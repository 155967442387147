import { MoratoriumCheckResponse } from './../../../../model/IMSService/MoratoriumCheckResponse';
import { Observable } from 'rxjs';
import { MoratoriumCheckRequest } from './../../../../model/IMSService/MoratoriumCheckRequest';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Guid } from "guid-typescript";
import { FindInsuredByName } from 'src/model/IMSService/FindInsuredByName';
import { Insured } from 'src/model/IMSService/Insured';
import { InsuredLocation } from 'src/model/IMSService/InsuredLocation';
import { Submission } from 'src/model/IMSService/Submission';
import { Quote } from 'src/model/IMSService/Quote';
import { QuoteOption } from 'src/model/IMSService/QuoteOption';
import { CreatePremium } from 'src/model/IMSService/CreatePremium';
import { DatePipe } from '@angular/common';
import { Policy } from 'src/model/IMSService/Policy';
import { BindQuote } from 'src/model/IMSService/BindQuote';
import { Surety } from 'src/model/IMSService/Surety';
import { ResponseAdditionalOccupants } from 'src/model/IMSService/ResponseAdditionalOccupants';
import { DocumentFileToIMS } from 'src/model/IMSService/DocumentFileToIMS';
import { ArrayOccupants } from 'src/model/IMSService/ArrayOccupants';
import { ResponseCreateSubjectivityUrl } from 'src/model/IMSService/ResponseCreateSubjectivityUrl';
import { UpdateSurety } from 'src/model/IMSService/UpdateSurety';
import { CompanyInstallment } from 'src/model/IMSService/CompanyInstallment';
import { GetQuoteRequest } from 'src/model/IMSService/GetQuoteRequest';
import { GetQuoteResponse } from 'src/model/IMSService/GetQuoteResponse';

@Injectable({
  providedIn: 'root'
})

export class IMSService {
  API_URL: any = environment.EnvironmentLSA;;
  TOKEN: any = environment.LSAToken;

  headersIMS = new HttpHeaders({
    'Authorization': 'Bearer ' + this.TOKEN
  });

  DocumentFileToIMSFormData = new FormData();
  QuoteGuid: Guid;

  constructor(private http: HttpClient, private datePipe: DatePipe) { }

  setDocumentFileToIMSFormData(documentFileToIMSFormData: FormData) {
    this.DocumentFileToIMSFormData = documentFileToIMSFormData;
  }

  getDocumentFileToIMSFormData() {
    return this.DocumentFileToIMSFormData;
  }

  setQuoteGUID(QuoteGuid: Guid) {
    this.QuoteGuid = QuoteGuid;
  }

  getQuoteGUID() {
    return this.QuoteGuid;
  }

  async getFindInsuredByName(findInsuredByName: FindInsuredByName) {
    const baseUrl = this.API_URL + "LookupInsured";
    return await this.http.post<string>(baseUrl, findInsuredByName, { headers: this.headersIMS, reportProgress: false }).toPromise();
  }

  async postCreateAccount(insured: Insured) {
    const baseUrl = this.API_URL + "CreateAccount/PostCreateAccount";
    return await this.http.post<Guid>(baseUrl, insured, {
      headers: this.headersIMS,
      reportProgress: false
    }).toPromise();
  }

  async postCreateInsuredLocation(insuredLocation: InsuredLocation) {
    const baseUrl = this.API_URL + "CreateInsuredLocation/PostCreateInsuredLocation";
    return await this.http.post<Guid>(baseUrl, insuredLocation, {
      headers: this.headersIMS,
      reportProgress: false
    }).toPromise();
  }

  async postCreateSubmission(submission: Submission) {
    const baseUrl = this.API_URL + "CreateSubmission/PostCreateSubmission";

    return await this.http.post<Guid>(baseUrl, submission, {
      headers: this.headersIMS,
      reportProgress: false
    }).toPromise();
  }

  async postCreateQuote(quote: Quote) {
    const baseUrl = this.API_URL + "CreateQuote/PostCreateQuote";

    return await this.http.post<Guid>(baseUrl, quote, {
      headers: this.headersIMS,
      reportProgress: false
    }).toPromise();
  }

  async postCreateQuoteOption(quoteOption: QuoteOption) {
    const baseUrl = this.API_URL + "CreateQuoteOption/PostCreateQuoteOption";

    return await this.http.post<Guid>(baseUrl, quoteOption, {
      headers: this.headersIMS,
      reportProgress: false
    }).toPromise();
  }

  async postCreatePremium(createPremium: CreatePremium) {
    const baseUrl = this.API_URL + "CreatePremium/PostCreatePremium";

    return await this.http.post<string>(baseUrl, createPremium, {
      headers: this.headersIMS,
      reportProgress: false
    }).toPromise();
  }

  async postBindQuote(bindQuote: BindQuote) {
    const baseUrl = this.API_URL + "BindQuote/PostBindQuote";
    return await this.http.post<Policy>(baseUrl, bindQuote, {
      headers: this.headersIMS,
      reportProgress: false
    }).toPromise();
  }

  async postSurety(surety: Surety) {
    const baseUrl = this.API_URL + "AdditionalOccupants/PostSurety";
    return await this.http.post<ResponseAdditionalOccupants>(baseUrl, surety, { headers: this.headersIMS }).toPromise();
  }

  async postAdditionalOccupants(arrayOccupants: ArrayOccupants) {
    const baseUrl = this.API_URL + "AdditionalOccupants/PostAdditionalOccupants";
    return await this.http.post<ResponseAdditionalOccupants>(baseUrl, arrayOccupants, { headers: this.headersIMS }).toPromise();
  }

  async postAdditionalOccupantsTemp(arrayOccupantsTemp: ArrayOccupants) {
    const baseUrl = this.API_URL + "AdditionalOccupants/PostOccupantsTemp";
    return await this.http.post<ResponseAdditionalOccupants>(baseUrl, arrayOccupantsTemp, { headers: this.headersIMS }).toPromise();
  }

  async putUpdateBondSigned(quoteGUID: string) {
    const baseUrl = this.API_URL + "AdditionalOccupants/PutUpdateBondSigned";

    const objet = {
      quoteGUID: quoteGUID
    }

    return await this.http.put<ResponseAdditionalOccupants>(baseUrl, objet, { headers: this.headersIMS }).toPromise();
  }

  async putUpdateSurety(updateSurety: UpdateSurety) {
    const baseUrl = this.API_URL + "AdditionalOccupants/PutUpdateSurety";
    return await this.http.put<ResponseAdditionalOccupants>(baseUrl, updateSurety, { headers: this.headersIMS }).toPromise();
  }
  
  async postCreateSubjectivityUrl(quoteGUID: string) {
    const baseUrl = this.API_URL + "CreateSubjectivityUrl/PostCreateSubjectivityUrl";

    const objet = {
      quoteGUID: quoteGUID
    }

    return await this.http.post<ResponseCreateSubjectivityUrl>(baseUrl, objet, { headers: this.headersIMS }).toPromise();
  }

  /**Create Template */
  async createTemplate(formData: FormData) {
    const baseUrl = this.API_URL + "CreateTemplate";
    return await this.http.post<Guid>(baseUrl, formData, { headers: this.headersIMS }).toPromise();
  }

  async GetDocumentFiletoIMS(formData: FormData) {
    return await this.http
      .post<DocumentFileToIMS>(
        this.API_URL + 'GetDocumentFiletoIMS',
        formData,
        {
          headers: this.headersIMS
        }
      ).toPromise();
  }

  async getCompanyInstallment(quoteGUID: string) {
    const baseUrl = this.API_URL + "BindQuote/GetCompanyInstallment";

    const objet = {
      quoteGUID: quoteGUID
    }

    return await this.http.post<CompanyInstallment>(baseUrl, objet, { headers: this.headersIMS }).toPromise();
  }

  async postBindWithInstallment(companyInstallment: CompanyInstallment) {
    const baseUrl = this.API_URL + "BindQuote/PostBindWithInstallment";
    return await this.http.post<Policy>(baseUrl, companyInstallment, { headers: this.headersIMS }).toPromise();
  }

  async postGetQuote(getQuoteRequest: GetQuoteRequest){
    const baseUrl = this.API_URL + "CreateQuote/GetQuote";
    return await this.http.post<GetQuoteResponse>(baseUrl, getQuoteRequest, { headers: this.headersIMS }).toPromise();
  }
  
  postMoratoriumCheck(request: MoratoriumCheckRequest):Observable<MoratoriumCheckResponse>{
    const baseUrl = this.API_URL + "MoratoriumCheck/GetMoratoriumCheck";
    return this.http.post<MoratoriumCheckResponse>(baseUrl, request, {headers: this.headersIMS});
  }

}