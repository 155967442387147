<form
  #DivBarAutocomplete
  fxLayout="row"
  fxLayoutAlign="space-around center"
  fxFlexFill
  id="DivBarAutocomplete"
  [formGroup]="homeForm"
>
  <div
    class="search-box-container"
    fxLayout.xs="column"
    fxLayoutAlign.xs="space-around stretch"
    fxLayoutGap="15px"
    fxFlex.xs="100%"
    fxFlex.gt-xs="70%"
    fxFlex.lg="50%"
    fxFlex.xl="40%"
  >
    <div class="quote">Get Insured in Minutes!</div>

    <div
      fxLayout="column"
      fxLayoutAlign.xs="space-around stretch"
      fxLayoutGap="15px"
    >
      <input
        type="text"
        class="form-control"
        id="autocomplete"
        placeholder="Address"
        autofocus
        formControlName="address"
        (keydown)="onKeydown($event)"
      />

      <div fxLayout="row" fxLayoutAlign="space-around center" fxLayoutGap="15px">
        <input
          class="form-control"
          formControlName="apt"
          placeholder="Apt/Unit #"
          [imask]="maskAPT"
          [unmask]="true"
          maxlength="64"
          fxFlex="25"
        />

        <select
          class="form-control"
          (change)="SetUnit()"
          formControlName="propertyTypeId"
          id="propertyType"
          placeholder="Property Type*"
          fxFlex="40"
        >
          <option [selected]="''" [ngValue]="''" [disabled]="true">
            Property Type
          </option>
          <option
            *ngFor="let propertyType of propertyTypes"
            [ngValue]="propertyType.code"
          >
            {{ propertyType.description }}
          </option>
        </select>

        <select
          class="form-control"
          formControlName="constructionTypeId"
          id="constructionType"
          placeholder="Construction Type*"
          fxFlex="40"
        >
          <option [selected]="''" [ngValue]="''" [disabled]="true">
            Construction Type
          </option>
          <option
            *ngFor="let constructionType of constructionTypes"
            [ngValue]="constructionType.code"
          >
            {{ constructionType.description }}
          </option>
        </select>

        <button
          class="btn btn-dark"
          (click)="AddressValidation()"
          type="submit"
          [disabled]="!formIsValid()"
          fxFlex="35"
        >
          Get Insured Today
        </button>
      </div>
    </div>
  </div>
</form>
