import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { prop } from 'ramda';
import { ProgressSpinnerService } from './shared/services/progress-spinner/progress-spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'protection-choice-front-end';
  isLoading?: boolean;

  icons = [
    {
      alias: 'pc-business',
      path: '../assets/images/icons/underwriting/business.svg'
    },
    {
      alias: 'pc-dog',
      path: '../assets/images/icons/underwriting/dog.svg'
    },
    {
      alias: 'pc-earthquake2',
      path: '../assets/images/icons/underwriting/earthquake2.svg'
    },
    {
      alias: 'pc-mold',
      path: '../assets/images/icons/underwriting/mold.svg'
    },
    {
      alias: 'pc-anchored',
      path: '../assets/images/icons/underwriting/anchored.svg'
    },
    {
      alias: 'pc-earthquake',
      path: '../assets/images/icons/underwriting/earthquake.svg'
    },
    {
      alias: 'pc-historical',
      path: '../assets/images/icons/underwriting/historical.svg'
    },
    {
      alias: 'pc-homeowners',
      path: '../assets/images/icons/underwriting/homeowners.svg'
    },
    {
      alias: 'pc-modular',
      path: '../assets/images/icons/underwriting/modular.svg'
    },
    {
      alias: 'pc-multi',
      path: '../assets/images/icons/underwriting/multi.svg'
    },
    {
      alias: 'pc-parking',
      path: '../assets/images/icons/underwriting/parking.svg'
    },
    {
      alias: 'pc-waterHeater',
      path: '../assets/images/icons/underwriting/waterHeater.svg'
    },
    {
      alias: 'pc-anchoredFoundation',
      path: '../assets/images/icons/underwriting/anchoredFoundation.svg'
    },
    {
      alias: 'cpp-download',
      path: '../assets/images/icons/completed/download.svg'
    },
    {
      alias: 'cpp-download-icon',
      path: '../assets/images/icons/completed/downloadIcon.svg'
    }
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private router: Router,
    private progressSpinnerService: ProgressSpinnerService
  ) {
    this.registerCustomIcons();
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && prop('ga')(window as any)) {
        (window as any)?.ga('set', 'page', event.urlAfterRedirects);
        (window as any)?.ga('create', 'UA-135147395-2', 'auto');
      }
    });
    this.progressSpinnerService.progressSpinnerStatus.subscribe({
      next: (isLoading: boolean) => (this.isLoading = isLoading)
    });
  }

  registerCustomIcons() {
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < this.icons.length; i++) {
      this.matIconRegistry.addSvgIcon(
        this.icons[i].alias,
        this.domSanitizer.bypassSecurityTrustResourceUrl(this.icons[i].path)
      );
    }
  }
}
