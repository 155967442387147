import { ProcessService } from './../../shared/services/process/process.service';
import { DOCUMENT } from '@angular/common';
import { Component, HostListener, Inject } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent {
  constructor(@Inject(DOCUMENT) private document: Document, processService: ProcessService) {
    processService.createProcessGuid();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    // Set new top to autocomplete dropdown
    const newTop =
      document.getElementById('DivBarAutocomplete').offsetTop +
      document.getElementById('DivAutocomplete').offsetTop +
      96;
    const pacStyle: HTMLElement = document.querySelector('.pac-container');
    if (pacStyle) pacStyle.style.top = newTop + 'px';

    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document
        .getElementById('DivAutocomplete')
        .classList.add('autocomplete-bottom');
    } else if (
      document.body.scrollTop === 0 ||
      document.documentElement.scrollTop === 0
    ) {
      document
        .getElementById('DivAutocomplete')
        .classList.remove('autocomplete-bottom');
    }
  }
}
