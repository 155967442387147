import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { InsuredInformationComponent } from './components/insured-information/insured-information.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PurchaseThankYouComponent } from './components/purchase/purchase-thank-you/purchase-thank-you.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { UnderwritingComponent } from './components/underwriting/underwriting.component';
import { WithParamsGuard } from './shared/guards/with-params/with-params.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'information',
    component: InsuredInformationComponent,
    canActivate: [WithParamsGuard],
    data: {
      requiredParams: [
        'constructionTypes',
        'deductibles',
        'personalPropertyLimits',
        'address',
        'propertyTypeId',
        'personalLiabilityLimits',
        'constructionTypeId'
      ],
      optionalParams: ['premium', 'liabilityLimit', 'rateParams', 'additionalInterest']
    },
    runGuardsAndResolvers: 'always'
  },
  { path: 'Payment', component: PaymentComponent },
  { path: 'purchase', component: PurchaseComponent },
  { path: 'ThankYou', component: PurchaseThankYouComponent },
  {
    path: 'underwriting',
    component: UnderwritingComponent,
    canActivate: [WithParamsGuard],
    data: { requiredParams: ['informationStep', 'rate', 'additionalInterest'] },
    runGuardsAndResolvers: 'always'
  },
  { path: '**', component: HomeComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'top'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
