import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class IpService {
  constructor(private httpClient: HttpClient) { }

  public getIpAddress() {
    return this.httpClient
      .get<{ ip: string }>('https://jsonip.com');
  }
}
