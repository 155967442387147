import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-protection',
  templateUrl: './home-protection.component.html',
  styleUrls: ['./home-protection.component.scss']
})
export class HomeProtectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
