import { ProcessService } from './../../shared/services/process/process.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  NgZone,
  OnInit,
  ViewChild
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner/progress-spinner.service';
import { environment } from 'src/environments/environment';
import {
  ApiService,
  BinderView,
  PaymentControllerInput,
  PaymentPlan,
  RateControllerInput,
  RateView
} from 'src/services/generated/api-services';

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit, AfterViewInit {
  @ViewChild('containerMakePayment') containerMakePayment: ElementRef;

  cardTerms = true;
  eftTerms = true;
  electronicTerms = true;

  rateInput: RateControllerInput;
  rateResponse: RateView;
  paymentInput: PaymentControllerInput;
  binderResponse: BinderView;
  planSelect: PaymentPlan;
  paymentForm: any;
  informationStep: any;
  processGuid: string
  paymentMethod: string;
  accountNumber: string;
  containEFT: boolean;
  containCC: boolean;

  TermsConditionsCC = environment.TermsConditionsCC;
  TermsConditionsEF = environment.TermsConditionsEF;
  yourInfoData: {
    liabilityLimit: number;
    personalPropertyLimit: number;
    deductible: number;
    policyStartDate: Date;
    premium: number;
  };

  constructor(
    public router: Router,
    private apiService: ApiService,
    private ngZone: NgZone,
    private progressService: ProgressSpinnerService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<MakePaymentComponent>,
    @Inject(MAT_DIALOG_DATA) public dataLoad: any,
    private processService:ProcessService
  ) {}
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.containerMakePayment.nativeElement.scrollTop = 0;
    }, 200);
  }
  ngOnInit(): void {
    this.processGuid = this.processService.getProcessGuid();
    const {
      rateInput,
      rateView,
      paymentInput,
      binderResponse,
      planSelect,
      paymentForm,
      informationStep
    } = this.dataLoad;

    if (!rateInput || !rateView || !paymentInput || !planSelect) {
      this.ngZone.run(() => this.router.navigate(['/home'])).then();
      return;
    }

    this.rateInput = rateInput;
    this.rateResponse = rateView;
    this.paymentInput = paymentInput;
    this.binderResponse = binderResponse;
    this.planSelect = planSelect;
    this.paymentForm = paymentForm;
    this.informationStep = informationStep;

    this.paymentMethod =
      this.paymentInput?.billingInfo?.methodPaymentCd === 'EFT'
        ? 'E-Check'
        : 'Credit/Debit Card';

    const str =
      this.paymentMethod === 'E-Check'
        ? this.paymentInput?.billingInfo?.paymentBankInfo?.accountNumber
        : this.paymentInput?.billingInfo?.creditCardInfo?.cardNumber;
    this.accountNumber = str.replace(/.(?=.{4})/g, '*');

    this.containEFT =
      this.paymentInput?.billingInfo?.methodPaymentCd === 'EFT' ||
      this.paymentInput?.recurringInfo?.methodPaymentCd === 'EFT';
    this.containCC =
      (this.paymentInput?.billingInfo?.methodPaymentCd &&
        this.paymentInput?.billingInfo?.methodPaymentCd !== 'EFT') ||
      (this.paymentInput?.recurringInfo?.methodPaymentCd &&
        this.paymentInput?.recurringInfo?.methodPaymentCd !== 'EFT');

    this.yourInfoData = {
      liabilityLimit: this.rateInput.rate.personalLiabilityLimit,
      personalPropertyLimit: this.rateInput.rate.personalPropertyLimit,
      deductible: this.rateInput.rate.deductible,
      policyStartDate: this.rateInput.rate.policyStartDate,
      premium: this.rateResponse.fullTermAmt
    };
    this.paymentInput.processGuid = this.processGuid;
  }

  makePayment() {
    if (this.cardTerms && this.eftTerms && this.electronicTerms) {
      this.progressService.progressStart();
      this.apiService.payment(this.paymentInput).subscribe(
        (paymentResponse) => {
          const data = {
            policyNumber: this.rateResponse.companysQuoteNumber,
            paymentResponse
          };
          this.ngZone
            .run(() =>
              this.router.navigate(['/ThankYou'], {
                state: data
              })
            )
            .then(() => this.progressService.progressStop());
          this.dialogRef.close(true);
        },
        (error) => {
          this.progressService.progressStop();
          this.alertService.AlertSimple('Make Payment', error?.detail);
        }
      );
    } else {
      this.alertService.AlertSimple(
        'Make Payment',
        'Please accept all the terms and conditions listed to continue.'
      );
    }
  }

  goBack() {
    const dataReturn = {
      paymentForm: this.paymentForm,
      rateInput: this.rateInput,
      rateView: this.rateResponse,
      informationStep: this.informationStep
    };
    this.ngZone
      .run(() => this.router.navigate(['/Payment'], { state: dataReturn }))
      .then(() => this.progressService.progressStop());
  }
}
