import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  API_URL: any = environment.ProtectionURL;
  TOKEN: any = environment.ProtectionToken;
  headersDC = new HttpHeaders({
    Authorization: 'Bearer ' + this.TOKEN,
  });

  addressServices: any;
  unitForAddressService: any;

  constructor(private http: HttpClient) {}

  setAddress(address: any) {
    if (address) {
      this.addressServices = address;
    }
  }

  getAddress(): any {
    return this.addressServices;
  }

  deleteAddress() {
    this.addressServices = undefined;
  }

  setUnitForAddressLocal(unitsForAddress: any) {
    if (unitsForAddress) {
      this.unitForAddressService = unitsForAddress;
    }
  }

  getUnitForAddressLocal(): any {
    return this.unitForAddressService;
  }
}
