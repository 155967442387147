<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="start center" fxLayoutAlign.lt-md="start start" class="title">
    Credit / Debit Card Information
    <span>
        <img src="assets/images/payment/visa-icon.svg" class="payment-icons" alt="visa">
        <img src="assets/images/payment/mastercard-icon.svg" class="payment-icons" alt="visa">
        <img src="assets/images/payment/american-express-icon.svg" class="payment-icons" alt="visa">
        <img src="assets/images/payment/discover-icon.svg" class="payment-icons" alt="visa">
    </span>
</div>

<div fxLayout="column" fxLayoutAlign="space-around stretch" [formGroup]="PaymentGroup" fxLayoutGap="15px">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxLayoutGap="15px">
        <div fxFlex="50%" fxFlex.lt-md="100%">
            <label for="" class="LabelText">Name on Card*</label>
            <input formControlName="NameOnCardControl" (focusout)="Validation()"
                [class.is-invalid]="NameOnCardControl.invalid && NameOnCardControl.touched" type="text" pattern="[a-zA-Z ]*"
                class="form-control" id="NameOnCardControl">
            <div *ngIf="(NameOnCardControl.invalid && NameOnCardControl.touched) || NameOnCardControl.dirty">
                <small *ngIf="NameOnCardControl.errors?.required" class="text-danger">Name on card is required</small>
                <small *ngIf="NameOnCardControl.hasError('pattern')" class="text-danger">This field only admit spaces and letters</small>
            </div>
        </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="space-around stretch" fxLayoutAlign="space-between start" fxLayoutGap="15px" fxFill fxLayoutGap="15px">
        <div fxFlex="25%" fxFlex.lt-md="100%">
            <label for="" class="LabelText">Card Number*</label>
            <div class="input-group">
                <input formControlName="CardNumberControl"
                    [class.is-invalid]="CardNumberControl.invalid && CardNumberControl.touched" type="text"
                    (keypress)="keyPress($event)" (keyup)="ValidationCardType()" (focusout)="LostFocusCardNumber()"
                    maxlength="16" minlength="15" name="CardNumber" id="CardNumber" class="form-control">
                <div *ngIf="this.Route">
                    <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"><img src={{this.Route}} height="24px"
                                alt=""></span>
                    </div>
                </div>
            </div>
            <div *ngIf="(CardNumberControl.invalid && CardNumberControl.touched) || CardNumberControl.dirty">
                <small *ngIf="CardNumberControl.errors?.required" class="text-danger">Card number is required</small>
                <small *ngIf="!CardNumberControl.errors?.required && CardNumberControl.errors?.validateInput" class="text-danger">Card number is invalid</small>
            </div>
        </div>

        <div fxFlex="25%" fxFlex.lt-md="100%">
            <label for="" class="LabelText">Exp. Month*</label>
            <div class="input-group">
                <select formControlName="ExpMonthControl" [class.is-invalid]="ExpMonthControl.invalid && ExpMonthControl.touched" (focusout)="Validation()" class="form-control" id="inputGroupSelect01">
                    <option disabled selected=""></option>
                    <option value="01">01 - January</option>
                    <option value="02">02 - February</option>
                    <option value="03">03 - March </option>
                    <option value="04">04 - April </option>
                    <option value="05">05 - May </option>
                    <option value="06">06 - June </option>
                    <option value="07">07 - July </option>
                    <option value="08">08 - August </option>
                    <option value="09">09 - September</option>
                    <option value="10">10 - October </option>
                    <option value="11">11 - November </option>
                    <option value="12">12 - December </option>
                </select>
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2"><img class="filterSVG-Green"
                            src="assets\images\purchase\calendar-alt-solid.svg" height="20px" alt=""></span>
                </div>
            </div>
            <div *ngIf="(ExpMonthControl.invalid && ExpMonthControl.touched) || ExpMonthControl.dirty">
                <small *ngIf="ExpMonthControl.errors?.required" class="text-danger">Month is required</small>
            </div>
        </div>

        <div fxFlex="25%" fxFlex.lt-md="100%">
            <label for="" class="LabelText">Exp. Year*</label>
            <div class="input-group">
                <select formControlName="ExpYearControl" [class.is-invalid]="ExpYearControl.invalid && ExpYearControl.touched" (focusout)="Validation()" class="form-control" id="inputGroupSelect01">
                    <option disabled selected=""></option>
                    <option *ngFor="let yearV of yearsData" value="{{yearV}}">{{yearV}}</option>
                </select>
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2"><img class="filterSVG-Green"
                            src="assets\images\purchase\calendar-alt-solid.svg" height="20px" alt=""></span>
                </div>
            </div>
            <div *ngIf="(ExpYearControl.invalid && ExpYearControl.touched) || ExpYearControl.dirty">
                <small *ngIf="ExpYearControl.errors?.required" class="text-danger">Year is required</small>
            </div>
        </div>

        <div fxFlex="25%" fxFlex.lt-md="100%">
            <label for="" class="LabelText">CCV*</label>
            <div class="input-group">
                <input formControlName="CVVControl" [class.is-invalid]="CVVControl.invalid && CVVControl.touched" type="password" (focusout)="Validation()" (keypress)="keyPress($event)" [attr.maxlength]="maxLength" name="CVV"
                    id="CVV" class="form-control">
                <div class="input-group-append">
                    <span class="input-group-text" id="basic-addon2"><img class="filterSVG-Green"
                            src="assets\images\purchase\question-circle-solid.svg" height="20px" alt=""></span>
                </div>
            </div>
            <div *ngIf="(CVVControl.invalid && CVVControl.touched) || CVVControl.dirty">
                <small *ngIf="CVVControl.errors?.required" class="text-danger">CVV is required</small>
            </div>
        </div>
    </div>
</div>