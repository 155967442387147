import { AddressService } from './../../../shared/services/address/address.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-home-address',
  templateUrl: './home-address.component.html',
  styleUrls: ['./home-address.component.scss']
})
export class HomeAddressComponent implements OnInit {
  addressValues : any;
  addressForm: FormGroup;
  constructor(public bsModalAddress: BsModalRef, private addressService:AddressService) { }

  ngOnInit() {
    this.addressValues = this.addressService.getAddress();
    this.addressForm = new FormGroup({
      streetControl: new FormControl('', Validators.required),
      cityControl: new FormControl('', Validators.required),
      stateControl: new FormControl('', Validators.required),
      zipControl: new FormControl('', Validators.required)
    });
    this.addressForm.controls.streetControl.setValue(this.addressValues.street ? this.addressValues.street : null);
    this.addressForm.controls.streetControl.markAsTouched();
    this.addressForm.controls.cityControl.setValue(this.addressValues.city ? this.addressValues.city : null);
    this.addressForm.controls.cityControl.markAsTouched();
    this.addressForm.controls.stateControl.setValue(this.addressValues.stateCode ? this.addressValues.stateCode : null);
    this.addressForm.controls.stateControl.markAsTouched();
    this.addressForm.controls.zipControl.setValue(this.addressValues.zipcode ? this.addressValues.zipcode : null);
    this.addressForm.controls.zipControl.markAsTouched();
  }

  get StreetControl(){
    return this.addressForm.get('streetControl')
  }
  get CityControl(){
    return this.addressForm.get('cityControl')
  }
  get StateControl(){
    return this.addressForm.get('stateControl')
  }
  get ZipControl(){
    return this.addressForm.get('zipControl')
  }

  Submit(){
    if(this.addressForm.valid){
      this.addressService.setAddress(this.addressValues);
      this.bsModalAddress.content.onClose.next(true);
      this.bsModalAddress.hide();
    }
    else{
      this.addressForm.markAsTouched();
    }

  }

  Cancel(){
    this.bsModalAddress.content.onClose.next(false);
    this.bsModalAddress.hide();
  }

  Validation() {
    if (this.addressForm.status === "VALID") {
      this.SetAddress();
    }
  }

  SetAddress(){
    this.addressValues.street = this.StreetControl.value;
    this.addressValues.city =  this.CityControl.value;
    this.addressValues.stateCode = this.StateControl.value;
    this.addressValues.zipcode =  this.ZipControl.value;
  }

  showValidationMsg(formGroup: FormGroup) {
    for (const key in formGroup.controls) {
        if (formGroup.controls.hasOwnProperty(key)) {
            const control: FormControl = <FormControl>formGroup.controls[key];

            if (Object.keys(control).includes('controls')) {
                const formGroupChild: FormGroup = <FormGroup>formGroup.controls[key];
                this.showValidationMsg(formGroupChild);
            }
            control.markAsTouched();
        }
    }
  }
}
