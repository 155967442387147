
import { add, parseISO } from 'date-fns';
class Utilities {
  numberToPhone(tel: any): string {
    if (!tel) {
      return '';
    }

    var value = tel.toString().trim().replace(/^\+/, '');

    if (value.match(/[^0-9]/)) {
      return tel;
    }

    var country, city, number;

    switch (value.length) {
      case 10: // +1PPP####### -> C (PPP) ###-####
        country = 1;
        city = value.slice(0, 3);
        number = value.slice(3);
        break;

      case 11: // +CPPP####### -> CCC (PP) ###-####
        country = value[0];
        city = value.slice(1, 4);
        number = value.slice(4);
        break;

      case 12: // +CCCPP####### -> CCC (PP) ###-####
        country = value.slice(0, 3);
        city = value.slice(3, 5);
        number = value.slice(5);
        break;

      default:
        return tel;
    }

    if (country == 1) {
      country = '';
    }

    number = number.slice(0, 3) + '-' + number.slice(3);

    return (country + ' (' + city + ') ' + number).trim();
  }

  objectToBoolean(value: any): boolean {
    switch (value) {
      case true:
      case 'true':
      case 'True':
      case 'TRUE':
      case 1:
      case '1':
      case 'on':
      case 'yes':
      case 'Yes':
      case 'YES':
        return true;
      default:
        return false;
    }
  }

  createDate(date: Date, addDay?: number): Date {
    if (addDay) {
      date = add(date, { days: addDay });
    }
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const dateUTC = `${date.getFullYear()}-${
      month > 9 ? month : '0' + month.toString()
    }-${day > 9 ? day : '0' + day.toString()}T12:00:00Z`;

    return parseISO(dateUTC);
  }
}
export const utilities = new Utilities();
