<div fxFlex="row" fxLayoutGap="20px">
  <div class="card" fxFlex="80%">
    <div class="card-header">
      What coverage options would you like to include in your renter insurance
      policy
    </div>

    <!-- FORM -->
    <form class="card-body" [formGroup]="informationForm">
      <!-- FLEX CONTAINER -->
      <div
        fxLayout="column"
        fxLayoutAlign="space-between stretch"
        fxLayoutGap="25px"
      >
        <!-- FIRST ROW -->
        <div
          fxLayout="row"
          fxLayoutAlign="space-between start"
          fxLayoutGap="20px"
        >
          <!-- PROPERTY LIMIT -->
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxFlex="calc(33% - 20px)"
          >
            <label class="col-form-label input-label label-center-icon">
              <mat-icon [customTooltip]="tooltipContentsCoverage"
                >information</mat-icon
              >
              Contents Coverage Amount*:
            </label>
            <select
              formControlName="personalPropertyLimit"
              class="form-control"
              (change)="changeDropDown($event, 'personalPropertyLimit')"
              [class.is-invalid]="getErrorCtr('personalPropertyLimit')"
            >
              <option [selected]="''" [ngValue]="''" [disabled]="true">
                Select Option
              </option>
              <option
                *ngFor="let limit of personalPropertyLimits"
                [value]="limit"
              >
                {{ limit | currency: 'USD':'symbol':'.0-0' }}
              </option>
            </select>
            <div class="invalid-feedback">
              Contents Coverage Amount is required.
            </div>
          </div>

          <!-- LIABILITY -->
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxFlex="calc(33% - 20px)"
          >
            <label class="col-form-label input-label label-center-icon">
              <mat-icon [customTooltip]="tooltipLiabilityLimit"
                >information</mat-icon
              >
              Liability Limit*:&nbsp;
            </label>
            <select
              formControlName="personalLiabilityLimit"
              class="form-control"
              (change)="changeDropDown($event, 'personalLiabilityLimit')"
              [class.is-invalid]="getErrorCtr('personalLiabilityLimit')"
            >
              <option [selected]="''" [ngValue]="''" [disabled]="true">
                Select Option
              </option>
              <option *ngFor="let limit of personalLiabilityLimits" [value]="limit">
                {{ limit | currency: 'USD':'symbol':'.0-0' }}
              </option>
            </select>
            <div class="invalid-feedback">Liability Limit is required.</div>
          </div>

          <!-- DEDUCTIBLE -->
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxFlex="calc(33% - 20px)"
          >
            <label class="col-form-label input-label"> Deductible*: </label>
            <select
              formControlName="deductible"
              class="form-control"
              (change)="changeDropDown($event, 'deductible')"
              [class.is-invalid]="getErrorCtr('deductible')"
            >
              <option [selected]="''" [ngValue]="''" [disabled]="true">
                Select Option
              </option>
              <option
                *ngFor="let deductible of deductibles"
                [value]="deductible"
              >
                {{ deductible | currency: 'USD':'symbol':'.0-0' }}
              </option>
            </select>
            <div class="invalid-feedback">Deductible is required.</div>
          </div>
        </div>
        <!-- END FIRST ROW -->

        <!-- SECOND ROW -->
        <div
          class="mid-width"
          fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="20px"
        >
          <!-- POLICY START DATE -->
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxFlex="0 0 calc(50% - 20px)"
          >
            <label class="col-form-label input-label">Policy Start Date</label>
            <div class="input-group">
              <input
                formControlName="policyStartDate"
                [class.is-invalid]="
                  informationForm.get('policyStartDate')?.invalid &&
                  informationForm.get('policyStartDate')?.touched
                "
                type="text"
                (focusout)="validationOwner()"
                class="form-control date-input"
                #dp="bsDatepicker"
                bsDatepicker
                placement="right"
                [bsConfig]="{ showWeekNumbers: false, adaptivePosition: true }"
                [minDate]="minDate"
                [maxDate]="maxDate"
                readonly="readonly"
              />
              <div class="input-group-append"  (click)="dp.toggle()">
                <span class="input-group-text">
                  <img
                    class="filter-svg-green"
                    src="assets\images\purchase\calendar-alt-solid.svg"
                    height="20px"
                    alt=""
                  />
                </span>
              </div>
            </div>
          </div>

          <!-- CONSTRUCTION TYPE -->
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxFlex="0 0 calc(50% - 20px)"
          >
            <label class="col-form-label input-label"
              >Construction Type*:</label
            >
            <select
              formControlName="constructionTypeId"
              class="form-control"
              (change)="changeDropDown($event, 'constructionTypeId')"
              [class.is-invalid]="getErrorCtr('constructionTypeId')"
            >
              <option [selected]="''" [ngValue]="''" [disabled]="true">
                Select Option
              </option>
              <option
                *ngFor="let constructionType of constructionTypes"
                [value]="constructionType.code"
              >
                {{ constructionType.description }}
              </option>
            </select>
            <div class="invalid-feedback">Construction Type is required.</div>
          </div>
        </div>
        <!-- END SECOND ROW -->
      </div>
      <!-- END FLEX CONTAINER -->
    </form>
    <!-- END FORM -->
  </div>

  <!-- SIDE COST -->
  <div class="card" fxFlex="20%">
    <div class="card-header">Your Policy Cost</div>
    <div class="card-body">
      <div class="cost-container" fxLayout="row" fxLayoutAlign="center center">
        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="premium">
          <div>
            <span class="cost">{{
              premium / 12 || 0 | currency: 'USD':'symbol':'1.2-2'
            }}</span
            >/Month
          </div>
          <span
            >or
            {{ premium || 0 | currency: 'USD':'symbol':'1.2-2' }} annually</span
          >
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!premium">
          <label class="not-premium"
            >Enter Coverages to see Policy Cost.
          </label>
        </div>
      </div>
    </div>
  </div>
  <!-- END SIDE COST -->
</div>
