<div [formGroup]="dynamicForm" class="">
    <div *ngFor="let occupant of t.controls; let i = index;">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between start" fxLayoutAlign.xs="space-around stretch" fxLayoutGap="15px" [formGroup]="occupant" fxFill>
            <div fxFlex="25%">
                <label class="LabelText">First Name*</label>
                <input formControlName="firstName" type="text" (focusout)="ValidationOcupants()" class="form-control"
                    name="firstName_{{i}}" [class.is-invalid]="occupant?.controls.firstName.invalid && occupant?.controls.firstName.touched">
                <div *ngIf="(occupant?.controls.firstName.invalid && occupant?.controls.firstName.touched) || occupant?.controls.firstName.dirty">
                    <small *ngIf="occupant?.controls.firstName.errors?.required" class="text-danger">First Name is required</small>
                    <small *ngIf="occupant?.controls.firstName.hasError('pattern')" class="text-danger">This field only admit spaces and letters</small>
                </div>
            </div>
            <div fxFlex="25%">
                <label class="LabelText">Last Name*</label>
                <input formControlName="lastName" type="text" (focusout)="ValidationOcupants()" class="form-control"
                    name="lastName_{{i}}" [class.is-invalid]="occupant?.controls.lastName.invalid && occupant?.controls.lastName.touched">
                <div *ngIf="(occupant?.controls.lastName.invalid && occupant?.controls.lastName.touched) || occupant?.controls.lastName.dirty">
                    <small *ngIf="occupant?.controls.lastName.errors?.required" class="text-danger">Last Name is required</small>
                    <small *ngIf="occupant?.controls.lastName.hasError('pattern')" class="text-danger">This field only admit spaces and letters</small>
                </div>
            </div>
            <div fxFlex="25%">
                <label class="LabelText">Email</label>
                <input type="text" formControlName="email" (focusout)="ValidationOcupants()" class="form-control"
                    name="email_{{i}}">
                <div *ngIf="(occupant?.controls.email.invalid && occupant?.controls.email.touched) || occupant?.controls.email.dirty">
                    <small *ngIf="occupant?.controls.email.errors?.pattern" class="text-danger">Please provide a valid email
                        address</small>
                </div>
            </div>
            <div fxFlex="25%"></div>
        </div>
    </div>
</div>
<div>
    <div class="Text" style="margin-top: 10px;">
        Additional Occupant(s): To ensure that coverage is extended to all occupants, please include all additional occupants over 18 years of age.
    </div>
</div>
<div>
    <div div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="!FiveRow">
            <img src="assets\images\purchase\plus-circle-solid.svg" height="15px" alt="Plus" class="filterSVG-Green addOccupant" (click)="addOccupant()" >
            <button [disabled]="disableButtons" class="ButtonAddOccupant" (click)="addOccupant()"> Add Occupant</button>
        </div>
        <div *ngIf="!OneRow">
            <img src="assets\images\purchase\minus-circle-solid.svg" height="15px" alt="Plus" class="filterSVG-Red removeOcuppant" (click)="removeOccupant(i)">
            <button [disabled]="disableButtons" class="ButtonRemoveOccupant" (click)="removeOccupant(i)">Remove Occupant</button>
        </div>
    </div>
</div>