<div fxLayout="row" fxLayout.xs="column" flexFill>
  <div class="col-left col-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px" fxFlex="50%">
    <div class="square" fxLayout="column" style="padding: 15px;">
      <div class="Titulo3">Renters</div>
  
      <div class="Texto3">
        Protect yourself from negligence that impacts your 
        apartment, the community, or other residents instead 
        of paying high, out-of-pocket costs in the event of 
        damage.
        <br><br>
        <b>Get coverage designed with you and your personal 
        property in mind!</b>
      </div>
      </div>
  </div>
  
  <div class="col-right col-content" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="15px" fxFlex="50%">
    <div class="square" fxLayout="column" style="padding: 15px;">
      <div class="Titulo3">Property owners and managers</div>
  
      <div class="Texto3">
        Reduce financial losses caused by uninsured 
        residents. Working together, we can reduce the 
        amount of unit damage expense sent to collections.
        <br><br>
        Learn more about the additional layer of protection 
        provided by Protection Choice renters insurance.
      </div>
    </div>
  </div>
</div>