<div class="modal-body">
  <div class="row ">
    <div class="col d-flex justify-content-center Text">
      Please wait a moment while your document is getting signed
    </div>
  </div>
  <div class="row ">
    <div class="col d-flex justify-content-center">
      <div class="preloader"></div>
    </div>
  </div>
</div>