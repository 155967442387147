import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BrokerDocument } from 'src/model/IMSService/BrokerDocument';

@Injectable({
  providedIn: 'root'
})
export class DocuSignService {

  API_URL: any = environment.EnvironmentDocuSign;
  TOKEN: any = environment.DocuSignToken;
  headersDodcSign = new HttpHeaders({
    'Authorization': 'Bearer ' + this.TOKEN
  });

  BrokerDocumentFormData = new FormData();
  constructor(private httpClient: HttpClient) { }

  setBrokerDocumentFormData(brokerDocumentFormData: FormData) {
    this.BrokerDocumentFormData = brokerDocumentFormData;
  }

  getBrokerDocumentFormData(): FormData {
    return this.BrokerDocumentFormData;
  }

  async postBrokerDocument(formData: FormData) {
    return await this.httpClient.post<BrokerDocument>(this.API_URL + 'Broker', formData, { headers: this.headersDodcSign }).toPromise();
  }
}
