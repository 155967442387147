import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-signature-validation',
  templateUrl: './signature-validation.component.html',
  styleUrls: ['./signature-validation.component.scss']
})
export class SignatureValidationComponent implements OnInit {
  
  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

}
