import { ProcessService } from './../../shared/services/process/process.service';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  faChevronCircleRight,
  faInfoCircle
} from '@fortawesome/free-solid-svg-icons';
import { emailPattern, namePattern } from 'src/app/constants/patters';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner/progress-spinner.service';
import { masks } from 'src/app/utilities/masks';
import {
  AdditionalInterest,
  ApiService,
  BillingInfo,
  BinderControllerInput,
  PaymentControllerInput,
  PaymentPlan,
  RateControllerInput,
  RateView
} from 'src/services/generated/api-services';
import { MakePaymentComponent } from '../make-payment/make-payment.component';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit, AfterViewInit, AfterViewChecked  {
  @ViewChild('containerPage') containerPage: ElementRef;
  @ViewChild('detailComponent') detailComponent: ElementRef;

  iconArrowRight = faChevronCircleRight;
  iconInfo = faInfoCircle;

  positionPageTop = true;

  rateInput: RateControllerInput;
  rateResponse: RateView;
  listPlan: PaymentPlan[];
  informationStep: any;
  additionalInterest: AdditionalInterest;
  processGuid: string;
  yearsData: number[] = [];

  paymentGroup: FormGroup;

  maskCity = masks.maskOnlyLetters;
  maskState = masks.maskOnlyLetters;
  maskZipCode = masks.maskOnlyNumber;
  maskCreditCard = masks.maskOnlyNumber;
  maskNameOnCard = masks.maskOnlyLetters;
  maskFutureCreditCard = masks.maskOnlyNumber;
  maskFutureNameOnCard = masks.maskOnlyLetters;
  maskRouting = masks.maskOnlyNumber;
  maskAccount = masks.maskOnlyNumber;
  maskFutureRouting = masks.maskOnlyNumber;
  maskFutureAccount = masks.maskOnlyNumber;

  routeCard: string;
  futureRouteCard: string;
  maxLength: number = 3;
  futureMaxLength: number = 3;
  CardType: string;
  futureCardType: string;
  addressFull: string;

  isGoBackMakePaymentFirst = false;

  get planSelect() {
    return this.getValue('plan') as PaymentPlan;
  }

  constructor(
    private fb: FormBuilder,
    private renderer: Renderer2,
    private apiService: ApiService,
    private router: Router,
    private ngZone: NgZone,
    private progressService: ProgressSpinnerService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private processService: ProcessService
  ) {
    this.paymentGroup = this.fb.group({
      plan: [null, Validators.required],
      chkTypePayment: [0, Validators.required],
      // creditCard
      nameOnCard: new FormControl('', [
        Validators.required,
        Validators.pattern('[a-zA-Z ]*')
      ]),
      cardNumber: new FormControl('', [
        Validators.required,
        Validators.minLength(15),
        this.validateInput
      ]),
      monthCreditCard: new FormControl('', Validators.required),
      yearCreditCard: new FormControl('', Validators.required),
      CVVCreditCard: new FormControl('', [
        Validators.required,
        Validators.minLength(3)
      ]),
      // eCheck
      eCheckType: new FormControl(''),
      routingNumber: new FormControl(''),
      accountNumber: new FormControl(''),
      // Billing Address
      billingAddress: new FormControl(''),
      billingCity: new FormControl(''),
      billingState: new FormControl(''),
      billingZip: new FormControl(''),

      // Future payment
      FutureChkTypePayment: [0],
      // creditCard
      futureNameOnCard: new FormControl(''),
      futureCardNumber: new FormControl(''),
      futureMonthCreditCard: new FormControl(''),
      futureYearCreditCard: new FormControl(''),
      futureCVVCreditCard: new FormControl(''),
      // eCheck
      futureECheckType: new FormControl(''),
      futureRoutingNumber: new FormControl(''),
      futureAccountNumber: new FormControl(''),
      // other
      termAndCondi: new FormControl(''),
      leaseEmail: new FormControl('', Validators.pattern(emailPattern)),
    });

    // validations
    this.validationsForm();
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit(): void {
    this.setStyleDEtailsComponent();
  }

  ngOnInit(): void {
    this.processGuid = this.processService.getProcessGuid();
    const { rateInput, rateView, informationStep, paymentForm, additionalInterest } = history.state;

    if (!rateInput || !rateView) {
      this.ngZone.run(() => this.router.navigate(['/home'])).then();
      return;
    }

    this.rateInput = rateInput;
    this.rateResponse = rateView;
    this.listPlan = this.rateResponse.paymentsPlans;
    this.paymentGroup.patchValue({ plan: this.listPlan[0] });
    this.informationStep = informationStep;
    this.additionalInterest = additionalInterest;

    if (paymentForm) {
      this.isGoBackMakePaymentFirst = true;
      this.processPaymentForm(paymentForm);
    }

    this.addressFull =
      this.rateInput?.address?.address1 +
      ' ' +
      (this.rateInput?.address?.address2
        ? this.rateInput?.address?.address2 + ' '
        : '') +
      this.rateInput?.address?.city +
      ', ' +
      this.rateInput?.address?.stateCode +
      ' ' +
      this.rateInput?.address?.zipCode;
  }

  validationsForm() {
    // Add validator Billing checked
    this.paymentGroup.valueChanges.subscribe((value) => {
        if (value) {
          this.getCtr('billingAddress')?.setValidators([Validators.required]);
          this.getCtr('billingCity')?.setValidators([Validators.required]);
          this.getCtr('billingState')?.setValidators([Validators.required]);
          this.getCtr('billingZip')?.setValidators([
            Validators.required,
            Validators.minLength(5)
          ]);
        } else {
          this.getCtr('billingAddress')?.setValidators(null);
          this.getCtr('billingAddress')?.reset();
          this.getCtr('billingCity')?.setValidators(null);
          this.getCtr('billingCity')?.reset();
          this.getCtr('billingState')?.setValidators(null);
          this.getCtr('billingState')?.reset();
          this.getCtr('billingZip')?.setValidators(null);
          this.getCtr('billingZip')?.reset();
        }
      });

    const dateNow = new Date();
    const month = dateNow.getMonth();
    const yearNow = dateNow.getFullYear();
    this.getCtr('monthCreditCard').valueChanges.subscribe((monthValue) => {
      this.yearsData = [];

      if (Number(monthValue) > month) {
        this.yearsData.push(yearNow);
      }
      for (let i = 1; i < 11; i++) this.yearsData.push(yearNow + i);
    });
    this.getCtr('cardNumber').valueChanges.subscribe((value) => {
      const dataReturn = this.ValidationCardType('cardNumber');
      this.maxLength = dataReturn.maxLength;
      this.routeCard = dataReturn.routeCard;
      this.CardType = dataReturn.cardType;
    });
    this.getCtr('futureCardNumber').valueChanges.subscribe((value) => {
      const dataReturn = this.ValidationCardType('futureCardNumber');
      this.futureMaxLength = dataReturn.maxLength;
      this.futureRouteCard = dataReturn.routeCard;
      this.futureCardType = dataReturn.cardType;
    });

    this.getCtr('plan').valueChanges.subscribe((value) => {
      // Prevents setting validations the first time it is entered from the go back from make payment
      if (this.isGoBackMakePaymentFirst) {
        this.isGoBackMakePaymentFirst = false;
      } else {
        this.newFutureProcessValidation();
        const typePlan = this.planSelect.paymentType === 'EF' ? 1 : 0;
        this.changeTypePayment(typePlan);
      }
    });
  }

  changeTypePayment(typePayment: number) {
    this.paymentGroup.patchValue({
      chkTypePayment: typePayment
    });
    if (typePayment === 1) {
      this.getCtr('routingNumber')?.setValidators([Validators.required]);
      this.getCtr('routingNumber')?.reset();
      this.getCtr('accountNumber')?.setValidators([Validators.required]);
      this.getCtr('accountNumber')?.reset();
      this.paymentGroup.patchValue({
        eCheckType: 'PersonalChecking'
      });
      // delete credit card
      this.getCtr('nameOnCard')?.setValidators(null);
      this.getCtr('nameOnCard')?.reset();
      this.getCtr('cardNumber')?.setValidators(null);
      this.getCtr('cardNumber')?.reset();
      this.getCtr('monthCreditCard')?.setValidators(null);
      this.getCtr('monthCreditCard')?.reset();
      this.getCtr('yearCreditCard')?.setValidators(null);
      this.getCtr('yearCreditCard')?.reset();
      this.getCtr('CVVCreditCard')?.setValidators(null);
      this.getCtr('CVVCreditCard')?.reset();
    } else {
      this.getCtr('nameOnCard')?.setValidators([Validators.required]);
      this.getCtr('nameOnCard')?.reset();
      this.getCtr('cardNumber')?.setValidators([
        Validators.required,
        Validators.minLength(15),
        this.validateInput
      ]);
      this.getCtr('cardNumber')?.reset();
      this.getCtr('monthCreditCard')?.setValidators([Validators.required]);
      this.getCtr('monthCreditCard')?.reset();
      this.getCtr('yearCreditCard')?.setValidators([Validators.required]);
      this.getCtr('yearCreditCard')?.reset();
      this.getCtr('CVVCreditCard')?.setValidators([
        Validators.required,
        Validators.minLength(3)
      ]);
      this.getCtr('CVVCreditCard')?.reset();
      // delete eCheck
      this.getCtr('routingNumber')?.setValidators(null);
      this.getCtr('routingNumber')?.reset();
      this.getCtr('accountNumber')?.setValidators(null);
      this.getCtr('accountNumber')?.reset();
      this.paymentGroup.patchValue({
        monthCreditCard: '',
        yearCreditCard: ''
      });
    }
  }

  paymentProcess() {
    if (this.paymentGroup.valid) {
      this.rateInput.rate.paymentPlanId = Number(this.planSelect.paymentPlanId);
      this.rateInput.rate.paymentPlanAmount = this.planSelect.installmentAmount;
      this.rateInput.rate.premium = this.rateResponse.fullTermAmt;

      const requestBinder: BinderControllerInput = {
        rqUID: this.rateResponse.rqUID,
        companysQuoteNumber: this.rateResponse.companysQuoteNumber,
        insuredInfo: this.rateInput?.insuredInfo,
        secondInsuredInfo: this.rateInput?.secondInsuredInfo,
        address: this.rateInput?.address,
        additionalInterest: this.additionalInterest,
        leaseManagementEmail: this.paymentGroup.get('leaseEmail').value,
        processGuid : this.processGuid
      };

      const requestPayment: PaymentControllerInput = {
        billingInfo: {
          billingAddress: {
            address1: this.rateInput.address.address1,
            address2: this.rateInput.address.address2,
            city: this.rateInput.address.city,
            country: this.rateInput.address.country,
            stateCode: this.rateInput.address.stateCode,
            zipCode: this.rateInput.address.zipCode
          },
          transferAmt: this.planSelect?.firstPaymentAmount,
          paymentPlanCd: this.planSelect.paymentPlanCd,
          paymentPlantId: +this.planSelect.paymentPlanId,
        },
        companysQuoteNumber: this.rateResponse.companysQuoteNumber,
        insuredInfo: this.rateInput.insuredInfo,
        rqUID: this.rateResponse.rqUID,
        mailingAddress: this.rateInput?.mailingAddress,
        additionalInterest: this.additionalInterest,
      };

      if (Number(this.getValue('chkTypePayment')) === 0) {
        const expirationDate = new Date(
          this.getValue('yearCreditCard'),
          +this.getValue('monthCreditCard') - 1,
          1
        );
        requestPayment.billingInfo.creditCardInfo = {
          cardNumber: this.getValue('cardNumber'),
          cvc: this.getValue('CVVCreditCard'),
          nameOnCard: this.getValue('nameOnCard'),
          expirationDate: expirationDate
        };
        requestPayment.billingInfo.methodPaymentCd = this.CardType;
      } else {
        requestPayment.billingInfo.paymentBankInfo = {
          accountHolder:
            this.rateInput.insuredInfo.firstName +
            this.rateInput.insuredInfo.lastName,
          accountNumber: this.getValue('accountNumber'),
          routingNumber: this.getValue('routingNumber'),
          accountType: this.getValue('eCheckType'),
          firstName: this.rateInput.insuredInfo.firstName,
          lastName: this.rateInput.insuredInfo.lastName
        };
        requestPayment.billingInfo.methodPaymentCd = 'EFT';
      }
      // Set Billing Info
      requestPayment.billingInfo.billingAddress = {
        address1: this.getValue('billingAddress'),
        city: this.getValue('billingCity'),
        stateCode: this.getValue('billingState'),
        zipCode: this.getValue('billingZip')
      };

      this.progressService.progressStart();
      this.apiService.binder(requestBinder).subscribe(
        (BinderResponse) => {
          const data = {
            rateInput: this.rateInput,
            rateView: this.rateResponse,
            paymentInput: requestPayment,
            binderResponse: BinderResponse,
            planSelect: this.planSelect,
            paymentForm: this.paymentGroup.getRawValue()
          };
          this.progressService.progressStop();
          this.openDialogMakePayment(data);
        },
        (error) => {
          this.progressService.progressStop();
          this.alertService.AlertSimple('Payment', error?.detail);
        }
      );
    } else {
      this.paymentGroup.markAllAsTouched();
      if (this.planSelect) {
        this.alertService.AlertSimple(
          'Information',
          'Payment process cannot be completed due to missing information. Please review provided data highlighted in red to retry.'
        );
      } else {
        this.alertService.AlertSimple(
          'Information',
          'Please select a Payment Plan.'
        );
      }
    }
  }

  newFutureProcessValidation() {
    if (this.planSelect.numInstallments > 0) {
      this.getCtr('termAndCondi').setValidators([Validators.requiredTrue]);
      this.getCtr('termAndCondi').reset();
    } else {
      this.getCtr('termAndCondi').setValidators(null);
      this.getCtr('termAndCondi').reset();
    }
  }

  futureProcessValidation() {
    if (this.planSelect.numInstallments > 0) {
      if (this.planSelect.paymentType === 'EF') {
        this.paymentGroup.patchValue({
          FutureChkTypePayment: 1
        });
        this.setValidatorFutureEchek();
        this.resetFutureControlsCreditCard();
      } else {
        this.paymentGroup.patchValue({
          FutureChkTypePayment: 0
        });
        this.setValidatorFutureCreditCard();
        this.resetFutureControlsECheck();
      }
      this.getCtr('termAndCondi').setValidators([Validators.requiredTrue]);
      this.getCtr('termAndCondi').reset();
    } else {
      this.resetFutureControlsCreditCard();
      this.resetFutureControlsECheck();
      this.getCtr('termAndCondi').setValidators(null);
      this.getCtr('termAndCondi').reset();
    }
  }

  futureChangeTypePayment(typePayment: number) {
    this.paymentGroup.patchValue({
      FutureChkTypePayment: typePayment
    });
    if (typePayment === 0) {
      this.setValidatorFutureCreditCard();
      this.resetFutureControlsECheck();
    } else {
      this.setValidatorFutureEchek();
      this.resetFutureControlsCreditCard();
    }
  }

  generateFutureData() {
    let billingInfo: BillingInfo = {};
    if (Number(this.getValue('FutureChkTypePayment')) === 0) {
      const expirationDate = new Date(
        this.getValue('futureYearCreditCard'),
        +this.getValue('futureMonthCreditCard') - 1,
        1
      );
      billingInfo.creditCardInfo = {
        cardNumber: this.getValue('futureCardNumber'),
        cvc: this.getValue('futureCVVCreditCard'),
        nameOnCard: this.getValue('futureNameOnCard'),
        expirationDate: expirationDate
      };
      billingInfo.methodPaymentCd = this.futureCardType;
    } else {
      billingInfo.paymentBankInfo = {
        accountHolder:
          this.rateInput.insuredInfo.firstName +
          this.rateInput.insuredInfo.lastName,
        accountNumber: this.getValue('futureAccountNumber'),
        routingNumber: this.getValue('futureRoutingNumber'),
        accountType: this.getValue('futureECheckType'),
        firstName: this.rateInput.insuredInfo.firstName,
        lastName: this.rateInput.insuredInfo.lastName
      };
      billingInfo.methodPaymentCd = 'EFT';
    }

    return billingInfo;
  }

  resetFutureControlsCreditCard() {
    this.getCtr('futureNameOnCard')?.setValidators(null);
    this.getCtr('futureNameOnCard')?.reset();
    this.getCtr('futureCardNumber')?.setValidators(null);
    this.getCtr('futureCardNumber')?.reset();
    this.getCtr('futureYearCreditCard')?.setValidators(null);
    this.getCtr('futureYearCreditCard')?.reset();
    this.getCtr('futureMonthCreditCard')?.setValidators(null);
    this.getCtr('futureMonthCreditCard')?.reset();
    this.getCtr('futureCVVCreditCard')?.setValidators(null);
    this.getCtr('futureCVVCreditCard')?.reset();
  }
  resetFutureControlsECheck() {
    this.getCtr('futureRoutingNumber')?.setValidators(null);
    this.getCtr('futureRoutingNumber')?.reset();
    this.getCtr('futureAccountNumber')?.setValidators(null);
    this.getCtr('futureAccountNumber')?.reset();
  }
  setValidatorFutureCreditCard() {
    this.getCtr('futureNameOnCard')?.setValidators([Validators.required]);
    this.getCtr('futureNameOnCard')?.reset();
    this.getCtr('futureCardNumber')?.setValidators([
      Validators.required,
      Validators.minLength(15),
      this.validateInput
    ]);
    this.getCtr('futureCardNumber')?.reset();
    this.getCtr('futureMonthCreditCard')?.setValidators([Validators.required]);
    this.getCtr('futureMonthCreditCard')?.reset();
    this.getCtr('futureYearCreditCard')?.setValidators([Validators.required]);
    this.getCtr('futureYearCreditCard')?.reset();
    this.getCtr('futureCVVCreditCard')?.setValidators([
      Validators.required,
      Validators.minLength(3)
    ]);
    this.getCtr('futureCVVCreditCard')?.reset();
    this.paymentGroup.patchValue({
      futureMonthCreditCard: '',
      futureYearCreditCard: ''
    });
  }
  setValidatorFutureEchek() {
    this.getCtr('futureRoutingNumber')?.setValidators([Validators.required]);
    this.getCtr('futureRoutingNumber')?.reset();
    this.getCtr('futureAccountNumber')?.setValidators([Validators.required]);
    this.getCtr('futureAccountNumber')?.reset();
    this.paymentGroup.patchValue({
      futureECheckType: 'PersonalChecking'
    });
  }

  ValidationCardType(controlName: string) {
    let Bin: number = this.getValue(controlName);
    let maxLength = 3;
    let cardType = undefined;
    let routeCard = undefined;
    if (Bin) {
      if (regTypeCard.VisaReg.test(Bin.toString())) {
        cardType = 'VISA';
        routeCard = 'assets/images/payment/visa-icon.svg';
      } else if (regTypeCard.MasterReg.test(Bin.toString())) {
        cardType = 'MASTR';
        routeCard = 'assets/images/payment/mastercard-icon.svg';
      } else if (regTypeCard.AmexReg.test(Bin.toString())) {
        maxLength = 4;
        cardType = 'AMEX';
        routeCard = 'assets/images/payment/american-express-icon.svg';
      } else if (regTypeCard.DiscReg.test(Bin.toString())) {
        cardType = 'DISCV';
        routeCard = 'assets/images/payment/discover-icon.svg';
      } else {
        cardType = undefined;
        routeCard = undefined;
      }
      if (this.CardType) {
        this.getCtr(controlName).setValidators([
          Validators.required,
          Validators.minLength(maxLength)
        ]);
      }
    } else {
      cardType = undefined;
      routeCard = undefined;
    }

    return { cardType, routeCard, maxLength };
  }

  getTooltipData(plan: PaymentPlan) {
    const text =
      'Installment Fee: ' + this.formatNumberToCurrency(plan.installmentFee, 2);
    return text;
  }

  changeAddress() {
    this.alertService.AlertYesCancel('Change Address', 
    'Are you sure you want to change the address? Your current progress will be lost.').then((result) => {
      if(result) {
        this.ngZone.run(() => this.router.navigate(['/home'])).then();
      }
    });
  }

  goBack() {
    const state = {
      informationStep: this.informationStep,
      rate: this.rateResponse,
      additionalInterest: this.additionalInterest,
    };
    this.ngZone
      .run(() => this.router.navigate(['/underwriting'], { state }))
      .then();
  }

  processPaymentForm(paymentForm: paymentGroupClass) {
    this.changeTypePayment(paymentForm.chkTypePayment);

    if (paymentForm.plan.numInstallments > 0) {
      this.futureChangeTypePayment(paymentForm.FutureChkTypePayment);
    }
    setTimeout(() => {
      this.paymentGroup.patchValue(paymentForm);
    }, 500);
  }

  contactUs() {
    console.log('contactUs');
  }

  @HostListener('window:resize', ['$event'])
  setStyleDEtailsComponent() {
    const marginContainer = this.containerPage.nativeElement.offsetLeft;
    const widthContainer = this.containerPage.nativeElement.offsetWidth;

    this.renderer.setStyle(
      this.detailComponent.nativeElement,
      'right',
      marginContainer + 'px'
    );
   
    this.renderer.setStyle(
      this.detailComponent.nativeElement,
      'width',
      (widthContainer / 12) * 3 + 'px'
    );
  }

  validateInput(c: FormControl) {
    return regTypeCard.VisaReg.test(c.value) ||
      regTypeCard.MasterReg.test(c.value) ||
      regTypeCard.AmexReg.test(c.value) ||
      regTypeCard.DiscReg.test(c.value)
      ? null
      : {
          validateInput: {
            valid: false
          }
        };
  }

  // FormControl Manager
  getValue(controlName: string) {
    return this.paymentGroup.get(controlName)?.value;
  }

  getCtr(controlName: string) {
    return this.paymentGroup.get(controlName);
  }

  getError(controlName: string, error?: string) {
    if (!error) {
      error = 'required';
    }

    return (
      this.paymentGroup.get(controlName)?.hasError(error) &&
      this.paymentGroup.get(controlName)?.touched
    );
  }


  formatNumberToCurrency(numberSimple: number, minimumFractionDigits = 0) {
    const format = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits
    });
    return format.format(numberSimple);
  }

  openDialogMakePayment(data: any) {
    this.dialog.open(MakePaymentComponent, {
      disableClose: true,
      hasBackdrop: true,
      panelClass: 'custom-dialog-container',
      maxWidth: '1054px',
      data: data
    });
  }
}

export const regTypeCard = {
  get VisaReg(): RegExp {
    return /^4\d{3}-?\d{4}-?\d{4}-?\d{4}$/;
  },
  get MasterReg(): RegExp {
    return /^5[1-5]\d{2}-?\d{4}-?\d{4}-?\d{4}$/;
  },
  get AmexReg(): RegExp {
    return /^3[4,7]\d{13}$/;
  },
  get DiscReg(): RegExp {
    return /^6011-?\d{4}-?\d{4}-?\d{4}$/;
  }
};

interface paymentGroupClass {
  plan: PaymentPlan;
  chkTypePayment: number;
  // creditCard
  nameOnCard?: string;
  cardNumber?: string;
  monthCreditCard?: string;
  yearCreditCard?: string;
  CVVCreditCard?: string;
  // eCheck
  eCheckType?: string;
  routingNumber?: string;
  accountNumber?: string;
  // Billing Address
  chkBillingAddress: boolean;
  billingAddress?: string;
  billingCity?: string;
  billingState?: string;
  billingZip?: string;

  // Future payment
  FutureChkTypePayment: number;
  // creditCard
  futureNameOnCard?: string;
  futureCardNumber?: string;
  futureMonthCreditCard?: string;
  futureYearCreditCard?: string;
  futureCVVCreditCard?: string;
  // eCheck
  futureECheckType?: string;
  futureRoutingNumber?: string;
  futureAccountNumber?: string;
  // other
  termAndCondi: boolean;
}
