<footer
  class="page-footer text-center"
  fxLayout="column"
  fxLayoutAlign="space-around center"
>
  <div class="footer-container">
    <!-- Links -->
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="space-between start"
      class="font-size"
    >
      
      <ul
        class="list-unstyled page-links"
        *ngIf="currentComponent === '/' || currentComponent === '/home'"
      >
        <li>
          <a
            [routerLink]="[]"
            (click)="NavigateHome('DivProtection')"
            routerLinkActive="active"
            >Home</a
          >
        </li>
        <li>
          <a
            [routerLink]="[]"
            (click)="NavigateHome('DivAbout')"
            routerLinkActive="active"
            >About us</a
          >
        </li>
        <li>
          <a
            [routerLink]="[]"
            (click)="NavigateHome('DivRenters')"
            routerLinkActive="active"
            >Renters/Owners</a
          >
        </li>
      </ul>
      
      <ul
        class="list-unstyled page-links"
        *ngIf="currentComponent !== '/' && currentComponent !== '/home'"
      >
        <li>
          <a href="/" routerLinkActive="active">Home</a>
        </li>
      </ul>

      <ul class="list-unstyled">
        <li>
          <svg width="24" height="24" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
          </svg>
          <span>4541 N. Prospect Road Suite 302 Peoria Heights, IL 61616 </span>
        </li>
      </ul>

      <ul class="list-unstyled contact-info">
        <li>
          <img
            class="InverterColor"
            src="\assets\images\footer\phone-solid.svg"
            alt="phone"
            height="18"
          />&nbsp;
          <a href="tel:{{ phoneNumber }}"> {{ phone }}</a>
        </li>
        <li>
          <img
            class="InverterColor"
            src="\assets\images\footer\fax-solid.svg"
            alt="fax"
            height="18"
          />&nbsp;
          <a [href]="faxSafe"> {{ fax }}</a>
        </li>
      </ul>
    </div>
    <div  
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="center center"
      class="company-container"
      >
      <div>
        <span
          >Protection Choice is a product of Deposit Choice, LLC. IL License:
          100289149</span
        >
        <br />
        <span
          >Copyright &copy; Protection Choice 2021. All Rights Reserved.
        </span>
        <a class="" (click)="ShowPrivatePolicyModal()"
          >View Terms & Conditions and Privacy Policy.</a
        >
      </div>
    </div>
  </div>
</footer>
