import { Guid } from 'guid-typescript';

export class InsuredLocation{
    InsuredGuid: Guid
    InsuredLocationGuid: Guid
    LocationName: string
    Address1: string
    Address2: string
    City: string
    County: string
    State: string
    Zip: string
    ZipPlus: string
    ISOCountryCode: string
    Region: string
    LocationType: number
    Phone: string
    Fax: string
    Email: string
    Website: string
    DeliveryMethodID: number
    LocationTypeID: number
    MobileNumber: string
}
