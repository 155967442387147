import { Component, Inject, Input } from '@angular/core';
import { CONTAINER_DATA } from 'src/app/constants/tooltips';

@Component({
  selector: 'app-string-tooltip',
  templateUrl: './string-tooltip.component.html'
})
export class StringTooltipComponent {
  @Inject(CONTAINER_DATA) public componentData?: string;
  @Input() info?: string;
}
