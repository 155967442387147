<mat-horizontal-stepper #stepper>
  <mat-step [completed]="indexStepperNow > 0">
    <ng-template matStepLabel>Coverages</ng-template>
  </mat-step>
  <mat-step [completed]="indexStepperNow > 1">
    <ng-template matStepLabel>Underwriting</ng-template>
  </mat-step>
  <mat-step>
    <ng-template matStepLabel>Purchase</ng-template>
  </mat-step>
</mat-horizontal-stepper>
