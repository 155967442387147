import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/shared/services/Storage/StorageService';
import { utilities } from 'src/app/utilities/utilities';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-banner-quote-generated',
  templateUrl: './banner-quote-generated.component.html',
  styleUrls: ['./banner-quote-generated.component.scss']
})
export class BannerQuoteGeneratedComponent implements OnInit {
  quote: string;
  telContac: string;

  constructor(private storageService: StorageService,) { 
    this.telContac = utilities.numberToPhone(environment.contactPhoneInfoQuote);
  }

  ngOnInit() {
    this.storageService.getItem("quote").subscribe((value) => {
      this.quote = value;
    });
  }
}
