<div class="card">
  <div class="card-header">Your Name</div>

  <form class="card-body" [formGroup]="informationForm">
    <div
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="25px"
    >
      <div
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxLayoutGap="20px"
        class="half-width"
        formGroupName="insuredInfo"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label">
            {{ isSecondInsuredInfo ? 'First' : 'Applicant' }} Name*
          </label>
          <input
            formControlName="firstName"
            [class.is-invalid]="
              informationForm.get('insuredInfo').get('firstName').invalid &&
              informationForm.get('insuredInfo').get('firstName').touched
            "
            type="text"
            class="form-control"
          />
          <div
            *ngIf="
              (informationForm.get('insuredInfo').get('firstName').invalid &&
                informationForm.get('insuredInfo').get('firstName').touched) ||
              informationForm.get('insuredInfo').get('firstName').dirty
            "
          >
            <small
              *ngIf="
                informationForm.get('insuredInfo').get('firstName').errors
                  ?.required
              "
              class="text-danger"
            >
              {{ isSecondInsuredInfo ? 'First' : 'Applicant' }} Name is required
            </small>
            <small
              *ngIf="
                informationForm
                  .get('insuredInfo')
                  .get('firstName')
                  .hasError('pattern')
              "
              class="text-danger"
            >
              This field only admit spaces and letters
            </small>
          </div>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label">
            {{ isSecondInsuredInfo ? '' : 'Applicant' }} Last Name*
          </label>
          <input
            formControlName="lastName"
            [class.is-invalid]="
              informationForm.get('insuredInfo').get('lastName').invalid &&
              informationForm.get('insuredInfo').get('lastName').touched
            "
            type="text"
            class="form-control"
          />
          <div
            *ngIf="
              (informationForm.get('insuredInfo').get('lastName').invalid &&
                informationForm.get('insuredInfo').get('lastName').touched) ||
              informationForm.get('insuredInfo').get('lastName').dirty
            "
          >
            <small
              *ngIf="
                informationForm.get('insuredInfo').get('lastName').errors
                  ?.required
              "
              class="text-danger"
            >
              {{ isSecondInsuredInfo ? '' : 'Applicant' }} Last Name is required
            </small>
            <small
              *ngIf="
                informationForm
                  .get('insuredInfo')
                  .get('lastName')
                  .hasError('pattern')
              "
              class="text-danger"
            >
              This field only admit spaces and letters
            </small>
          </div>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxLayoutGap="20px"
        formGroupName="insuredInfo"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label"> Email* </label>
          <input
            formControlName="emailAddress"
            type="text"
            class="form-control"
            [class.is-invalid]="
              informationForm.get('insuredInfo').get('emailAddress').invalid &&
              informationForm.get('insuredInfo').get('emailAddress').touched
            "
          />
          <span
            *ngIf="
              informationForm.get('insuredInfo').get('emailAddress').touched
            "
          >
            <small
              *ngIf="
                informationForm
                  .get('insuredInfo')
                  .get('emailAddress')
                  .hasError('required')
              "
              class="text-danger"
            >
              Email is required
            </small>
            <small
              *ngIf="
                informationForm
                  .get('insuredInfo')
                  .get('emailAddress')
                  .hasError('pattern')
              "
              class="text-danger"
            >
              Please provide a valid email address
            </small>
          </span>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label"> Date of Birth* </label>

          <div
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="input-group"
          >
            <select
              formControlName="dateOfBirthMonth"
              class="form-control"
              (change)="
                changeDropDown($event, 'insuredInfo', 'dateOfBirthMonth')
              "
              [class.is-invalid]="
                informationForm.get('insuredInfo').get('dateOfBirthMonth')
                  .invalid &&
                informationForm.get('insuredInfo').get('dateOfBirthMonth')
                  .touched
              "
            >
              <option [selected]="''" [ngValue]="''" [disabled]="true">
                MM
              </option>
              <option *ngFor="let month of months" [value]="month">
                {{ formatLetNumber(month) }}
              </option>
            </select>
            <select
              formControlName="dateOfBirthDay"
              class="form-control"
              (change)="changeDropDown($event, 'insuredInfo', 'dateOfBirthDay')"
              [class.is-invalid]="
                informationForm.get('insuredInfo').get('dateOfBirthDay')
                  .invalid &&
                informationForm.get('insuredInfo').get('dateOfBirthDay').touched
              "
            >
              <option [selected]="''" [ngValue]="''" [disabled]="true">
                DD
              </option>
              <option *ngFor="let day of getDays('insuredInfo')" [value]="day">
                {{ formatLetNumber(day) }}
              </option>
            </select>
            <select
              formControlName="dateOfBirthYear"
              class="form-control"
              (change)="
                changeDropDown($event, 'insuredInfo', 'dateOfBirthYear')
              "
              [class.is-invalid]="
                informationForm.get('insuredInfo').get('dateOfBirthYear')
                  .invalid &&
                informationForm.get('insuredInfo').get('dateOfBirthYear')
                  .touched
              "
            >
              <option [selected]="''" [ngValue]="''" [disabled]="true">
                YYYY
              </option>
              <option *ngFor="let year of years" [value]="year">
                {{ year }}
              </option>
            </select>
          </div>
          <div
            class="invalid-feedback-text-danger"
            *ngIf="getErrorDateOfBirfh('insuredInfo')"
          >
            Date of Birth is required.
          </div>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label"> Phone Number* </label>
          <input
            formControlName="phoneNumber"
            type="text"
            mask="(000) 000-0000"
            class="form-control"
            [class.is-invalid]="
              informationForm.get('insuredInfo').get('phoneNumber').invalid &&
              informationForm.get('insuredInfo').get('phoneNumber').touched
            "
          />
          <div class="invalid-feedback">Phone Number is required.</div>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label"> Phone Type* </label>
          <select
            formControlName="phoneType"
            class="form-control"
            (change)="changeDropDown($event, 'insuredInfo', 'phoneType')"
            [class.is-invalid]="
              informationForm.get('insuredInfo').get('phoneType').invalid &&
              informationForm.get('insuredInfo').get('phoneType').touched
            "
          >
            <option [selected]="''" [ngValue]="''" [disabled]="true">
              Select Option
            </option>
            <option *ngFor="let item of phoneTypes" [value]="item.id">
              {{ item.desc }}
            </option>
          </select>
          <div class="invalid-feedback">Phone Type is required.</div>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxLayoutGap="20px"
        formGroupName="previousAddress"
      >
        <label class="checkbox-container">
          If you haven't lived at the address for longer that 2 years.
          <input type="checkbox" formControlName="previousAddress" />
          <span class="checkmark"></span>
        </label>
      </div>

      <!-- Previus Address -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxLayoutGap="20px"
        formGroupName="previousAddress"
        *ngIf="getPreviosCheck"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(20% - 20px)"
        >
          <label class="col-form-label input-label"> Address* </label>
          <input
            formControlName="address1"
            type="text"
            class="form-control"
            [class.is-invalid]="
              informationForm.get('previousAddress').get('address1').invalid &&
              informationForm.get('previousAddress').get('address1').touched
            "
          />
          <span
            *ngIf="
              informationForm.get('previousAddress').get('address1').touched
            "
          >
            <small
              *ngIf="
                informationForm
                  .get('previousAddress')
                  .get('address1')
                  .hasError('required')
              "
              class="text-danger"
            >
              Address is required
            </small>
          </span>
        </div>
        <!-- Address 2 -->
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(20% - 20px)"
        >
          <label class="col-form-label input-label"> Address Line 2* </label>
          <input
            formControlName="address2"
            type="text"
            class="form-control"            
          />         
        </div>
        <!-- City -->
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(20% - 20px)"
        >
          <label class="col-form-label input-label"> City* </label>
          <input
            formControlName="city"
            type="text"
            class="form-control"
            [class.is-invalid]="
              informationForm.get('previousAddress').get('city').invalid &&
              informationForm.get('previousAddress').get('city').touched
            "
          />
          <span
            *ngIf="informationForm.get('previousAddress').get('city').touched"
          >
            <small
              *ngIf="
                informationForm
                  .get('previousAddress')
                  .get('city')
                  .hasError('required')
              "
              class="text-danger"
            >
              City is required
            </small>
          </span>
        </div>
        <!-- State -->
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(20% - 20px)"
        >
          <label class="col-form-label input-label"> State* </label>
          <input
            formControlName="stateCode"
            type="text"
            class="form-control"
            [class.is-invalid]="
              informationForm.get('previousAddress').get('stateCode').invalid &&
              informationForm.get('previousAddress').get('stateCode').touched
            "
          />
          <span
            *ngIf="
              informationForm.get('previousAddress').get('stateCode').touched
            "
          >
            <small
              *ngIf="
                informationForm
                  .get('previousAddress')
                  .get('stateCode')
                  .hasError('required')
              "
              class="text-danger"
            >
              State is required
            </small>
          </span>
        </div>
        <!-- Zip Code -->
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(20% - 20px)"
        >
          <label class="col-form-label input-label"> Zip Code* </label>
          <input
            [imask]="maskZipCode"
            [unmask]="true"
            maxlength="5"
            formControlName="zipCode"
            type="text"
            class="form-control"
            [class.is-invalid]="
              informationForm.get('previousAddress').get('zipCode').invalid &&
              informationForm.get('previousAddress').get('zipCode').touched
            "
          />
          <span
            *ngIf="informationForm.get('previousAddress').get('zipCode').touched"
          >
            <small
              *ngIf="
                informationForm
                  .get('previousAddress')
                  .get('zipCode')
                  .hasError('required')
              "
              class="text-danger"
            >
              Zip Code is required
            </small>
          </span>
        </div>
      </div>

      
      <!-- END Previus Address -->

      <label class="checkbox-container" *ngIf="false">
        Would you like to include another named insured on the policy?
        <input
          type="checkbox"
          [(ngModel)]="aditionalInsured"
          (change)="setValidatorsSecondaryInsured()"
          [ngModelOptions]="{ standalone: true }"
        />
        <span class="checkmark"></span>
      </label>

      <div *ngIf="aditionalInsured" class="message-container">
        <span>
          <mat-icon aria-hidden="false" aria-label="Info">information</mat-icon>
        </span>
        <span>
          Additional Insured: To ensure that coverage is extended to another
          insured, please include the additional insured over 18 years of age
          below.
        </span>
      </div>

      <div
        *ngIf="aditionalInsured"
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxLayoutGap="20px"
        class="half-width"
        formGroupName="secondInsuredInfo"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label"> First Name* </label>
          <input
            formControlName="firstName"
            [class.is-invalid]="
              informationForm.get('secondInsuredInfo').get('firstName')
                .invalid &&
              informationForm.get('secondInsuredInfo').get('firstName').touched
            "
            type="text"
            class="form-control"
          />
          <div
            *ngIf="
              (informationForm.get('secondInsuredInfo').get('firstName')
                .invalid &&
                informationForm.get('secondInsuredInfo').get('firstName')
                  .touched) ||
              informationForm.get('secondInsuredInfo').get('firstName').dirty
            "
          >
            <small
              *ngIf="
                informationForm.get('secondInsuredInfo').get('firstName').errors
                  ?.required
              "
              class="text-danger"
            >
              First Name is required
            </small>
            <small
              *ngIf="
                informationForm
                  .get('secondInsuredInfo')
                  .get('firstName')
                  .hasError('pattern')
              "
              class="text-danger"
            >
              This field only admit spaces and letters
            </small>
          </div>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label"> Last Name* </label>
          <input
            formControlName="lastName"
            [class.is-invalid]="
              informationForm.get('secondInsuredInfo').get('lastName')
                .invalid &&
              informationForm.get('secondInsuredInfo').get('lastName').touched
            "
            type="text"
            class="form-control"
          />
          <div
            *ngIf="
              (informationForm.get('secondInsuredInfo').get('lastName')
                .invalid &&
                informationForm.get('secondInsuredInfo').get('lastName')
                  .touched) ||
              informationForm.get('secondInsuredInfo').get('lastName').dirty
            "
          >
            <small
              *ngIf="
                informationForm.get('secondInsuredInfo').get('lastName').errors
                  ?.required
              "
              class="text-danger"
            >
              Last Name is required
            </small>
            <small
              *ngIf="
                informationForm
                  .get('secondInsuredInfo')
                  .get('lastName')
                  .hasError('pattern')
              "
              class="text-danger"
            >
              This field only admit spaces and letters
            </small>
          </div>
        </div>
      </div>

      <div
        fxLayout="row"
        fxLayoutAlign="space-between start"
        fxLayoutGap="20px"
        *ngIf="aditionalInsured"
        formGroupName="secondInsuredInfo"
      >
        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label"> Email*</label>
          <input
            formControlName="emailAddress"
            type="text"
            class="form-control"
            [class.is-invalid]="
              informationForm.get('secondInsuredInfo').get('emailAddress')
                .invalid &&
              informationForm.get('secondInsuredInfo').get('emailAddress')
                .touched
            "
          />

          <span
            *ngIf="
              informationForm.get('secondInsuredInfo').get('emailAddress')
                .touched
            "
          >
            <small
              *ngIf="
                informationForm
                  .get('secondInsuredInfo')
                  .get('emailAddress')
                  .hasError('required')
              "
              class="text-danger"
            >
              Email is required
            </small>
            <small
              *ngIf="
                informationForm
                  .get('secondInsuredInfo')
                  .get('emailAddress')
                  .hasError('pattern')
              "
              class="text-danger"
            >
              Please provide a valid email address
            </small>
          </span>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label"> Date of Birth* </label>

          <div
            fxLayout="row"
            fxLayoutAlign="space-between start"
            class="input-group"
          >
            <select
              formControlName="dateOfBirthMonth"
              class="form-control"
              (change)="
                changeDropDown($event, 'secondInsuredInfo', 'dateOfBirthMonth')
              "
              [class.is-invalid]="
                informationForm.get('secondInsuredInfo').get('dateOfBirthMonth')
                  .invalid &&
                informationForm.get('secondInsuredInfo').get('dateOfBirthMonth')
                  .touched
              "
            >
              <option [selected]="''" [ngValue]="''" [disabled]="true">
                MM
              </option>
              <option *ngFor="let month of months" [value]="month">
                {{ formatLetNumber(month) }}
              </option>
            </select>
            <select
              formControlName="dateOfBirthDay"
              class="form-control"
              (change)="
                changeDropDown($event, 'secondInsuredInfo', 'dateOfBirthDay')
              "
              [class.is-invalid]="
                informationForm.get('secondInsuredInfo').get('dateOfBirthDay')
                  .invalid &&
                informationForm.get('secondInsuredInfo').get('dateOfBirthDay')
                  .touched
              "
            >
              <option [selected]="''" [ngValue]="''" [disabled]="true">
                DD
              </option>
              <option
                *ngFor="let day of getDays('secondInsuredInfo')"
                [value]="day"
              >
                {{ formatLetNumber(day) }}
              </option>
            </select>
            <select
              formControlName="dateOfBirthYear"
              class="form-control"
              (change)="
                changeDropDown($event, 'secondInsuredInfo', 'dateOfBirthYear')
              "
              [class.is-invalid]="
                informationForm.get('secondInsuredInfo').get('dateOfBirthYear')
                  .invalid &&
                informationForm.get('secondInsuredInfo').get('dateOfBirthYear')
                  .touched
              "
            >
              <option [selected]="''" [ngValue]="''" [disabled]="true">
                YYYY
              </option>
              <option *ngFor="let year of years" [value]="year">
                {{ year }}
              </option>
            </select>
          </div>
          <div
            class="invalid-feedback-text-danger"
            *ngIf="getErrorDateOfBirfh('secondInsuredInfo')"
          >
            Date of Birth is required.
          </div>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label"> Phone Number* </label>
          <input
            formControlName="phoneNumber"
            type="text"
            mask="(000) 000-0000"
            class="form-control"
            [class.is-invalid]="
              informationForm.get('secondInsuredInfo').get('phoneNumber')
                .invalid &&
              informationForm.get('secondInsuredInfo').get('phoneNumber')
                .touched
            "
          />
          <div class="invalid-feedback">Phone Number is required.</div>
        </div>

        <div
          fxLayout="column"
          fxLayoutAlign="space-between start"
          fxFlex="calc(25% - 20px)"
        >
          <label class="col-form-label input-label"> Phone Type* </label>
          <select
            formControlName="phoneType"
            class="form-control"
            (change)="changeDropDown($event, 'secondInsuredInfo', 'phoneType')"
            [class.is-invalid]="
              informationForm.get('secondInsuredInfo').get('phoneType')
                .invalid &&
              informationForm.get('secondInsuredInfo').get('phoneType').touched
            "
          >
            <option [selected]="''" [ngValue]="''" [disabled]="true">
              Select Option
            </option>
            <option *ngFor="let item of phoneTypes" [value]="item.id">
              {{ item.desc }}
            </option>
          </select>
          <div class="invalid-feedback">Phone Number is required.</div>
        </div>
      </div>
    </div>
  </form>
</div>
