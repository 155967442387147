import { Component, OnInit, ViewChild } from '@angular/core';
import { BillingAddress } from 'src/model/BillingAddress';
import { BondCoverageService } from 'src/app/shared/services/bondCoverage/bond-coverage.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PrivacyPolicyComponent } from 'src/app/shared/modal/privacy-policy/privacy-policy.component';
import IMask from 'imask/esm/imask';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-billing',
  templateUrl: './purchase-billing.component.html',
  styleUrls: ['./purchase-billing.component.scss']
})
export class PurchaseBillingComponent implements OnInit {

  oneIncURL = 'https://www.oneinc.com/';
  fee = environment.PayerFee;
  //Modal
  bsModalRefPrivatePolicy: BsModalRef;
  aceptedTerms: boolean = false;

  billingAddress = new BillingAddress();

  Billing = new FormGroup({
    AddressControl: new FormControl("", Validators.required),
    CityControl: new FormControl("", Validators.required),
    StateControl: new FormControl("", Validators.required),
    ZipCodeControl: new FormControl("", Validators.required)
  });

  get AddressControl() {
    return this.Billing.get('AddressControl')
  }

  get CityControl() {
    return this.Billing.get('CityControl')
  }

  get StateControl() {
    return this.Billing.get('StateControl')
  }

  get ZipCodeControl() {
    return this.Billing.get('ZipCodeControl')
  }

  maskName = new IMask.MaskedRegExp({
    mask: /^[a-zA-Z\s]*$/
  });
  
  maskCity = new IMask.MaskedRegExp({
    mask: /^[a-zA-Z\s]*$/
  });

  maskState = new IMask.MaskedRegExp({
    mask: /^[a-zA-Z\s]*$/
  });

  constructor(private bondCoverageServices: BondCoverageService, private modalService: BsModalService) { }

  ngOnInit() {
  }

  Validation() {
    this.setBillingAddress();
  }

  setBillingAddress() {
    this.billingAddress.Address = this.Billing.controls.AddressControl.value;
    this.billingAddress.City = this.Billing.controls.CityControl.value;
    this.billingAddress.State = this.Billing.controls.StateControl.value;
    this.billingAddress.ZipCode = this.Billing.controls.ZipCodeControl.value;
    this.billingAddress.acceptedTerms = this.aceptedTerms;
    this.bondCoverageServices.BillingAddress$.emit(this.billingAddress);
  }

  keyPress(event: any) {
    const pattern = /[0-9-]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  ShowPrivatePolicyModal() {
    this.bsModalRefPrivatePolicy = this.modalService.show(PrivacyPolicyComponent);
    this.bsModalRefPrivatePolicy.content.closeBtnName = 'Close';
  }
}
