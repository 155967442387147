import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentView } from 'src/services/generated/api-services';

@Component({
  selector: 'app-purchase-thank-you',
  templateUrl: './purchase-thank-you.component.html',
  styleUrls: ['./purchase-thank-you.component.scss']
})
export class PurchaseThankYouComponent implements OnInit {
  policyNumber = '';
  paymentResponse: PaymentView;
  decPage: string;
  acordApp: string;
  policyConfirm: string;

  constructor(public router: Router, private ngZone: NgZone) {}

  ngOnInit() {
    const { paymentResponse } = history.state;
    if (!paymentResponse) {
      this.ButtonGoBack();
      return;
    }

    this.paymentResponse = paymentResponse;
    this.policyNumber = this.paymentResponse.policyNumber;

    this.decPage = this.paymentResponse.stillWaterDocuments.find(
      (x) => x.docID === 'DecPage'
    )?.docUrl;
    this.acordApp = this.paymentResponse.stillWaterDocuments.find(
      (x) => x.docID === 'AcordApp'
    )?.docUrl;
    this.policyConfirm = this.paymentResponse.stillWaterDocuments.find(
      (x) => x.docID === 'PolicyConfirm'
    )?.docUrl;
  }

  ButtonGoBack() {
    this.ngZone.run(() => this.router.navigate(['/home'])).then();
  }
}
