import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
// Media Observer
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AddressService } from 'src/app/shared/services/address/address.service';
import { ProtectionService } from 'src/app/shared/services/protection/protection.service';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { BondCoverageService } from '../../../shared/services/bondCoverage/bond-coverage.service';

@Component({
  selector: 'app-payment-options',
  templateUrl: './payment-options.component.html',
  styleUrls: ['./payment-options.component.scss']
})
export class PaymentOptionsComponent implements OnInit, OnChanges {
  public paymentPlanAmountResponse: any[];
  public contentsAmountResponse: any[];
  @Input() ratedId: number;
  PaymentPlanModel: any;
  selectedPaymentPlanId: string = '0';
  disableForm: boolean = false;
  getAddressView: any;
  contentsAmount: any;
  getPaymentPlanView: any;

  // Media Observer
  public media$: Observable<MediaChange[]>;
  public isMobile = false;

  constructor(
    private router: Router,
    private addressService: AddressService,
    private bondCoverageService: BondCoverageService,
    media: MediaObserver,
    private protectionService: ProtectionService
  ) {
    this.media$ = media.asObservable();
  }

  ngOnInit() {
    this.media$.subscribe((mq) => {
      this.isMobile = 'xs' === mq[0].mqAlias;
    });
    this.getAddressView = this.addressService.getAddress();
    this.bondCoverageService.ContentsAmountResponse$.subscribe(
      (contentsAmount: any) => {
        this.contentsAmount = contentsAmount;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.getAddressView) {
      let paymentPlanInput: any = {
        address1: this.getAddressView?.address1,
        address2: this.getAddressView?.address2,
        city: this.getAddressView?.city,
        stateCode: this.getAddressView?.stateCode,
        zipCode: this.getAddressView?.zipCode,
        personalPropertyLimit: this.contentsAmount?.contentsAmount,
        rateId: this.contentsAmount?.rateId,
        useStillWater: true
      };
    }
  }

  onChange(index: number) {
    if (this.paymentPlanAmountResponse) {
      this.PaymentPlanModel = this.paymentPlanAmountResponse[index];
      this.selectedPaymentPlanId = index.toString();
      this.bondCoverageService.PaymentPlant$.emit(this.getPaymentPlanView);
    }
  }

  changeOpt() {
    this.protectionService.emiterPaymentPlan(this.PaymentPlanModel);
  }
}
