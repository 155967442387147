<div fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="15px">
    <div>
        <div fxLayout="column" fxLayoutAlign="space-around start">
            <div *ngIf="isMobile; then thenBlock else elseBlock"></div>
            <ng-template #thenBlock>
                <a data-toggle="collapse" href="#collapseDisclaimer" role="button" aria-expanded="false" aria-controls="collapseExample" class="TitleAnchor" (click)="toggleDisclaimer()">
                    Your Renter's Insurance Information
                    <fa-icon [icon]="togglerIcon"></fa-icon>
                </a>

                <div class="collapse" id="collapseDisclaimer">
                    <div class="bg-gray" style="padding: 10px;">
                        <label>Based on your address: <strong>{{this.getAddressView?.address1}} Unit {{unitForAddress?.unitNumber}},
                                {{getAddressView?.city + " " + getAddressView?.stateCode}}, {{getAddressView?.zipCode}}</strong>, the
                            following Renters Insurance Policies are available to you on a
                            Pre-Approved basis. All policies are written as an annual (12 month) term. Select your desired coverage and
                            payment plan below. For your convenience, we will automatically process your premium installment(s) and
                            annual renewal of policy via your preferred method of payment when due (30 days prior to end of coverage
                            period) so that no lapse in insurance coverage will occur until your policy is cancelled. See payment
                            information as to processing fees that will apply. Please take a few moment to review your coverage.</label>
                    </div>
                </div>
            </ng-template>
            <ng-template #elseBlock>
                <div class="title">
                    Your Renter's Insurance Information
                </div>

                <div id="collapseDisclaimer">
                    <div class="bg-gray" style="padding: 10px;">
                        <label>Based on your address: <strong>{{getAddressView?.address1}} Unit {{unitForAddress?.unitNumber}},
                                {{getAddressView?.city + " " + getAddressView?.stateCode}}, {{getAddressView?.zipCode}}</strong>, the
                            following Renters Insurance Policies are available to you on a
                            Pre-Approved basis. All policies are written as an annual (12 month) term. Select your desired coverage and
                            payment plan below. For your convenience, we will automatically process your premium installment(s) and
                            annual renewal of policy via your preferred method of payment when due (30 days prior to end of coverage
                            period) so that no lapse in insurance coverage will occur until your policy is cancelled. See payment
                            information as to processing fees that will apply. Please take a few moment to review your coverage.</label>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>

    <div>
        <div class="subTitle" style="padding-bottom: 4px">Property Insurance Policy</div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxLayoutGap="15px" fxFill>
            <div [formGroup]="General" fxFlex="25%">
                <label id="basic-addon2" class="col-form-label LabelText">Personal Property Limit:</label>
                <select formControlName="CoverageOptionControl" class="form-control" id="inputGroupSelect01"
                    (change)="ChangeComboAmount()">
                    <option *ngFor=" let contents of contentsAmountView?.contentsAmounts" [value]="contents.contentsId">
                        {{contents.contentsAmount | currency:'USD':'symbol':'.0-0'}}
                    </option>
                </select>
            </div>
            <div fxFlex="25%">
                <label class="col-form-label LabelText">Liability Limit:&nbsp;</label>
                <br>
                <span>{{contentsAmountView?.liabilityAmount}}</span>
            </div>

            <div fxFlex="25%">
                <label class="col-form-label LabelText">Deductible:&nbsp; </label>
                <br>
                <span>{{contentsAmountView?.deductible}}</span>
            </div>

            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" fxFlex="25%">
                <label class="col-form-label Premium">PREMIUM (what you pay)</label>
                <span class="PremiumValue">{{paymentPlan?.fullTermAmt | currency:'USD':'symbol':'.0-0'}}</span>
            </div>
        </div>

        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between stretch" fxFill fxLayoutGap="15px" [formGroup]="General" class="form-group DatePicker">
            <div fxFlex="25%">
                <label for="" class="LabelText">Effective Date</label>
                <div class="input-group">
                    <input formControlName="MoveInDateControl"
                        [class.is-invalid]="MoveInDateControl.invalid && MoveInDateControl.touched" type="text"
                        (focusout)="ValidationOwner()" class="form-control Input" #dp="bsDatepicker" bsDatepicker
                        placement="right"
                        [bsConfig]="{ showWeekNumbers:false, adaptivePosition: true }" [minDate]="minDate" [maxDate]="maxDate">
                    <div class="input-group-append">
                        <span class="input-group-text" id="basic-addon2"><img class="filterSVG-Green"
                                src="assets\images\purchase\calendar-alt-solid.svg" height="20px" alt=""></span>
                    </div>
                </div>
                <div *ngIf="(MoveInDateControl.invalid && MoveInDateControl.touched) || MoveInDateControl.dirty">
                    <small *ngIf="MoveInDateControl.errors?.required" class="text-danger">Effective Date required</small>
                    <small *ngIf="MoveInDateControl.errors?.bsDate?.maxDate" class="text-danger">Effective Date cannot be more than 6 months from today</small>
                </div>
            </div>
        </div>
    </div>

    <div>
        <div class="subTitle" style="padding-bottom: 4px">Your Name</div>

        <div [formGroup]="General" fxLayout.lt-md="column" fxLayout="row" fxLayoutAlign="space-between stretch" fxLayoutGap="15px" fxFill>
            <div fxFlex="25">
                <label for="" class="LabelText">First Name*</label>
                <input formControlName="FirstNameControl"
                    [class.is-invalid]="FirstNameControl.invalid && FirstNameControl.touched" type="text"  [imask]="firstNameMask" [unmask]="true"
                    (focusout)="ValidationOwner()" class="form-control" id="FirstNameControl">
                <div *ngIf="(FirstNameControl.invalid && FirstNameControl.touched) || FirstNameControl.dirty">
                    <small *ngIf="FirstNameControl.errors?.required" class="text-danger">First Name is required</small>
                    <small *ngIf="FirstNameControl.hasError('pattern')" class="text-danger">This field only admit spaces and letters</small>
                </div>
            </div>
            <div fxFlex="25">
                <label for="" class="LabelText">Last Name*</label>
                <input formControlName="LastNameControl"
                    [class.is-invalid]="LastNameControl.invalid && LastNameControl.touched" type="text"  [imask]="lastNameMask" [unmask]="true"
                    (focusout)="ValidationOwner()" class="form-control" id="LastNameControl">
                <div *ngIf="(LastNameControl.invalid && LastNameControl.touched) || LastNameControl.dirty">
                    <small *ngIf="LastNameControl.errors?.required" class="text-danger">Last Name is required</small>
                    <small *ngIf="LastNameControl.hasError('pattern')" class="text-danger">This field only admit spaces and letters</small>
                </div>
            </div>
            <div fxFlex="25">
                <label for="" class="LabelText">Email*</label>
                <input [class.is-invalid]="EmailControl.invalid && EmailControl.touched" (focusout)="ValidationOwner()"
                    type="email" class="form-control" formControlName="EmailControl" name="email" />
                <div *ngIf="(EmailControl.invalid && EmailControl.touched) || EmailControl.dirty">
                    <small *ngIf="EmailControl.errors?.required" class="text-danger">Email is required</small>
                    <small *ngIf="EmailControl.errors?.pattern" class="text-danger">Please provide a valid email address</small>
                </div>
            </div>
            <div fxFlex="25">
                <label for="" class="LabelText">Primary Phone</label>
                <input [imask]="{mask: '000-000-0000'}" [unmask]="false" [class.is-invalid]="PrincipalPhone.invalid && PrincipalPhone.touched" (focusout)="ValidationOwner()"
                    type="text" class="form-control" formControlName="PrincipalPhone" name="PrincipalPhone" />
                <div *ngIf="(PrincipalPhone.invalid && PrincipalPhone.touched) || PrincipalPhone.dirty">
                    <small *ngIf="PrincipalPhone.hasError('minlength')" class="text-danger">This field must have at least 10 numbers</small>
                </div>
            </div>
        </div>
    </div>
</div>
