import { NgModule } from '@angular/core';
import {
  API_BASE_URL,
  ApiService,
  IConfig,
  PersonalPropertyService
} from './generated/api-services';
import { environment } from 'src/environments/environment';

const tokenPC = () => environment.ProtectionToken;
const URLPC = () => environment.ProtectionURL;
@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: API_BASE_URL,
      useFactory: URLPC,
      multi: false
    },
    {
      provide: IConfig,
      useFactory: tokenPC,
      multi: false
    },
    ApiService,
    PersonalPropertyService
  ]
})
export class ApiServicesModule {}
