<app-header></app-header>
<div
  class="layout"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  fxLayoutGap="20px"
>
  <app-stepper></app-stepper>
  <h1>
    We’ll need to ask a few questions in order to get you the best possible
    coverage options:
  </h1>
  
  <form [formGroup]="underwritingForm" class="form-array">
    <div
      formArrayName="questions"
      style="overflow: hidden"
      gdLayout.gt-sm="row"
      gdGap.gt-sm="25px"
    >
      <ng-container *ngFor="let item of questions.controls; let i = index">
        <mat-expansion-panel
          hideToggle
          [expanded]="item.get('answer').value"
          disabled
          [formGroupName]="i"
          class="padding-bottom cuestion-container"
          [ngClass]="{ 'error-ck-r-custom': item.invalid && item.touched }"
          [ngClass]="{
            expanded:
              item.get('answer').value &&
              item.get('childQuestions')?.value?.length
          }"
        >
          <mat-expansion-panel-header>
            <!-- Question text -->
            <div
              class="w-100 header-content"
              fxLayout="row"
              fxLayoutAlign="space-between start"
            >
              <div
                fxLayout="column"
                fxLayoutAlign="space-between start"
                fxLayoutGap="15px"
              >
                <mat-label class="title-question">
                  {{ item.get('question')?.value }}
                </mat-label>
                <mat-radio-group
                  formControlName="answer"
                  aria-label="Select an option"
                  (change)="questionChange(item)"
                >
                  <mat-radio-button class="answer-question" [value]="true">
                    Yes
                  </mat-radio-button>
                  <mat-radio-button class="answer-question" [value]="false">
                    No
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <mat-icon
                class="icon-question"
                [svgIcon]="item.get('icon')?.value"
              ></mat-icon>
            </div>
            <input type="text" hidden formControlName="eligibilityQuestionId" />
          </mat-expansion-panel-header>
          <div
            fxLayout="column"
            fxLayoutAlign="space-between stretch"
            fxLayoutGap="10px"
            class="body-container"
            formArrayName="childQuestions"
          >
            <ng-container
              *ngFor="
                let question of getFormArrayChild(item).controls;
                let j = index
              "
            >
              <div
                fxLayout="column"
                fxLayoutAlign="space-between stretch"
                fxLayoutGap="5px"
                [formGroupName]="j"
              >
                <p>{{ question.get('question').value }}</p>
                <mat-radio-group
                  aria-label="Select an option"
                  *ngIf="question.get('isYesNo').value"
                  (change)="changeChildQuestion($event, i, j)"
                  formControlName="answer"
                >
                  <mat-radio-button class="answer-question" [value]="true">
                    Yes
                  </mat-radio-button>
                  <mat-radio-button class="answer-question" [value]="false">
                    No
                  </mat-radio-button>
                </mat-radio-group>

                <input
                  *ngIf="!question.get('isYesNo').value"
                  class="form-control"
                  placeholder="Your answer"
                  [ngClass]="{
                    'is-invalid': question.invalid && question.touched
                  }"
                  formControlName="answer"
                  type="text"
                  (keyup)="changeChildQuestion($event, i, j)"
                  currencyMask 
                  [options]="getMaskNumber()"
                  maxlength="8"
                />
                <div class="invalid-feedback">Your answer is required.</div>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
      </ng-container>
    </div>
  </form>
  <app-stepper-buttons
    backPath="/information"
    [backParams]="informationStep"
    [handleNext]="true"
    (onNext)="preRate()"
    [disableNext]="underwritingForm.invalid"
  ></app-stepper-buttons>
</div>