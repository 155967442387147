

export class Owner{
    FirstName?: string;
    LastName?: string;
    Email?: string;
    MoveOnDate?: Date;
    Address?: any;
    PrincipalPhone?: string;
}
