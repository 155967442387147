import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AddressService } from 'src/app/shared/services/address/address.service';
import { BondCoverageService } from 'src/app/shared/services/bondCoverage/bond-coverage.service';
import { Property } from 'src/model/Property';
import { BondCoverage } from 'src/model/BondCoverage';
import { Owner } from 'src/model/Owner';
import { Occupant } from 'src/model/Occupant';
import { Payment } from 'src/model/Payment';
import { BillingAddress } from 'src/model/BillingAddress';
import Swal from 'sweetalert2';
import { environment } from 'src/environments/environment';

// Media Observer
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable } from 'rxjs';
import { ProtectionService } from 'src/app/shared/services/protection/protection.service';

@Component({
  selector: 'app-purchase-bond-coverage',
  templateUrl: './purchase-bond-coverage.component.html',
  styleUrls: ['./purchase-bond-coverage.component.scss']
})
export class PurchaseBondCoverageComponent implements OnInit {
  public media$: Observable<MediaChange[]>;
  public isMobile = false;

  constructor(
    private router: Router,
    private bondCoverageBondService: BondCoverageService,
    private addressService: AddressService,
    private protectionService: ProtectionService,
    media: MediaObserver
  ) {
    this.media$ = media.asObservable();
  }

  getAddressView: any;
  unitsForAddress: any;
  paymentPlan: any;
  bondCoverageService: BondCoverage[] = [];

  Property = new Property();
  BondCoverage = new BondCoverage();
  ContentsAmountResponse: any;
  PaymentPlanAmountResponse: any;
  Owner = new Owner();
  Occupants = new Occupant();
  Payment = new Payment();
  BillingAddress = new BillingAddress();

  // If Address
  AddressFullName: string;
  AddressStreet: string;
  AddressCity: string;
  AddressState: string;
  AddressZipCode: string;

  // CardNumber
  LastCardNumber: string;
  processingFee: number;

  ngOnInit() {
    this.media$.subscribe((mq) => {
      this.isMobile = 'xs' === mq[0].mqAlias;
    });

    this.getAddressView = this.addressService.getAddress();
    // Set Property
    this.unitsForAddress = this.addressService.getUnitForAddressLocal();

    if (this.unitsForAddress) {
      this.Property.CommunityName = this.unitsForAddress.communityName;
      this.Property.CommunityNumber = this.unitsForAddress.communityId;
      this.Property.PropertyName = this.unitsForAddress.managementName;
    }

    this.bondCoverageBondService.Property$.subscribe((property: Property) => {
      this.Property = property;
    });

    this.bondCoverageBondService.ContentsAmountResponse$.subscribe(
      (contentsAmountResponse: any) => {
        this.ContentsAmountResponse = contentsAmountResponse;
      }
    );

    this.bondCoverageBondService.PaymentPlant$.subscribe(
      (paymentPlanResponse: any) => {
        this.paymentPlan = paymentPlanResponse;
      }
    );

    this.protectionService.PaymentPlanEnd$.subscribe((paymentPlan) => {
      this.PaymentPlanAmountResponse = paymentPlan;

      this.processingFee = environment.PayerFee;
    });

    this.bondCoverageBondService.BondCoverage$.subscribe(
      (bondCoverage: BondCoverage) => {
        this.BondCoverage = bondCoverage;
      }
    );

    this.bondCoverageBondService.Owner$.subscribe((owner: Owner) => {
      this.Owner = owner;
    });

    this.bondCoverageBondService.Occupants$.subscribe((occupants: Occupant) => {
      this.Occupants = occupants;
    });

    this.bondCoverageBondService.Payment$.subscribe((payment: Payment) => {
      this.Payment = payment;
      this.LastCardNumber = '***' + this.Payment.CardNumber.substr(13);
      this.AddressFullName = this.Payment?.NameOnCard;
      if (this.Owner) {
        this.AddressStreet = this.BillingAddress?.Address;
        this.AddressCity = this.BillingAddress?.City;
        this.AddressState = this.BillingAddress?.State;
        this.AddressZipCode = this.BillingAddress?.ZipCode;
      }
    });

    this.bondCoverageBondService.BillingAddress$.subscribe(
      (billingAddress: BillingAddress) => {
        this.BillingAddress = billingAddress;
        this.AddressFullName = this.Payment?.NameOnCard;
        this.AddressStreet = this.BillingAddress?.Address;
        this.AddressCity = this.BillingAddress?.City;
        this.AddressState = this.BillingAddress?.State;
        this.AddressZipCode = this.BillingAddress?.ZipCode;
      }
    );
  }

  ValidationExit(NamePage: string) {
    if (this.getAddressView) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The changes will be lost!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#046a38',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, exit!',
        scrollbarPadding: false
      }).then((result) => {
        if (result.value) {
          this.addressService.deleteAddress();
          this.router.navigate(['/' + NamePage]);
        }
      });
    } else {
      this.router.navigate(['/' + NamePage]);
    }
  }
}
