import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { DocuSignDocumentStatusResquest } from 'src/model/Protection/DocuSignDocumentStatusRequest';
import { DocuSignDocumentStatusResponse } from 'src/model/Protection/DocuSignDocumentStatusResponse';
import { ChargeCodeRequest } from 'src/model/Deposit/ChargeCodeRequest';
import { ChargeCodeResponse } from 'src/model/Deposit/ChargeCodeResponse';
@Injectable({
  providedIn: 'root'
})
export class ProtectionService {
  API_URL: any = environment.ProtectionURL;
  TOKEN: any = environment.ProtectionToken;
  headersPC = new HttpHeaders({
    Authorization: 'Bearer ' + this.TOKEN
  });

  private LiabilityAmountSource = new Subject<any>();
  public LiabilityAmountEnd$ = this.LiabilityAmountSource.asObservable();

  private PaymentPlanSource = new Subject<any>();
  public PaymentPlanEnd$ = this.PaymentPlanSource.asObservable();

  private ContentsAmountSource = new Subject<any>();
  public ContentsAmountEnd$ = this.ContentsAmountSource.asObservable();

  emiterContentsAmount(op: any){
    this.ContentsAmountSource.next(op);
  }

  emiterLiability(op: any) {
    this.LiabilityAmountSource.next(op)
  }

  emiterPaymentPlan(op: any) {
    this.PaymentPlanSource.next(op);
  }

  constructor(private http: HttpClient) { }

  GetDocuSignDocumentStatus(request: DocuSignDocumentStatusResquest) {
    const baseURL = this.API_URL + '/api/DocuSignDocument/GetDocuSignDocumentStatus';
    return this.http.post<DocuSignDocumentStatusResponse>(baseURL, request,
      {
        headers: this.headersPC,
        reportProgress: false
      });
  }

  async getChargeCode(chargeCodeRequest: ChargeCodeRequest){
    const baseURL = this.API_URL + '/api/ChargeCode/GetChargeCode';
    return await this.http.post<ChargeCodeResponse>(baseURL, chargeCodeRequest, { headers: this.headersPC, reportProgress: false }).toPromise();
   }
}
