import IMask from 'imask';
import { CurrencyMaskConfig, CurrencyMaskInputMode } from 'ngx-currency';
class Masks {
  get maskCurrency() {
    return new IMask.MaskedNumber({
      mask: Number,
      scale: 0,
      signed: false,
      thousandsSeparator: '',
      padFractionalZeros: false,
      normalizeZeros: false
    });
  }
  get maskOnlyNumber() {
    return new IMask.MaskedRegExp({
      mask: /^[0-9]*$/
    });
  }
  get maskZipCode() {
    return new IMask.MaskedRegExp({
      mask: /^[0-9]{5}$/
    });
  }
  get maskPolicyNumber() {
    return new IMask.MaskedRegExp({
      mask: /^[0-9]{9}-[0-9]{2}$/
    });
  }
  get maskOnlyLetters() {
    return new IMask.MaskedRegExp({
      mask: /^[a-zA-Z\s]*$/
    });
  }
  get maskOnlyLettersNumber() {
    return new IMask.MaskedRegExp({
      mask: /^[A-Za-z0-9]*$/
    });
  }
  get maskOnlyLettersNumberHyphen() {
    return new IMask.MaskedRegExp({
      mask: /^[A-Za-z0-9_-\s]*$/
    });
  }
  get maskOnlyLettersNumberSpacing() {
    return new IMask.MaskedRegExp({
      mask: /^[A-Za-z0-9\s]*$/
    });
  }
  get maskOnlyLettersNotSpacing() {
    return new IMask.MaskedRegExp({
      mask: /^[A-Za-z]*$/
    });
  }
  //   ngx-currency
  get maskPercentage(): CurrencyMaskConfig {
    return {
      align: 'center',
      allowNegative: true,
      allowZero: true,
      decimal: ',',
      precision: 0,
      prefix: '',
      suffix: '%',
      thousands: '.',
      nullable: true,
      min: 0,
      max: 100,
      inputMode: CurrencyMaskInputMode.NATURAL
    };
  }

  get maskPercentageNotLimit(): CurrencyMaskConfig {
    return {
      align: 'center',
      allowNegative: true,
      allowZero: true,
      decimal: ',',
      precision: 0,
      prefix: '',
      suffix: '%',
      thousands: '.',
      nullable: true,
      min: 0,
      inputMode: CurrencyMaskInputMode.NATURAL
    };
  }

  get maskNumberPrefix(): CurrencyMaskConfig {
    return {
      align: 'center',
      allowNegative: true,
      allowZero: true,
      decimal: '.',
      precision: 0,
      prefix: '$ ',
      suffix: '',
      thousands: ',',
      nullable: true,
      min: 0,
      inputMode: CurrencyMaskInputMode.NATURAL
    };
  }

  get maskNGXOnlyNumber(): CurrencyMaskConfig {
    return {
      align: 'left',
      allowNegative: false,
      allowZero: true,
      decimal: '.',
      precision: 0,
      prefix: '',
      suffix: '',
      thousands: '',
      nullable: true,
      min: 0,
      inputMode: CurrencyMaskInputMode.NATURAL
    };
  }
}

export const masks = new Masks();
