export class Insured{
    BusinessTypeID: number
    Salutation: string
    FirstName: string
    MiddleName: string
    LastName: string
    CorporationName: string
    NameOnPolicy: string
    DBA: string
    FEIN: string
    SSN: string
    DateOfBirth: object
    RiskId: string
}