import { Component, OnInit } from '@angular/core';
import { Payment } from 'src/model/Payment';
import { BondCoverageService } from 'src/app/shared/services/bondCoverage/bond-coverage.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-purchase-payment',
  templateUrl: './purchase-payment.component.html',
  styleUrls: ['./purchase-payment.component.scss']
})
export class PurchasePaymentComponent implements OnInit {

  maxLength: number = 3
  CardType: string;
  Route: string;
  yearsData: number[] = [];

  payment = new Payment();

  VisaReg: RegExp = /^4\d{3}-?\d{4}-?\d{4}-?\d{4}$/;
  MasterReg: RegExp = /^5[1-5]\d{2}-?\d{4}-?\d{4}-?\d{4}$/;
  AmexReg: RegExp = /^3[4,7]\d{13}$/;
  DiscReg: RegExp = /^6011-?\d{4}-?\d{4}-?\d{4}$/;

  //FromGroup
  PaymentGroup = new FormGroup({
    NameOnCardControl: new FormControl("", [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
    CardNumberControl: new FormControl("", [Validators.required, this.validateInput]),
    ExpMonthControl: new FormControl("", Validators.required),
    ExpYearControl: new FormControl("", Validators.required),
    CVVControl: new FormControl("", Validators.required)
  });

  validateInput(c: FormControl) {
   let VisaReg: RegExp = /^4\d{3}-?\d{4}-?\d{4}-?\d{4}$/;
   let MasterReg: RegExp = /^5[1-5]\d{2}-?\d{4}-?\d{4}-?\d{4}$/;
   let AmexReg: RegExp = /^3[4,7]\d{13}$/;
   let DiscReg: RegExp = /^6011-?\d{4}-?\d{4}-?\d{4}$/;
  
    return (VisaReg.test(c.value) || MasterReg.test(c.value)) || AmexReg.test(c.value) || DiscReg.test(c.value) ? null : {
      validateInput: {
        valid: false
      }
    };
  }

  get NameOnCardControl(){
    return this.PaymentGroup.get('NameOnCardControl')
  }

  get CardNumberControl(){
    return this.PaymentGroup.get('CardNumberControl')
  }

  get ExpMonthControl(){
    return this.PaymentGroup.get('ExpMonthControl')
  }

  get ExpYearControl(){
    return this.PaymentGroup.get('ExpYearControl')
  }

  get CVVControl(){
    return this.PaymentGroup.get('CVVControl')
  }
  

  constructor(private bondCoverageService: BondCoverageService) { }

  ngOnInit() {
    const dateNow = new Date();
    const month = dateNow.getMonth();
    const yearNow = dateNow.getFullYear();
    this.ExpMonthControl.valueChanges.subscribe((monthValue) => {
      this.yearsData = [];

      if (Number(monthValue) > month) {
        this.yearsData.push(yearNow);
      }
      for (let i = 1; i < 11; i++)
        this.yearsData.push(yearNow + i);
    });
  }

  keyPress(event: any) {
    const pattern = /[0-9]/;
    const inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  ValidationCardType() {
    let Bin: number = this.PaymentGroup.controls.CardNumberControl.value

    if (this.VisaReg.test(Bin.toString())) {
      this.maxLength = 3;
      this.CardType = "Visa";
      this.Route = "assets/images/payment/visa-icon.svg";
    } else if (this.MasterReg.test(Bin.toString())) {
      this.maxLength = 3;
      this.CardType = "Master Card";
      this.Route = "assets/images/payment/mastercard-icon.svg";
    } else if (this.AmexReg.test(Bin.toString())) {
      this.maxLength = 4;
      this.CardType = "American Express";
      this.Route = "assets/images/payment/american-express-icon.svg";
    } else if (this.DiscReg.test(Bin.toString())) {
      this.maxLength = 3;
      this.CardType = "Discover";
      this.Route = "assets/images/payment/discover-icon.svg";
    } else {
      this.CardType = undefined;
      this.Route = undefined;
    }
  }

  LostFocusCardNumber() {
    this.ValidationCardType();
    this.Validation();
  }

  Validation() {
    if (this.PaymentGroup.status === "VALID" && this.CardType) {
      this.SetPaymet();
    }
  }

  SetPaymet() {
    this.payment.NameOnCard = this.PaymentGroup.controls.NameOnCardControl.value;
    this.payment.CardType = this.CardType;
    this.payment.CardNumber = this.PaymentGroup.controls.CardNumberControl.value;
    this.payment.ExpMonth = this.PaymentGroup.controls.ExpMonthControl.value;
    this.payment.ExpYear = this.PaymentGroup.controls.ExpYearControl.value;
    this.payment.CVV = this.PaymentGroup.controls.CVVControl.value;
    this.payment.SecondCardType = this.GetSecondCardType();
    this.bondCoverageService.Payment$.emit(this.payment);
  }

  GetSecondCardType():string{
    switch(this.CardType){
      case 'Visa':
        return 'VISA'
      case 'Master Card':
        return 'MASTR';
      case 'American Express':
        return 'AMEX';
      case 'Discover':
        return 'DISCV';
    }
  }

}
