import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor() {}

  CloseAll() {
    Swal.close();
  }

  ToastAlert(message: any, icon: any) {
    const Toast = Swal.mixin({
      toast: true,
      position: 'bottom-end',
      showConfirmButton: false,
      timer: 4000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
    });

    Toast.fire({
      icon,
      title: message,
      customClass: {
        container: 'toast-custom',
        icon: 'toast-icon',
        title: 'toast-title'
      }
    });
  }

  AlertYesCancel(title: string, text: string): Promise<boolean> {
    const alert = Swal.fire({
      title,
      html: text,
      imageUrl: 'assets/images/alert-circle.svg',
      imageWidth: 60,
      imageHeight: 60,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn-swal-alert',
        cancelButton: 'btn-swal-alert',
        image: 'icon-filter',
        title: 'title-alert',
        content: 'content-alert'
      },
      scrollbarPadding: false
    });
    return alert.then((result) => {
      if (result.value) {
        return true;
      } else {
        return false;
      }
    });
  }

  AlertSimpleTryAgain(title: string, text: string) {
    const alert = Swal.fire({
      title,
      text,
      imageUrl: 'assets/images/alert-circle.svg',
      imageWidth: 60,
      imageHeight: 60,
      confirmButtonText: 'Try Again',
      customClass: {
        confirmButton: 'btn-swal-alert',
        image: 'icon-filter',
        title: 'title-alert',
        content: 'content-alert'
      },
      scrollbarPadding: false
    });
  }

  AlertSimple(title: string, text: string) {
    const alert = Swal.fire({
      title,
      text,
      imageUrl: 'assets/images/alert-circle.svg',
      imageWidth: 60,
      imageHeight: 60,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn-swal-alert',
        image: 'icon-filter',
        title: 'title-alert',
        content: 'content-alert'
      },
      scrollbarPadding: false
    });
  }

  AlertSimpleHTML(title: string, html: string) {
    const alert = Swal.fire({
      title,
      html,
      imageUrl: 'assets/images/alert-circle.svg',
      imageWidth: 60,
      imageHeight: 60,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn-swal-alert',
        image: 'icon-filter',
        title: 'title-alert',
        content: 'content-alert'
      },
      scrollbarPadding: false
    });
  }

  AlertSimplePromise(title: string, text: string) {
    const alert = Swal.fire({
      title,
      text,
      imageUrl: 'assets/images/alert-circle.svg',
      imageWidth: 60,
      imageHeight: 60,
      confirmButtonText: 'Try Again',
      customClass: {
        confirmButton: 'btn-swal-alert',
        image: 'icon-filter',
        title: 'title-alert',
        content: 'content-alert'
      },
      scrollbarPadding: false
    });
    return alert;
  }
  AlertSimpleOkPromise(title: string, text: string) {
    const alert = Swal.fire({
      title,
      text,
      imageUrl: 'assets/images/alert-circle.svg',
      imageWidth: 60,
      imageHeight: 60,
      confirmButtonText: 'Ok',
      customClass: {
        confirmButton: 'btn-swal-alert',
        image: 'icon-filter',
        title: 'title-alert',
        content: 'content-alert'
      },
      scrollbarPadding: false
    });
    return alert;
  }

  AlertSimpleOK(title: string, text: string) {
    const alert = Swal.fire({
      title,
      text,
      imageUrl: 'assets/images/check_circle.svg',
      imageWidth: 60,
      imageHeight: 60,
      confirmButtonText: 'OK',
      customClass: {
        confirmButton: 'btn-swal-alert',
        image: 'alertSuccessGreenIcon',
        title: 'title-alert',
        content: 'content-alert'
      },
      scrollbarPadding: false
    });
  }

  AlertMakePaymentPromise(title: string, text: string) {
    const alert = Swal.fire({
      title,
      text,
      imageUrl: 'assets/images/check_circle.svg',
      imageWidth: 60,
      imageHeight: 60,
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: 'Continue',
      customClass: {
        confirmButton: 'btn-swal-alert',
        image: 'iconFilterMakePayment',
        title: 'title-alert',
        content: 'content-alertMakePayment',
        popup: 'swal2-makePayment'
      },
      scrollbarPadding: false
    });
    return alert;
  }

  AlertCustomBtn(
    title: string,
    text: string,
    btnConfirm: string,
    btnCancel: string
  ): Promise<boolean> {
    const alert = Swal.fire({
      title,
      html: text,
      imageUrl: 'assets/images/alert-circle.svg',
      imageWidth: 60,
      imageHeight: 60,
      showCancelButton: true,
      confirmButtonText: btnConfirm,
      cancelButtonText: btnCancel,
      allowEnterKey: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      customClass: {
        confirmButton: 'btn-swal-alert',
        cancelButton: 'btn-swal-alert',
        image: 'icon-filter',
        title: 'title-alert',
        content: 'content-alert'
      },
      scrollbarPadding: false
    });
    return alert.then((result) => {
      if (result.value) {
        return true;
      } else {
        return false;
      }
    });
  }
}
