import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressSpinnerService {
  public progressSpinnerStatus: Observable<boolean>;
  private progressSpinnerStatusSource = new Subject<boolean>();

  constructor() {
    this.progressSpinnerStatus = this.progressSpinnerStatusSource.asObservable();
  }

  progressStart() {
    setTimeout(() => {
      this.progressSpinnerStatusSource.next(true);
    }, 100);
  }

  progressStop() {
    setTimeout(() => {
      this.progressSpinnerStatusSource.next(false);
    }, 100);
  }
}
