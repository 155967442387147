<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="40px" class="w-100">
  <button *ngIf="backPath" (click)="back()" class="btn back">< Go Back</button>
  <button
    mat-raised-button
    *ngIf="nextPath || handleNext"
    (click)="next()"
    class="btn next"
    [disabled]="disableNext"
  >
    Continue >
  </button>
</div>
