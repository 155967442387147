import { Injectable } from '@angular/core';
import { SuccessfullyDone } from 'src/model/successfullyService/SuccessfullyDone';

@Injectable({
  providedIn: 'root'
})
export class SuccessfullyService {

  successfullyDone: SuccessfullyDone;

  constructor() { }

  setSuccessfullyDone(successfullyDone: SuccessfullyDone) {
    this.successfullyDone = successfullyDone;
  }

  getSuccessfullyDone(): SuccessfullyDone {
    return this.successfullyDone;
  }
}
