import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { values } from 'ramda';
import { InformationForm } from 'src/app/interfaces/information';
import { BondCoverageService } from 'src/app/shared/services/bondCoverage/bond-coverage.service';
import { Owner } from 'src/model/Owner';
import {
  ConstructionType,
  InsuredAddress
} from 'src/services/generated/api-services';
import { environment } from 'src/environments/environment';
import { utilities } from 'src/app/utilities/utilities';

@UntilDestroy()
@Component({
  selector: 'app-your-info',
  templateUrl: './your-info.component.html',
  styleUrls: ['./your-info.component.scss']
})
export class YourInfoComponent implements OnChanges {
  @Input() liabilityLimit?: number;
  @Input() premium?: number;
  @Input() personalPropertyLimits?: number[];
  @Input() personalLiabilityLimits?: number[];
  @Input() deductibles?: number[];
  @Input() constructionTypes?: ConstructionType[];

  @Input() personalPropertyLimit?: number;
  @Input() deductible?: number;
  @Input() constructionTypeId?: string;
  @Input() policyStartDate?: Date;
  @Input() address?: InsuredAddress;

  @Output() form = new EventEmitter<InformationForm>();
  @Output() isFormValid = new EventEmitter<boolean>();
  @Output() liabilityLimitChange = new EventEmitter<number>();

  contentsAmountView?: any;
  getAddressView?: any;

  informationForm = new FormGroup({
    constructionTypeId: new FormControl('', [Validators.required]),
    personalLiabilityLimit: new FormControl('', [Validators.required]),
    deductible: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$')
    ]),
    personalPropertyLimit: new FormControl('', [
      Validators.required,
      Validators.pattern('^[0-9]*$')
    ]),
    policyStartDate: new FormControl(utilities.createDate(new Date()), [Validators.required])
  });

  maxDate?: Date;
  minDate?: Date;
  owner = new Owner();
  paymentPlan: any;
  unitForAddress?: any;

  tooltipContentsCoverage = environment.tooltipContentsCoverage;
  tooltipLiabilityLimit = environment.tooltipLiabilityLimit;

  constructor(private bondCoverageService: BondCoverageService) {
    this.minDate = utilities.createDate(new Date());
    this.maxDate = utilities.createDate(new Date());
    this.maxDate.setDate(this.maxDate.getDate() + 90);

    this.informationForm.valueChanges.pipe(untilDestroyed(this)).subscribe({
      next: (value) => {
        const hasAll = values(value).every((val) => val);
        if (hasAll) this.form.emit(<InformationForm>value);
      }
    });
    this.informationForm.statusChanges.subscribe((status) => {
      const statusReturn = status === 'VALID';
      this.isFormValid.emit(statusReturn);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const liabilityLimitChanged =
      changes?.liabilityLimit?.currentValue !=
      changes?.liabilityLimit?.previousValue;
    const deductibleChanged =
      changes?.deductible?.currentValue != changes?.deductible?.previousValue;

    if (liabilityLimitChanged && deductibleChanged) {
      this.informationForm.patchValue({
        personalLiabilityLimit: changes.liabilityLimit.currentValue,
        deductible: changes?.deductible.currentValue
      });

      return;
    }

    if (liabilityLimitChanged) {
      this.informationForm.patchValue({
        personalLiabilityLimit: changes.liabilityLimit.currentValue
      });
    }

    if (deductibleChanged) {
      this.informationForm.patchValue({
        deductible: changes?.deductible.currentValue
      });
    }
  }

  changeDropDown(ev: any, controlName: string) {
    if (controlName == 'personalLiabilityLimit') {
      this.liabilityLimit = ev.target?.value;
      this.liabilityLimitChange.emit(this.liabilityLimit);
      return;
    }

    this.informationForm.get(controlName).setValue(ev.target?.value, {
      onlySelf: true
    });
  }

  ngOnInit() {
    if (
      this.personalPropertyLimit &&
      this.deductible &&
      this.constructionTypeId &&
      this.policyStartDate
    ) {
      this.informationForm.setValue({
        constructionTypeId: this.constructionTypeId,
        deductible: this.deductible,
        personalPropertyLimit: this.personalPropertyLimit,
        policyStartDate: this.policyStartDate,
        personalLiabilityLimit: this.liabilityLimit
      });
      this.isFormValid.emit(this.informationForm.valid);
    } else {
      const personalPropertyLimit =
        this.address.stateCode === 'CA'
          ? this.personalPropertyLimits[1]
          : this.personalPropertyLimits[0];

      this.informationForm.patchValue({
        constructionTypeId: this.constructionTypeId,
        deductible: this.deductibles[0],
        personalPropertyLimit,
        personalLiabilityLimit: this.personalLiabilityLimits[0]
      });
      this.isFormValid.emit(this.informationForm.valid);
    }
  }

  setOwner() {
    this.owner.FirstName = this.informationForm.controls.FirstNameControl.value;
    this.owner.LastName = this.informationForm.controls.LastNameControl.value;
    this.owner.Email = this.informationForm.controls.EmailControl.value;
    this.owner.MoveOnDate =
      this.informationForm.controls.MoveInDateControl.value;
    this.owner.Address = {
      street: this.getAddressView.address1,
      city: this.getAddressView.city,
      stateCode: this.getAddressView.stateCode,
      zipcode: this.getAddressView.zipCode
    };
    this.owner.PrincipalPhone =
      this.informationForm.controls.PrincipalPhone.value;

    this.bondCoverageService.Owner$.emit(this.owner);
  }

  validationOwner() {
    if (this.informationForm.status === 'VALID' && this.getAddressView) {
      this.setOwner();
    }
  }

  getValidForm() {
    this.informationForm.markAllAsTouched();
    return this.informationForm.valid;
  }

  getErrorCtr(nameControl: string) {
    return (
      this.informationForm.get(nameControl).invalid &&
      this.informationForm.get(nameControl).touched
    );
  }
}
