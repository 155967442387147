import { Property } from './Property';
import { Owner } from './Owner';
import { Occupant } from './Occupant';
import { Payment } from './Payment';
import { BondCoverage } from './BondCoverage';
import { BillingAddress } from './BillingAddress';


export class BondCoverageGlobal{
    Property?: Property;
    BondCoverage?: BondCoverage;
    Owner?: Owner;
    Occupants?: Occupant[];
    Payment?: Payment;
    BillingAddress?: BillingAddress;
}