<div class="modal-content" style="padding: 15px;">
    <div class="input-group d-flex align-items-center">
        <img src="assets\images\home\info-circle-solid.svg" height="20px" alt="" tooltip="We had a trouble finding the address you entered. Please re-verify your address bellow to continue."
        placement="auto">
        <h4 class="modal-title">&nbsp;Address</h4>
    </div>
    <div class="container">
        Please re-verify your address to continue.
    </div>
    <form class="form-group">
        <div [formGroup]="addressForm" class=" DivMargin">
            <div class="modal-body  col-12">
                <label for="street" class="control-label"> Street</label>
                <input type="text" class="form-control" formControlName="streetControl" id="street"
                    placeholder="Street" (focusout)="Validation()"
                    [class.is-invalid]="StreetControl.invalid && StreetControl.touched" required />
                <div *ngIf="(StreetControl.invalid && StreetControl.touched) || StreetControl.dirty">
                    <small *ngIf="StreetControl.errors?.required" class="text-danger">Street is
                        required</small>
                </div>
                <label for="city" class="control-label"> City</label>
                <input type="text" class="form-control" formControlName="cityControl" id="city" placeholder="City"
                    (focusout)="Validation()" [class.is-invalid]="CityControl.invalid && CityControl.touched"
                    required />
                <div *ngIf="(CityControl.invalid && CityControl.touched) || CityControl.dirty">
                    <small *ngIf="CityControl.errors?.required" class="text-danger">City is required</small>
                </div>
                <label for="state" class="control-label"> State</label>
                <input type="text" class="form-control" formControlName="stateControl" id="state" placeholder="State"
                    (focusout)="Validation()" [class.is-invalid]="StateControl.invalid && StateControl.touched"
                    required />
                <div *ngIf="(StateControl.invalid && StateControl.touched) || StateControl.dirty">
                    <small *ngIf="StateControl.errors?.required" class="text-danger">State is required</small>
                </div>
                <label for="zip" class="control-label"> Zip</label>
                <input type="text" class="form-control" formControlName="zipControl" id="zip" placeholder="Zip" pattern="^\d{5}(?:[-\s]\d{5})?$" minlenght="5" maxlength="11"
                    (focusout)="Validation()" [class.is-invalid]="ZipControl.invalid && ZipControl.touched" required />
                <div *ngIf="(ZipControl.invalid && ZipControl.touched) || ZipControl.dirty">
                    <small *ngIf="ZipControl.errors?.required" class="text-danger">Zip code is required</small>
                    <small *ngIf="ZipControl.hasError('pattern')" class="text-danger">The zip code must be formatted using #####-#####</small>
                </div>
                <div class="modal-footer ">
                    <button class="btn btn-dark" (click)="Cancel()">Cancel</button>
                    <button class="btn btn-dark" (click)="Submit()">Continue</button>
                </div>
            </div>
        </div>
    </form>
</div>