export class GetQuoteRequest{
    Street: string
    City: string
    StateCode: string
    Zipcode: string
    CountryCode: string
    Unit: string
    FirstName: string
    LastName: string
    LineGuid: string
}