import { PurchasePaymentComponent } from './purchase-payment/purchase-payment.component';
import { PurchaseBillingComponent } from './purchase-billing/purchase-billing.component';
import { CompanyLineResponse } from './../../../model/CompanyLine/CompanyLineResponse';
import { CompanyLineRequest } from './../../../model/CompanyLine/CompanyLineRequest';
import { GetProducerInfoResponse } from './../../../model/ProducerInfo/GetProducerInfoResponse';
import {
  Component,
  OnInit,
  AfterViewInit,
  HostListener,
  ViewChild
} from '@angular/core';
import { AddressService } from 'src/app/shared/services/address/address.service';
import { Router } from '@angular/router';
import { ScreenSize } from 'src/model/ScreenSize';
import Swal from 'sweetalert2';
import { Owner } from 'src/model/Owner';
import { BondCoverageService } from 'src/app/shared/services/bondCoverage/bond-coverage.service';
import { IMSService } from 'src/app/shared/services/IMS/ims.service';
import { FindInsuredByName } from 'src/model/IMSService/FindInsuredByName';
import { Insured } from 'src/model/IMSService/Insured';
import { InsuredLocation } from 'src/model/IMSService/InsuredLocation';
import { Guid } from 'guid-typescript';
import { CreatePremium } from 'src/model/IMSService/CreatePremium';
import { QuoteOption } from 'src/model/IMSService/QuoteOption';
import { Quote } from 'src/model/IMSService/Quote';
import { Submission } from 'src/model/IMSService/Submission';
import { BondCoverage } from 'src/model/BondCoverage';
import { QuoteDetail } from 'src/model/IMSService/QuoteDetail';
import { PayPolicyRequest } from 'src/model/PaymentService/PayPolicyRequest';
import { PaymentService } from 'src/app/shared/services/payment/payment.service';
import { CreditCardInformationModel } from 'src/model/PaymentService/CreditCardInformationModel';
import { Payment } from 'src/model/Payment';
import { BillingAddress } from 'src/model/BillingAddress';
import { PayPolicyResponse } from 'src/model/PaymentService/PayPolicyResponse';
import { Policy } from 'src/model/IMSService/Policy';
import { BindQuote } from 'src/model/IMSService/BindQuote';
import { Occupant } from 'src/model/Occupant';
import { ResponseAdditionalOccupants } from 'src/model/IMSService/ResponseAdditionalOccupants';
import { Surety } from 'src/model/IMSService/Surety';
import { PurchaseGeneralComponent } from './purchase-general/purchase-general.component';
import { DocuSignService } from 'src/app/shared/services/DocuSign/docu-sign.service';
import { IpService } from 'src/app/shared/services/ip/ip.service';
import { SubjectivityService } from 'src/app/shared/services/subjectivity/subjectivity.service';
import { environment } from 'src/environments/environment';
import { DocumentFileToIMS } from 'src/model/IMSService/DocumentFileToIMS';
import { BrokerDocument } from 'src/model/IMSService/BrokerDocument';
import { ArrayOccupants } from 'src/model/IMSService/ArrayOccupants';
import { UnitsForAddress } from 'src/model/UnitsForAddress';
import { SuccessfullyService } from 'src/app/shared/services/successfully/successfully.service';
import { SuccessfullyDone } from 'src/model/successfullyService/SuccessfullyDone';
import * as signalR from '@microsoft/signalr';
import { ProtectionService } from 'src/app/shared/services/protection/protection.service';
import { ResponseCreateSubjectivityUrl } from 'src/model/IMSService/ResponseCreateSubjectivityUrl';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SignatureValidationComponent } from 'src/app/shared/modal/signature-validation/signature-validation.component';
import { UpdateSurety } from 'src/model/IMSService/UpdateSurety';
import { PaymentValidationComponent } from 'src/app/shared/modal/payment-validation/payment-validation.component';
import { GetProducerInfoRequest } from 'src/model/ProducerInfo/GetProducerInfoRequest';
import { PaymentInfoRequest } from 'src/model/PaymentService/PaymentInfoRequest';
import { PaymentInfoResponse } from 'src/model/PaymentService/PaymentInfoResponse';
import { CompanyInstallment } from 'src/model/IMSService/CompanyInstallment';
import { TokenRequest } from 'src/model/PaymentService/TokenRequest';
import { TokenResponse } from 'src/model/PaymentService/TokenResponse';
import { ChargeCodeResponse } from 'src/model/Deposit/ChargeCodeResponse';
import { ChargeCodeRequest } from 'src/model/Deposit/ChargeCodeRequest';
import { DocuSignDocumentStatusResquest } from 'src/model/Protection/DocuSignDocumentStatusRequest';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { LogService, Level } from 'src/app/shared/services/log/log.service';
import { GetQuoteRequest } from 'src/model/IMSService/GetQuoteRequest';
import { GetQuoteResponse } from 'src/model/IMSService/GetQuoteResponse';
import { PurchaseOccupantsComponent } from './purchase-occupants/purchase-occupants.component';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

// Media Observer
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable } from 'rxjs';

// FontAwesome
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

//ApiService

@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit, AfterViewInit {
  @ViewChild(PurchaseGeneralComponent) childGeneral: PurchaseGeneralComponent;
  @ViewChild(PurchaseOccupantsComponent)
  childOccupants: PurchaseOccupantsComponent;
  @ViewChild(PaymentOptionsComponent)
  childPaymentOptions: PaymentOptionsComponent;
  @ViewChild(PurchaseBillingComponent) childBilling: PurchaseBillingComponent;
  @ViewChild(PurchasePaymentComponent) childPayment: PurchasePaymentComponent;

  prefix = 'is-';

  sizes = [
    {
      id: ScreenSize.XS,
      name: 'xs',
      css: `d-block d-sm-none`
    },
    {
      id: ScreenSize.SM,
      name: 'sm',
      css: `d-none d-sm-block d-md-none`
    },
    {
      id: ScreenSize.MD,
      name: 'md',
      css: `d-none d-md-block d-lg-none`
    },
    {
      id: ScreenSize.LG,
      name: 'lg',
      css: `d-none d-lg-block d-xl-none`
    },
    {
      id: ScreenSize.XL,
      name: 'xl',
      css: `d-none d-xl-block`
    }
  ];

  // Media Observer
  public media$: Observable<MediaChange[]>;
  public isMobile = false;

  unitForAddress: any;
  getAddressView: any;

  GlobalCurrentSizes: string;

  Owner = new Owner();
  BondCoverage = new BondCoverage();
  Payment = new Payment();
  BillingAddress = new BillingAddress();
  Occupant = new Occupant();

  FindInsuredByName = new FindInsuredByName();
  Insured = new Insured();
  InsuredLocation = new InsuredLocation();
  Submission = new Submission();
  Quote = new Quote();
  QuoteOption = new QuoteOption();
  CreatePremium = new CreatePremium();
  TokenRequest = new TokenRequest();
  TokenResponse = new TokenResponse();
  PayPolicyRequest = new PayPolicyRequest();
  PaymentInfoRequest = new PaymentInfoRequest();
  PaymentInfoResponse = new PaymentInfoResponse();
  BindQuote = new BindQuote();
  Surety = new Surety();
  ArrayOccupants = new ArrayOccupants();
  UpdateSurety = new UpdateSurety();
  CreateTemplateFormData = new FormData();
  DocumentFileToIMSFormData = new FormData();
  BrokerDocumentFormData = new FormData();
  DocuSignDataFormData = new FormData();
  ResponsiveAdditionalOccupants = new ResponseAdditionalOccupants();
  ResponsiveAdditionalOccupantsTemp = new ResponseAdditionalOccupants();

  ExistInsured: string;
  InsuredGuid: Guid;
  InsuredLocationGuid: Guid;
  SubmissionGuid: Guid;
  QuoteGuid: Guid;
  QuoteOptionGuid: Guid;
  PremiumResponse: string;
  PayPolicyResponse: PayPolicyResponse;
  Policy: Policy;
  ResponseCreateSubjectivityUrl: ResponseCreateSubjectivityUrl;
  DocumentGuid: Guid;
  ResponseSurety: ResponseAdditionalOccupants;
  ResponseAdditionalOccupants: ResponseAdditionalOccupants;
  ResponseUpdateSurety: ResponseAdditionalOccupants;
  DocumentFileToIMS: DocumentFileToIMS;
  BrokerDocument: BrokerDocument;
  DocuSignData: Guid;
  Signed = false;
  SuccessfullyDone = new SuccessfullyDone();
  ProducerInfoRequest = new GetProducerInfoRequest();
  ProducerInfoResponse: GetProducerInfoResponse;
  CompanyLineRequest = new CompanyLineRequest();
  CompanyLineResponse = new CompanyLineResponse();
  CompanyInstallment: CompanyInstallment;
  ChargeCodeRequest = new ChargeCodeRequest();
  ChargeCodeResponse = new ChargeCodeResponse();
  GetQuoteRequest = new GetQuoteRequest();
  GetQuoteResponse = new GetQuoteResponse();

  ActualTryNumber = 1;
  MaximunTryNumber = 3;
  SuccessfulPayment = false;
  TryPayment = true;
  ExitNow = false;

  SwalPaymentCont = 0;

  liabilityAmountResponse: any;

  //New Api Objects
  insuredInput: any;
  insuredView: any;
  binderInput: any;
  binderView: any;
  paymentView: any;
  paymentPlan: any;
  occupants: any[] = [];
  contentsAmountResponse: any;
  paymentPlanAmountResponse: any;
  quoteView: any;

  ip: string;
  API_URL_Docusign_Direct: string = environment.DocuSignDirect;

  /** Sockets Variables */
  connection = new signalR.HubConnectionBuilder()
    .withUrl(this.API_URL_Docusign_Direct.slice(0, -4) + 'Emmiter')
    .build();

  // Modal
  bsModalRefSignature: BsModalRef; // Signature
  bsModalRefPayment: BsModalRef; // Payment
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    keyboard: false,
    class: 'modal-dialog-centered'
  };

  public ratedId = 0;
  selectedPaymentPlanId = '0';

  expand = faCaretDown;
  close = faCaretRight;
  togglerIcon = faCaretDown;
  collapseSummary = true;

  constructor(
    private router: Router,
    private addressService: AddressService,
    private bondCoverageBondService: BondCoverageService,
    private imsService: IMSService,
    private paymentService: PaymentService,
    private docusignService: DocuSignService,
    private ipService: IpService,
    private subjectivityService: SubjectivityService,
    private successfullyService: SuccessfullyService,
    private protectionService: ProtectionService,
    private modalService: BsModalService,
    private logService: LogService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    media: MediaObserver
  ) {
    this.media$ = media.asObservable();
  }

  @HostListener('window:resize', [])
  @HostListener('window:message', ['$event'])
  onMessage(e) {
    if (e?.data === 'CloseModal') {
      Swal.close();
    }
  }

  ngOnInit() {
    // Get Ip
    this.getIP();
    this.getAddressView = this.addressService.getAddress();

    if (!this.getAddressView) {
      // this.router.navigate(['/home']);
    }

    this.media$.subscribe((mq) => {
      this.isMobile = 'xs' === mq[0].mqAlias;
    });

    // Get UnitID
    this.unitForAddress = this.addressService.getUnitForAddressLocal()
      ? this.addressService.getUnitForAddressLocal()
      : new UnitsForAddress();

    this.bondCoverageBondService.Owner$.subscribe(
      (owner: Owner) => {
        this.Owner = owner;
      },
      (error) => {
        this.logService.Log(error, Level.ERROR);
      }
    );

    this.bondCoverageBondService.BondCoverage$.subscribe(
      (bondCoverage: BondCoverage) => {
        this.BondCoverage = bondCoverage;
      },
      (error) => {
        this.logService.Log(error, Level.ERROR);
      }
    );

    this.bondCoverageBondService.Payment$.subscribe(
      (payment: Payment) => {
        this.Payment = payment;
      },
      (error) => {
        this.logService.Log(error, Level.ERROR);
      }
    );

    this.bondCoverageBondService.BillingAddress$.subscribe(
      (billingAddress: BillingAddress) => {
        this.BillingAddress = billingAddress;
      },
      (error) => {
        this.logService.Log(error, Level.ERROR);
      }
    );

    this.bondCoverageBondService.Occupants$.subscribe(
      (occupant: Occupant) => {
        this.Occupant = occupant;
      },
      (error) => {
        this.logService.Log(error, Level.ERROR);
      }
    );
    this.protectionService.ContentsAmountEnd$.subscribe(
      (ContentsAmount: any) => {
        this.contentsAmountResponse = ContentsAmount;
      },
      (error) => {
        this.logService.Log(error, Level.ERROR);
      }
    );
    this.bondCoverageBondService.PaymentPlant$.subscribe(
      (paymentPlanResult) => {
        this.paymentPlan = paymentPlanResult;
      }
    );
    this.protectionService.PaymentPlanEnd$.subscribe(
      (paymentplan) => {
        this.paymentPlanAmountResponse = paymentplan;
      },
      (error) => {
        this.logService.Log(error, Level.ERROR);
      }
    );

    this.protectionService.LiabilityAmountEnd$.subscribe(
      (liabilityAmount: any) => {
        this.liabilityAmountResponse = liabilityAmount;
        // tslint:disable-next-line: no-unused-expression
      }
    ),
      (error) => {
        this.logService.Log(error, Level.ERROR);
      };
  }

  ngAfterViewInit(): void {}

  ValidationExit(NamePage: string) {
    if (this.getAddressView) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The changes will be lost!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#046a38',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, exit!',
        scrollbarPadding: false
      }).then((result) => {
        if (result.value) {
          this.addressService.deleteAddress();
          this.router.navigate(['/' + NamePage]);
        }
      });
    } else {
      this.router.navigate(['/' + NamePage]);
    }
  }

  ValidateForms(): boolean {
    let section: string = '';
    this.childGeneral.ValidationOwner();
    this.childPayment.Validation();
    this.childOccupants.ValidationOcupants();
    if (
      this.childGeneral.General.status === 'VALID' ||
      this.childGeneral.General.status === 'DISABLED'
    ) {
      if (
        this.childOccupants.t.status === 'VALID' ||
        this.childOccupants.t.status === 'DISABLED'
      ) {
        if (this.childPayment.PaymentGroup.status === 'VALID') {
          if (this.childBilling.Billing.status === 'VALID') {
            return true;
          } else {
            section = 'Billing';
          }
        } else {
          section = 'Payment';
        }
      } else {
        section = 'Occupants';
      }
    } else {
      section = 'Property Insurance Policy';
    }

    //markAllAsTouched
    if (this.childGeneral.General.status === 'INVALID') {
      this.childGeneral.General.markAllAsTouched();
    }

    if (this.childOccupants.t.status === 'INVALID') {
      this.childOccupants.t.markAllAsTouched();
    }

    if (this.childPayment.PaymentGroup.status != 'VALID') {
      this.childPayment.PaymentGroup.markAllAsTouched();
    }

    if (this.childBilling.Billing.status != 'VALID') {
      this.childBilling.Billing.markAllAsTouched();
    }

    Swal.fire({
      title: section,
      text:
        'Please provide highlighted missing or invalid data in section: ' +
        section,
      icon: 'error',
      showCancelButton: false,
      confirmButtonColor: '#046a38',
      cancelButtonColor: '#d33',
      confirmButtonText: 'OK',
      scrollbarPadding: false
    });
    return false;
  }

  FillInsuredControllerInput() {
    this.insuredInput = {
      addressId: Number(this.getAddressView.addressId),
      unitId: Number(this.unitForAddress.unitId),
      firstName: this.Owner.FirstName,
      lastName: this.Owner.LastName,
      emailAddress: this.Owner.Email
    };
  }

  FillBinderControllerInput() {
    this.occupants = [];
    this.FillBinderOccupants();

    var address: any = {
      address1: this.Owner.Address.street,
      address2: this.getAddressView.address2,
      city: this.Owner.Address.city,
      stateCode: this.Owner.Address.stateCode,
      zipCode: this.Owner.Address.zipcode
    };
    var paymentInfo: any = {
      accountNumberId: this.Payment?.CardNumber,
      creditCardExpirationDt:
        this.Payment?.ExpYear + '-' + this.Payment?.ExpMonth,
      givenName: this.Payment?.NameOnCard,
      amount: this.paymentPlanAmountResponse?.firstPaymentAmount,
      methodPaymentCd: this.Payment?.SecondCardType,
      paymentPlanCd: this.paymentPlanAmountResponse?.paymentPlanCd,
      paymentPlantId: this.paymentPlanAmountResponse?.paymentPlanId,
      rqUID: this.paymentPlan?.rqUID,
      companysQuoteNumber: this.paymentPlan?.policyNumber,
      billingAddress1: this.BillingAddress?.Address,
      billingCity: this.BillingAddress?.City,
      billingStateCode: this.BillingAddress?.State,
      billingZipCode: this.BillingAddress?.ZipCode,
      otherGivenName: ' ',
      surname: ' ',
      chargeFee: environment.ChargeFee
    };
    this.binderInput = {
      addressId: 0,
      insuredId: Guid.EMPTY.toString(),
      unitId: this.unitForAddress?.unitId,
      bondAmount: this.paymentPlan.totalInsurableReplCostAmt,
      insuredEmail: this.Owner?.Email,
      ratedPremium: this.paymentPlan.fullTermAmt,
      moveInDate: this.Owner?.MoveOnDate,
      occupants: this.occupants,
      processingFee: environment.PayerFee,
      companysQuoteNumber: this.paymentPlan?.policyNumber,
      rqUID: this.paymentPlan?.rqUID,
      paymentInfo: paymentInfo,
      isSWProcess: true,
      address: address
    };
  }

  FillBinderOccupants() {
    const lengthArray = this.Occupant.occupants_form?.length;

    this.occupants.push({
      firstName: this.Owner.FirstName,
      lastName: this.Owner.LastName,
      email: this.Owner.Email,
      isPrimaryOccupant: true,
      principalPhone: this.Owner?.PrincipalPhone
    });

    for (let index = 0; index < lengthArray; index++) {
      this.occupants.push({
        firstName: this.Occupant.occupants_form[index].firstName,
        lastName: this.Occupant.occupants_form[index].lastName,
        email: this.Occupant.occupants_form[index].email,
        isPrimaryOccupant: false
      });
    }
  }

  async GenerateToken() {
    if (!true) {
      // Generate token
      this.FillTokenRequest();
      this.TokenResponse = await this.paymentService.postGenerateToken(
        this.TokenRequest
      );
      this.ActualTryNumber++;
      if (this.TokenResponse.responseCode != 200) {
        Swal.fire({
          title: 'Ooops!',
          text: this.TokenResponse.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonColor: '#046a38',
          cancelButtonColor: '#d33',
          confirmButtonText: 'OK',
          scrollbarPadding: false
        });
        this.bsModalRefPayment.hide();
        return 0;
      }
    }
  }

  ValidateException(ex: string) {
    let msg = '';
    if (ex.search('Error Code: 400') >= 0) {
      msg = ex.substring(ex.search('Error:'), ex.length);
      return msg;
    }

    return msg;
  }

  async ExecutePayment() {
    if (this.TryPayment) {
      this.FillPayPolicyRequest();
      this.PayPolicyResponse = await this.paymentService.postPayPolicy(
        this.PayPolicyRequest
      );
      switch (this.PayPolicyResponse.statusCode) {
        case 0:
          await Swal.fire({
            title: 'Payment could not be completed',
            html:
              '<span>Payment provider error: ' +
              this.PayPolicyResponse?.data.message +
              '</span><br><span>Please contact Protection Choice, LLC client services.</span>' +
              '<br><span>Phone: (888) 219-9112,</span>' +
              '<br><span>Email: customerservice@protectionchoice.com</span>',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#046a38',
            cancelButtonColor: '#d33',
            confirmButtonText: 'OK',
            scrollbarPadding: false
          });
          this.ExitNow =
            this.PayPolicyResponse?.headers['CanBeRetry'] === 'False';
          break;
        case 1:
          // Set SuccessfulPayment
          this.SuccessfulPayment = true;
          break;
        case 3:
        case 5:
        case 9:
        case 11:
          if (
            this.ActualTryNumber < this.MaximunTryNumber &&
            this.PayPolicyResponse.headers['CanBeRetry']
          ) {
            await Swal.fire({
              title: 'Payment could not be completed',
              text: 'Do you want to try again?',
              icon: 'error',
              showCancelButton: true,
              confirmButtonColor: '#046a38',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Try again',
              scrollbarPadding: false
            }).then(async (result) => {
              if (result.value) {
                this.ActualTryNumber++;
              } else {
                // No desea continuar
                this.ExitNow = true;
              }
            });
          } else {
            // Terminan los reintentos
            this.ExitNow = true;
          }
          break;
      }
    }
  }

  async ShowSWDocument() {
    this.ExitNow = true;
    this.downloadPDFBase64(this.binderView.bondCertificate);
    window.open(this.binderView.documentURL, '_blank');
    this.Signed = true;
    this.SuccessfulPayment = true;
    this.bsModalRefPayment.hide();
    await this.SetRedirectThankYouPage(1);
  }
  async ExecuteSign() {
    if (this.InsuredGuid != Guid.createEmpty()) {
      // DocumentFileToIMS
      //this.FillgetDocumentFiletoIMS();
      //this.DocumentFileToIMS = await this.imsService.GetDocumentFiletoIMS(this.DocumentFileToIMSFormData);
      // BrokerDocument

      // CreateSubjectivityUrl
      this.ResponseCreateSubjectivityUrl = await this.imsService.postCreateSubjectivityUrl(
        this.quoteView.quoteGuid
      );

      this.FillonSign();
      this.BrokerDocument = await this.docusignService.postBrokerDocument(
        this.BrokerDocumentFormData
      );

      // DocuSignData
      this.FillDocuSingData();
      this.DocuSignData = await this.subjectivityService.postDocuSignData(
        this.DocuSignDataFormData
      );

      // Hide PaymentModal
      this.bsModalRefPayment.hide();

      Swal.fire({
        html: `<div class="iframe-container"><iframe allowfullscreen sandbox="allow-same-origin allow-top-navigation allow-scripts allow-popups allow-pointer-lock" src="${this.BrokerDocument.url.Url}"></iframe></div>`,
        showCancelButton: false,
        showConfirmButton: false,
        cancelButtonText: 'Close',
        allowOutsideClick: false,
        allowEscapeKey: false,
        customClass: {
          popup: 'swal2-broker',
          cancelButton: 'cancel-btn-doc'
        },
        scrollbarPadding: false
      }).then(async (result) => {
        this.OpenModalTimer(60);
        // verification sign Document
      });
    }
  }

  async Purchase() {
    try {
      if (this.ValidateForms()) {
        if (!this.BillingAddress.acceptedTerms) {
          Swal.fire({
            title: 'Payment could not be completed',
            text:
              'It is required to accept the terms to continue with your purchase.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#046a38',
            confirmButtonText: 'OK',
            scrollbarPadding: false
          });
        } else {
          this.openModalPayment();
          if (this.ActualTryNumber == 1) {
            if (!true) {
              this.FillInsuredControllerInput();
              this.InsuredGuid = Guid.parse(this.insuredView.insuredId);
              await this.GenerateToken();
            }

            this.FillBinderControllerInput();

            if (true) {
              await this.ShowSWDocument();
            } else {
              await this.ExecuteSign();
            }
          } else {
            if (this.TryPayment) {
              if (!true) {
                await this.GenerateToken();
              }

              this.FillBinderControllerInput();
              if (true) {
                await this.ShowSWDocument();
              } else {
                await this.ExecuteSign();
              }
            }
          }
          if (!this.SuccessfulPayment && this.ExitNow) {
            this.bsModalRefPayment.hide();
            this.SetRedirectThankYouPage(2);
          }
        }
      }
    } catch (e) {
      this.bsModalRefPayment.hide();
      this.logService.Log(e, Level.ERROR);
    }
  }

  FillPostGetQuote() {
    this.GetQuoteRequest.Street = this.Owner.Address?.street;
    this.GetQuoteRequest.City = this.Owner.Address?.city;
    this.GetQuoteRequest.StateCode = this.Owner.Address?.stateCode;
    this.GetQuoteRequest.Zipcode = this.Owner.Address?.zipcode;
    this.GetQuoteRequest.CountryCode = environment.ISOCountryCode;
    this.GetQuoteRequest.Unit = this.unitForAddress.unitNumber;
    this.GetQuoteRequest.FirstName = this.Owner?.FirstName;
    this.GetQuoteRequest.LastName = this.Owner?.LastName;
    this.GetQuoteRequest.LineGuid = environment.LineGUID;
  }

  FillFindInsuredByNameObject() {
    this.FindInsuredByName.insuredName =
      this.Owner?.FirstName + ' ' + this.Owner?.LastName;
    this.FindInsuredByName.city = this.Owner.Address?.city;
    this.FindInsuredByName.state = this.Owner.Address?.stateCode;
    this.FindInsuredByName.zip = this.Owner.Address?.zipcode;
  }

  FillInsuredObject() {
    this.Insured.BusinessTypeID = environment.BusinessTypeID;
    this.Insured.FirstName = this.Owner.FirstName;
    this.Insured.LastName = this.Owner.LastName;
    this.Insured.NameOnPolicy =
      this.Owner.FirstName + ' ' + this.Owner.LastName;
  }

  FillInsuredLocationObject() {
    this.InsuredLocation.InsuredGuid = this.InsuredGuid;
    this.InsuredLocation.LocationName = environment.LocationName;
    this.InsuredLocation.Address1 = this.Owner.Address.street;
    this.InsuredLocation.City = this.Owner.Address?.city;
    this.InsuredLocation.State = this.Owner.Address?.stateCode;
    this.InsuredLocation.Zip = this.Owner.Address?.zipcode;
    this.InsuredLocation.ISOCountryCode = environment.ISOCountryCode;
    this.InsuredLocation.LocationType = environment.LocationType;
    this.InsuredLocation.DeliveryMethodID = environment.DeliveryMethodID;
    this.InsuredLocation.LocationTypeID = environment.LocationTypeID;
    this.InsuredLocation.Email = this.Owner?.Email;
    this.InsuredLocation.Address2 = 'Unit ' + this.unitForAddress.unitNumber;
  }

  FillProducerInfoObject() {
    this.ProducerInfoRequest.unitId = this.unitForAddress.unitId;
  }

  FillSubmissionObject() {
    this.Submission.InsuredGuid = this.InsuredGuid.toString();
    this.Submission.ProducerContactGuid = this.ProducerInfoResponse.producerContactGUID;
    this.Submission.ProducerLocationGuid = this.ProducerInfoResponse.producerLocationGUID;
    this.Submission.UnderwriterGuid = environment.UnderwriterGUID;
    this.Submission.SubmissionDate = new Date();
  }

  FillCompanyLineObject() {
    this.CompanyLineRequest.lineGuid = environment.LineGUID;
    this.CompanyLineRequest.stateId = this.getAddressView.stateCode;
  }

  FillQuoteObject() {
    this.Quote.SubmissionGuid = this.SubmissionGuid.toString();
    this.Quote.QuotingLocationGuid = environment.QuotingLocationGUID;
    this.Quote.IssuingLocationGuid = environment.IssuingLocationGUID;
    this.Quote.CompanyLocationGuid = environment.CompanyLocationGUID;
    this.Quote.LineGuid = environment.LineGUID;
    this.Quote.StateID = this.getAddressView.stateCode;
    this.Quote.ProducerContactGuid = this.ProducerInfoResponse.producerContactGUID;
    this.Quote.QuoteStatusID = environment.QuoteStatusID;
    this.Quote.Effective = this.Owner.MoveOnDate;
    this.Quote.Expiration = new Date(
      this.Owner.MoveOnDate.getFullYear() + 1,
      this.Owner.MoveOnDate.getMonth(),
      this.Owner.MoveOnDate.getDate()
    );
    this.Quote.BillingTypeID = environment.BillingTypeID;

    this.Quote.QuoteDetail = new QuoteDetail();

    this.Quote.QuoteDetail.CompanyContactGuid = this.CompanyLineResponse.companyContactGuid;
    this.Quote.QuoteDetail.CompanyCommission = this.CompanyLineResponse.companyCommNew;
    this.Quote.QuoteDetail.ProducerCommission = this.CompanyLineResponse.ProducerCommNew;
    this.Quote.QuoteDetail.TermsOfPayment = this.CompanyLineResponse.termsOfPayment;
    this.Quote.QuoteDetail.RaterID = environment.RaterID;

    this.Quote.UnderwriterGuid = environment.UnderwriterGUID;
    this.Quote.PolicyTypeID = environment.PolicyTypeID;
    this.Quote.InsuredPolicyName = this.Insured.NameOnPolicy;
    this.Quote.InsuredFirstName = this.Insured.FirstName;
    this.Quote.InsuredLastName = this.Insured.LastName;
    this.Quote.InsuredAddress1 = this.InsuredLocation.Address1;
    this.Quote.InsuredAddress2 = this.InsuredLocation.Address2;
    this.Quote.InsuredCity = this.InsuredLocation.City;
    this.Quote.InsuredState = this.InsuredLocation.State;
    this.Quote.InsuredISOCountryCode = this.InsuredLocation.ISOCountryCode;
    this.Quote.InsuredZipCode = this.InsuredLocation.Zip;
    this.Quote.InsuredZipPlus = this.InsuredLocation.ZipPlus;
    this.Quote.costCenterID = environment.CostCenterID;
  }

  FillQuoteOptionObject() {
    this.QuoteOption.QuoteGuid = this.QuoteGuid.toString();
    this.QuoteOption.LineGuid = environment.LineGUID;
  }

  FillChargeCodeRequest() {
    this.ChargeCodeRequest.StateId = this.getAddressView.stateCode;
  }

  FillCreatePremiumObject() {
    this.CreatePremium.QuoteOptionGuid = this.QuoteOptionGuid.toString();
    this.CreatePremium.Premium = this.contentsAmountResponse.premium;
    this.CreatePremium.OfficeID = environment.OfficeID;
    this.CreatePremium.ChargeCode = this.ChargeCodeResponse.chargeCode;
  }

  FillTokenRequest() {
    this.TokenRequest.Payer = this.Owner.FirstName + ' ' + this.Owner.LastName;
    this.TokenRequest.EmailAddress = this.Owner.Email;
    this.TokenRequest.LineGuid = environment.LineGUID;
    this.TokenRequest.ReOccurringPayment = true;
    this.TokenRequest.InsuredGuid = this.InsuredGuid.toString();
    /*if (this.GetQuoteResponse.newQuote) {

    } else {
      this.TokenRequest.InsuredGuid = this.GetQuoteResponse.quote.insuredGUID.toString();
    }*/

    this.TokenRequest.CreateUserId = 0; // Pending Change.

    this.TokenRequest.CreditCardInformation = new CreditCardInformationModel();
    this.TokenRequest.CreditCardInformation.AccountHolder = this.Payment.NameOnCard;
    this.TokenRequest.CreditCardInformation.CardNumber = this.Payment.CardNumber;
    this.TokenRequest.CreditCardInformation.Cvc = this.Payment.CVV.toString();
    this.TokenRequest.CreditCardInformation.Month = Number(
      this.Payment.ExpMonth
    );
    this.TokenRequest.CreditCardInformation.Year = Number(this.Payment.ExpYear);
    this.TokenRequest.CreditCardInformation.PostalCode = this.BillingAddress.ZipCode;
  }

  FillPayPolicyRequest() {
    this.PayPolicyRequest.TokenId = this.TokenResponse.tokenId;
    this.PayPolicyRequest.Payer =
      this.Owner.FirstName + ' ' + this.Owner.LastName;
    this.PayPolicyRequest.Amount =
      this.paymentPlanAmountResponse.paymentAmount + environment.PayerFee;
    if (this.contentsAmountResponse.stateSurcharge) {
      this.PayPolicyRequest.Amount =
        this.PayPolicyRequest.Amount +
        this.contentsAmountResponse.stateSurchargeAmount;
    }
    if (this.contentsAmountResponse.citySurcharge) {
      this.PayPolicyRequest.Amount =
        this.PayPolicyRequest.Amount +
        this.contentsAmountResponse.citySurchargeAmount;
    }
    this.PayPolicyRequest.PayerFee = environment.PayerFee;
    this.PayPolicyRequest.EmailAddress = this.Owner.Email;
    this.PayPolicyRequest.LineGuid = environment.LineGUID;
  }

  FillBindQuote() {
    this.BindQuote.QuoteGuid = this.QuoteGuid;
  }

  FillPaymentToPost() {
    this.PaymentInfoRequest = new PaymentInfoRequest();
    this.PaymentInfoRequest.quoteguid = this.QuoteGuid.toString();
    this.PaymentInfoRequest.paymentAmount = this.paymentPlanAmountResponse.paymentAmount;
    if (this.contentsAmountResponse.stateSurcharge) {
      this.PaymentInfoRequest.paymentAmount =
        this.PaymentInfoRequest.paymentAmount +
        this.contentsAmountResponse.stateSurchargeAmount;
    }
    if (this.contentsAmountResponse.citySurcharge) {
      this.PaymentInfoRequest.paymentAmount =
        this.PaymentInfoRequest.paymentAmount +
        this.contentsAmountResponse.citySurchargeAmount;
    }
    this.PaymentInfoRequest.checkNumber = Object(
      this.PayPolicyResponse.data
    ).id;
    this.PaymentInfoRequest.comments = 'epay Protection Choice';
  }

  FillSurety() {
    this.Surety.QuoteGUID = this.QuoteGuid.toString();
    this.Surety.UnitId = this.unitForAddress.unitId;
    this.Surety.BondSigned = false;
    this.Surety.LineGUID = environment.LineGUID;
    this.Surety.PaymentPlanId = this.paymentPlanAmountResponse.paymentPlanId;
    this.Surety.ContentsAmount = this.contentsAmountResponse.contentsAmount;
    this.Surety.LiabilityAmount = +this.liabilityAmountResponse.liabilityAmount
      .replace('$', '')
      .replace(',', '');
    this.Surety.DeductibleAmount = +this.liabilityAmountResponse.deductible
      .replace('$', '')
      .replace(',', '');
    this.Surety.PaymentPlanAmount = this.paymentPlanAmountResponse.paymentAmount;
    this.Surety.Premium = this.contentsAmountResponse.premium;
  }

  FillAdditionalOccupants() {
    this.ArrayOccupants.SuretyID = this.ResponseSurety.suretyId;
    this.ArrayOccupants.QuoteGUID = this.QuoteGuid.toString();
    //this.ArrayOccupants.Occupants = Array<Occupants>();

    // First occupant
    this.ArrayOccupants.Occupants.push({
      FirstName: this.Owner.FirstName,
      LastName: this.Owner.LastName,
      Email: this.Owner.Email,
      PrimaryOccupant: true,
      PrincipalPhone: this.Owner.PrincipalPhone
    });

    // Additional occupants
    if (this.Occupant.occupants_form) {
      const lengthArray = this.Occupant.occupants_form.length;

      for (let index = 0; index < lengthArray; index++) {
        this.ArrayOccupants.Occupants.push({
          FirstName: this.Occupant.occupants_form[index].firstName,
          LastName: this.Occupant.occupants_form[index].lastName,
          Email: this.Occupant.occupants_form[index].email,
          PrimaryOccupant: false,
          PrincipalPhone: null
        });
      }
    }
  }

  FillputUpdateSurety() {
    this.UpdateSurety.QuoteGUID = this.QuoteGuid.toString();
    this.UpdateSurety.DocumentGUID = this.DocumentGuid.toString();
    this.UpdateSurety.PolicyNumber = this.Policy.policyNumber;
  }

  /** Document Signature  */
  getIP() {
    this.ipService.getIpAddress().subscribe(
      (res: any) => {
        this.ip = res.ip;
      },
      (error) => {
        this.logService.Log(error, Level.ERROR);
      }
    );
  }

  FillcreateTemplate() {
    const FileName = environment.FileName + '.pdf';
    const TemplateID = environment.TemplateID;
    const DocumentTypeID = environment.DocumentTypeGUID;
    const FolderName = environment.FolderName;
    const Description =
      environment.Description +
      ' ' +
      this.Owner.FirstName +
      ' ' +
      this.Owner.LastName;

    this.CreateTemplateFormData.append('FileName', FileName);
    this.CreateTemplateFormData.append('DocumentTypeID', DocumentTypeID); // Is GUID Type
    this.CreateTemplateFormData.append('QuoteGUID', this.QuoteGuid.toString());
    this.CreateTemplateFormData.append('TemplateID', TemplateID.toString());
    this.CreateTemplateFormData.append('FolderName', FolderName);
    this.CreateTemplateFormData.append('Description', Description);
  }

  FillgetDocumentFiletoIMS() {
    this.DocumentFileToIMSFormData.append(
      'DocumentGuid',
      this.DocumentGuid?.toString()
    );
  }

  FillonSign() {
    const returnUrl = environment.ReturnURL;
    const fileName = environment.FileName;
    const templateId = environment.TemplateID;
    const conditionId = environment.ConditionID;
    const company = environment.Company;

    this.BrokerDocumentFormData.append(
      'inputDocument',
      this.binderView.bondCertificate
    );
    this.BrokerDocumentFormData.append('fileName', fileName);
    this.BrokerDocumentFormData.append('returnUrl', returnUrl);
    this.BrokerDocumentFormData.append(
      'signerClientId',
      this.binderView.policyNumber
    );
    this.BrokerDocumentFormData.append('conditionID', conditionId.toString());
    this.BrokerDocumentFormData.append('quoteGuid', this.quoteView.quoteGuid);
    this.BrokerDocumentFormData.append('templateId', templateId.toString());
    this.BrokerDocumentFormData.append('company', company);
    this.BrokerDocumentFormData.append(
      'insuredName',
      this.Owner.FirstName + ' ' + this.Owner.LastName
    );
    this.BrokerDocumentFormData.append('emailInsured', this.Owner.Email);
  }

  FillDocuSingData() {
    const returnUrl = environment.ReturnURL;
    const fileName = environment.FileName;
    const templateId = environment.TemplateID;
    const conditionId = environment.ConditionID;

    this.DocuSignDataFormData.append(
      'docuSignDocumentGuid',
      this.BrokerDocument.evelopeId.toString()
    );
    this.DocuSignDataFormData.append('envelopeId', '1');
    this.DocuSignDataFormData.append('conditionId', conditionId.toString());
    this.DocuSignDataFormData.append('quoteGuid', this.quoteView.quoteGuid);
    this.DocuSignDataFormData.append('status', 'sent');
  }

  async SetRedirectThankYouPage(option: number) {
    if (option === 1 && !true) {
      // BondSigned
      this.ResponseUpdateSurety = await this.imsService.putUpdateBondSigned(
        this.quoteView.quoteGuid
      );
    }

    this.SuccessfullyDone.Option = option;
    this.successfullyService.setSuccessfullyDone(this.SuccessfullyDone);
    this.router.navigate(['/ThankYou']);
  }

  // ** Sockets implementation */
  startConnection() {
    const inter = interval(5000);

    const takeFourInter = inter.pipe(take(12));

    const result = takeFourInter.subscribe((x) => {
      const resquest: DocuSignDocumentStatusResquest = {
        quoteGUID: this.quoteView.quoteGuid
      };
      this.protectionService.GetDocuSignDocumentStatus(resquest).subscribe(
        (res) => {
          if (res.isDone) {
            this.Signed = true;
            this.bsModalRefSignature.hide();
            result.unsubscribe();
            this.SetRedirectThankYouPage(1);
          }
        },
        (error) => {
          this.logService.Log(error, Level.ERROR);
        }
      );
    });
  }

  receiveValues() {
    this.connection.on('emitValue', (res: any, res2: any) => {
      if (res === 'completed') {
        this.Signed = true;
        this.bsModalRefSignature.hide();
        this.SetRedirectThankYouPage(1);
      } else if (res === 'errorConnect') {
      } else {
      }
    });
  }

  joinGroup(group: string): void {
    if (this.connection) {
      this.connection
        .invoke('AddGroup', group)
        .then(() => {
          this.receiveValues();
        })
        .catch((error) => {});
    }
  }
  /** END SOCKETS */

  onRatedIdChange(value: number) {
    this.ratedId = value;
  }

  openModalSignature() {
    this.bsModalRefSignature = this.modalService.show(
      SignatureValidationComponent,
      this.config
    );
    this.bsModalRefSignature.content.closeBtnName = 'Close';
  }

  openModalPayment() {
    this.bsModalRefPayment = this.modalService.show(
      PaymentValidationComponent,
      this.config
    );
    this.bsModalRefPayment.content.closeBtnName = 'Close';
  }

  OpenModalTimer(seconds: number) {
    let counter = seconds;

    this.openModalSignature();

    const interval = setInterval(() => {
      counter--;

      if (counter < 0 && !this.Signed) {
        clearInterval(interval);
        this.bsModalRefSignature.hide();
        this.FnSigned();
      }
    }, 1000);
  }

  FnSigned() {
    if (this.Signed) {
      this.SetRedirectThankYouPage(1);
    } else {
      this.imsService.setQuoteGUID(Guid.parse(this.quoteView.quoteGuid));
      this.imsService.setDocumentFileToIMSFormData(this.DocuSignDataFormData);
      this.docusignService.setBrokerDocumentFormData(
        this.BrokerDocumentFormData
      );
      this.subjectivityService.setDocuSignDataFormData(
        this.DocuSignDataFormData
      );

      this.SetRedirectThankYouPage(3);
    }
  }

  toggleSummary(): void {
    this.collapseSummary = !this.collapseSummary;

    if (this.collapseSummary) {
      this.togglerIcon = this.expand;
    } else {
      this.togglerIcon = this.close;
    }
  }

  downloadPDFBase64(dataFileBase64: string) {
    const name = 'Policy Still Water.pdf';
    const blob = this.b64toBlob(dataFileBase64, 'application/pdf');
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }

    const data = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    document.body.appendChild(link); // required in FF, optional for Chrome
    link.href = data;
    link.download = name;
    link.click();
    window.URL.revokeObjectURL(data);
    link.remove();
  }

  b64toBlob(b64Data: string, contentType: string) {
    contentType = contentType || '';
    const sliceSize = 512;
    b64Data = b64Data.replace(/^[^,]+,/, '');
    b64Data = b64Data.replace(/\s/g, '');
    const byteCharacters = window.atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
