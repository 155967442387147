import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class SubjectivityService {

  API_URL_SUBJECTIVITY: any = environment.EnvironmentUrlApiUrl;
  TOKEN_SUBJECTIVITY: any = environment.SubjectivityToken;

  headersSubjectivity = new HttpHeaders({
    'Authorization': 'Bearer ' + this.TOKEN_SUBJECTIVITY
  });

  DocuSignDataFormData = new FormData();

  constructor(private httpClient: HttpClient) { }

  setDocuSignDataFormData(docuSignDataFormData: FormData) {
    this.DocuSignDataFormData = docuSignDataFormData;
  }

  getDocuSignDataFormData(): FormData {
    return this.DocuSignDataFormData;
  }

  async postDocuSignData(formData: any) {
    return await this.httpClient.post<Guid>(this.API_URL_SUBJECTIVITY + 'DocuSign', formData, { headers: this.headersSubjectivity }).toPromise();
  }
}
