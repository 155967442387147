<div class="container" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-around center" fxLayoutGap="15px">
    <div fxLayout.xs="column" fxLayoutGap="15px">
        <div class="Title">
            Protection Choice Renter's Insurance, underwritten by a highly respected
            national insurance company, protects what you own with policies designed 
            exclusively for apartment living.
        </div>
        
        <div class="Text">
            Your landlord's insurance protects the physical building in which you live, but NOT your personal property. 
            You are responsible for any damage done to your apartment or the property, and you may also be 
            responsible if someone is injured while in your space. Teaming up with Protection Choice establishes a 
            partnership between you and the property management to reduce both risk and financial losses all the way 
            around.
        </div>
    </div>
    
    <div fxLayout="column" fxLayoutAlign="center center">
        <img src="assets/images/home/content.png" alt="content">
    </div>
</div>

<div class="section-separator"></div>

<div class="container" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around start" fxLayoutGap="15px">
    <div fxLayout="column" fxLayoutAlign="space-around center">
        <div>
            <img src="assets/images/home/experienced.svg" class="feature-icons">
        </div>
        <div class="Title-column">
            Experienced
        </div>
        <div class="Text-column">
            Our team of professionals have decades
            of experience working with rental communities, management companies, property owners, and their
            residents.
        </div>
    </div>
    
    <div fxLayout="column" fxLayoutAlign="space-around center">
        <div>
            <img src="assets/images/home/committed.svg" class="feature-icons">
        </div>
        
        <div class="Title-column">
            Committed
        </div>
        <div class="Text-column">
            Protection Choice, LLC is a national provider of Renter's Insurance for the multifamily industry. Our
            singular focus is on
            serving the specific needs of renters
            and property managers.
        </div>
    </div>
    
    <div fxLayout="column" fxLayoutAlign="space-around center">
        <div>
            <img src="assets/images/home/stable.svg" class="feature-icons">
        </div>
        <div class="Title-column">
            Stable
        </div>
        <div class="Text-column">
            Our insurance partner serves customers throughout 
            the United States, and was selected based on a 
            history of delivering innovative solutions.
        </div>  
    </div>
</div>