import { Guid } from 'guid-typescript';
import { QuoteDetail } from './QuoteDetail';

export class Quote{
    SubmissionGuid: string
    QuotingLocationGuid: string
    IssuingLocationGuid: string
    CompanyLocationGuid: string
    LineGuid: string
    StateID: string
    ProducerContactGuid: string
    QuoteStatusID: number
    Effective: Date
    Expiration: Date
    BillingTypeID: number
    FinanceCompanyGuid: string
    NetRateQuoteID: number
    QuoteDetail: QuoteDetail
    ExpiringQuoteGuid: string
    UnderwriterGuid: string
    ExpiringPolicyNumber: string
    ExpiringCompanyLocationGuid: string
    PolicyTypeID: number
    RenewalOfQuoteGuid: string
    InsuredBusinessTypeID: number
    InsuredPolicyName: string
    InsuredCorporationName: string
    InsuredFirstName: string
    InsuredLastName: string
    InsuredAddress1: string
    InsuredAddress2: string
    InsuredCity: string
    InsuredState: string
    InsuredISOCountryCode: string
    InsuredRegion: string
    InsuredZipCode: string
    InsuredZipPlus: string
    InsuredPhone: string
    InsuredFax: string
    AdditionalInformation: string[]
    onlineRaterID: number
    costCenterID: number
}
