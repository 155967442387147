import { Inject, Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { LogService, Level } from './../services/log/log.service';
import Swal from 'sweetalert2';
import { utilities } from 'src/app/utilities/utilities';
import { environment } from 'src/environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private logService: LogService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = `Error: ${error.error.message}`;
        } else {
          // server-side error
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message} \nError: ${error.error}`;
        }

        this.logService.Log(errorMessage, Level.ERROR);
        if (error.status !== 400) {
          const phone = utilities.numberToPhone(environment.contactPhone);
          const email = environment.contactEmail;
          // Message Error
          Swal.fire({
            title: 'An unexpected error occurred!',
            html:
              'We had trouble processing your request, please contact us at <br> ' +
              phone +
              ' <br>or <br>' +
              email +
              '.',
            icon: 'error',
            showCancelButton: false,
            confirmButtonColor: '#046a38',
            confirmButtonText: 'OK',
            scrollbarPadding: false
          }).then((result) => {
            // Close Alert
          });
          // END
          return throwError(errorMessage);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
