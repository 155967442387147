<div fxLayout="column" fxLayoutAlign="start center" class="Background">
  <div class="Shadow"></div>
  <div
    fxLayout="column"
    fxLayoutAlign="space-around center"
    class="Shadow-description"
  >
    <div class="Protection">
      Protection Choice
      <br />Renters Insurance
    </div>

    <div class="Alternative">AFFORDABLE PEACE OF MIND</div>

    <div class="Offer">
      <div>
        On average, individual renters have more than $30,000 worth of personal
        belongings, yet a surprising number fail to protect their own valuables.
      </div>
      <br />
      <strong
        >Protection Choice offers quality renters' insurance options at
        affordable prices.
      </strong>
    </div>
  </div>
</div>
