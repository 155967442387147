import { Injectable, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
declare var window: any;

@Injectable({
  providedIn: "root"
})
export class AppInitService {
  // envirPath: string;
  constructor(public http: HttpClient) {}

  public getEnvRoute() {
    return this.http
      .get("assets/environments/env-route.json")
      .pipe(
        map((envRoute: any) => {

          return this.http
            .get(envRoute.path + "/config.json")
            .pipe(
              map((config: any) => {
                window.config = config;
                return config;
              })
            )
            .toPromise();
        })
      )
      .toPromise();
  }
}