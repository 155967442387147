import { Location } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent {

  @ViewChild('stepper') stepper: MatStepper;
  indexStepperNow = -1;

  constructor(private locationService: Location) { }

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (this.locationService.isCurrentPathEqualTo('/information')) {
        this.stepper.selectedIndex = 0;
        this.indexStepperNow = 0;
      }
      if (this.locationService.isCurrentPathEqualTo('/underwriting')) {
        this.stepper.selectedIndex = 1;
        this.indexStepperNow = 1;
      }
      if (this.locationService.isCurrentPathEqualTo('/Payment')) {
        this.stepper.selectedIndex = 2;
        this.indexStepperNow = 2;
      }
    })
  }

}
