import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { omit } from 'ramda';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { stringMask } from 'src/app/constants/masks';
import { emailPattern, namePattern } from 'src/app/constants/patters';
import { YourNameForm } from 'src/app/interfaces/information';
import { masks } from 'src/app/utilities/masks';
import { InsuredPersonalInfo } from 'src/services/generated/api-services';

const insuredForm = () =>
  new FormGroup({
    firstName: new FormControl('', [
      Validators.required,
      Validators.pattern(namePattern)
    ]),
    lastName: new FormControl('', [
      Validators.required,
      Validators.pattern(namePattern)
    ]),
    emailAddress: new FormControl('', [
      Validators.required,
      Validators.pattern(emailPattern)
    ]),
    dateOfBirthMonth: new FormControl('', Validators.required),
    dateOfBirthDay: new FormControl('', Validators.required),
    dateOfBirthYear: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', [
      Validators.required,
      Validators.minLength(10)
    ]),
    phoneType: new FormControl('', Validators.required)
  });

@UntilDestroy()
@Component({
  selector: 'app-your-name',
  templateUrl: './your-name.component.html',
  styleUrls: ['./your-name.component.scss']
})
export class YourNameComponent implements OnChanges {
  @Input() insuredInfo?: InsuredPersonalInfo;
  @Input() secondInsuredInfo?: InsuredPersonalInfo;
  @Input() isSecondInsuredInfo?: boolean;
  @Input() previousAddress?: any;

  @Output() form = new EventEmitter<YourNameForm>();
  @Output() isFormValid = new EventEmitter<boolean>();

  maxDate = new Date();
  phoneTypes = [
    { id: '-1', desc: 'Home' },
    { id: '1', desc: 'Cellphone' }
  ];

  aditionalInsured?: boolean;
  informationForm = new FormGroup({
    insuredInfo: insuredForm(),
    secondInsuredInfo: insuredForm(),
    previousAddress: new FormGroup({
      previousAddress: new FormControl(false),
      address1: new FormControl(''),
      address2: new FormControl(''),
      city: new FormControl(''),
      stateCode: new FormControl(''),
      zipCode: new FormControl('')
    })
  });
  stringMask = stringMask;
  months?: number[];
  years?: number[];

  maskZipCode = masks.maskOnlyNumber;

  get getPreviosCheck(): boolean {
    return this.informationForm?.get('previousAddress').get('previousAddress')
      .value;
  }

  ngOnInit() {
    for (let i = 1; i <= 12; i++) this.months.push(i);

    for (
      let i = moment().subtract(18, 'years').year();
      i > moment().subtract(101, 'years').year();
      i--
    )
      this.years.push(i);
    this.setValidatorsSecondaryInsured();
    this.informationForm.statusChanges.subscribe((status) => {
      const statusReturn = status === 'VALID';
      this.isFormValid.emit(statusReturn);
    });
    this.informationForm
      .get('previousAddress')
      .get('previousAddress')
      .valueChanges.subscribe((value) =>
        this.setValidatorsPreviusAddress(value)
      );
  }

  constructor() {
    this.months = [];
    this.years = [];
    this.informationForm.valueChanges
      .pipe(untilDestroyed(this), debounceTime(300), distinctUntilChanged())
      .subscribe({
        next: this.emitIfHaveAllValues
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.insuredInfo || changes?.secondInsuredInfo) {
      this.setDataInsured();
    }

    if (changes?.previousAddress) {
      const previousAddress =
        this.previousAddress?.address1 &&
        this.previousAddress?.city &&
        this.previousAddress?.stateCode &&
        this.previousAddress?.zipCode;
      this.informationForm.get('previousAddress').patchValue({
        previousAddress: previousAddress
      });
      setTimeout(() => {
        this.informationForm
          .get('previousAddress')
          .patchValue(this.previousAddress);
      }, 200);
    }
  }

  changeDropDown(ev: any, formName: string, controlName: string) {
    this.informationForm
      .get(formName)
      .get(controlName)
      ?.setValue(ev.target?.value, {
        onlySelf: true
      });

    if (controlName == 'dateOfBirthYear' || controlName == 'dateOfBirthMonth') {
      this.setLastDayInMonth(formName);
    }
  }

  emitIfHaveAllValues = (value) => {
    const { insuredInfo, secondInsuredInfo, previousAddress } = value;
    const omitDateNumbers = omit([
      'dateOfBirthYear',
      'dateOfBirthMonth',
      'dateOfBirthDay'
    ]);
    const insuredToDate = ({
      dateOfBirthYear,
      dateOfBirthMonth,
      dateOfBirthDay
    }) =>
      moment(
        `${dateOfBirthYear}/${dateOfBirthMonth}/${dateOfBirthDay}`,
        'YYYY/MM/DD'
      ).toDate();
    const toInsuredModel = (insured) => ({
      ...omitDateNumbers(insured),
      dateOfBirth: insuredToDate(insured),
      phoneNumber: insured.phoneNumber?.toString(),
      phoneType: insured.phoneType
        ? parseInt(insured.phoneType === '-1' ? 0 : insured.phoneType)
        : undefined
    });
    const req = {
      insuredInfo: toInsuredModel(insuredInfo),
      secondInsuredInfo: toInsuredModel(secondInsuredInfo),
      aditionalInsured: this.aditionalInsured,
      previusAddressCheck: previousAddress.previousAddress,
      previousAddress: {
        address1: previousAddress.address1,
        address2: previousAddress.address2,
        city: previousAddress.city,
        stateCode: previousAddress.stateCode,
        zipCode: previousAddress.zipCode
      }
    };
    this.form.emit(req);
  };

  setDataInsured() {
    const isPrincipal = this.insuredInfo?.lastName ? true : false;
    const isSecondary = this.secondInsuredInfo?.lastName ? true : false;
    this.aditionalInsured = isSecondary;

    const insuredInfoValues = {
      firstName: this.insuredInfo?.firstName,
      lastName: this.insuredInfo?.lastName,
      emailAddress: this.insuredInfo?.emailAddress,
      dateOfBirthMonth: this.insuredInfo?.dateOfBirth?.getMonth() + 1,
      dateOfBirthDay: this.insuredInfo?.dateOfBirth?.getDate(),
      dateOfBirthYear: this.insuredInfo?.dateOfBirth?.getFullYear(),
      phoneNumber: this.insuredInfo?.phoneNumber,
      phoneType:
        this.insuredInfo?.phoneType === 0
          ? '-1'
          : this.insuredInfo?.phoneType?.toString()
    };
    const secondInsuredInfoValues = {
      firstName: this.secondInsuredInfo?.firstName,
      lastName: this.secondInsuredInfo?.lastName,
      emailAddress: this.secondInsuredInfo?.emailAddress,
      dateOfBirthMonth: this.secondInsuredInfo?.dateOfBirth?.getMonth() + 1,
      dateOfBirthDay: this.secondInsuredInfo?.dateOfBirth?.getDate(),
      dateOfBirthYear: this.secondInsuredInfo?.dateOfBirth?.getFullYear(),
      phoneNumber: this.secondInsuredInfo?.phoneNumber,
      phoneType:
        this.secondInsuredInfo?.phoneType === 0
          ? '-1'
          : this.secondInsuredInfo?.phoneType?.toString()
    };

    if (isPrincipal && isSecondary) {
      setTimeout(() => {
        this.informationForm.patchValue({
          insuredInfo: insuredInfoValues,
          secondInsuredInfo: secondInsuredInfoValues
        });
      }, 100);
    } else if (isPrincipal) {
      this.informationForm.patchValue({
        insuredInfo: insuredInfoValues
      });
    }

    setTimeout(() => {
      this.isFormValid.emit(this.informationForm.valid);
    }, 600);
  }

  getErrorDateOfBirfh(formName: string) {
    const isInValidMonth =
      this.informationForm.get(formName).get('dateOfBirthMonth').invalid &&
      this.informationForm.get(formName).get('dateOfBirthMonth').touched;
    const isInValidDay =
      this.informationForm.get(formName).get('dateOfBirthDay').invalid &&
      this.informationForm.get(formName).get('dateOfBirthDay').touched;
    const isInValidYear =
      this.informationForm.get(formName).get('dateOfBirthYear').invalid &&
      this.informationForm.get(formName).get('dateOfBirthYear').touched;
    return isInValidDay || isInValidMonth || isInValidYear;
  }

  getFormValid() {
    this.informationForm.markAllAsTouched();
    return this.informationForm.valid;
  }

  setValidatorsSecondaryInsured() {
    if (this.aditionalInsured) {
      this.informationForm
        .get('secondInsuredInfo')
        .get('firstName')
        .setValidators([Validators.required, Validators.pattern(namePattern)]);
      this.informationForm.get('secondInsuredInfo').get('firstName').reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('lastName')
        .setValidators([Validators.required, Validators.pattern(namePattern)]);
      this.informationForm.get('secondInsuredInfo').get('lastName').reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('emailAddress')
        .setValidators([Validators.required, Validators.pattern(emailPattern)]);
      this.informationForm.get('secondInsuredInfo').get('emailAddress').reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthMonth')
        .setValidators(Validators.required);
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthMonth')
        .reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthDay')
        .setValidators(Validators.required);
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthDay')
        .reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthYear')
        .setValidators(Validators.required);
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthYear')
        .reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('phoneNumber')
        .setValidators([Validators.required, Validators.minLength(10)]);
      this.informationForm.get('secondInsuredInfo').get('phoneNumber').reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('phoneType')
        .setValidators(Validators.required);
      this.informationForm.get('secondInsuredInfo').get('phoneType').reset();
    } else {
      // clean validators
      this.informationForm
        .get('secondInsuredInfo')
        .get('firstName')
        .setValidators(null);
      this.informationForm.get('secondInsuredInfo').get('firstName').reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('lastName')
        .setValidators(null);
      this.informationForm.get('secondInsuredInfo').get('lastName').reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('emailAddress')
        .setValidators(null);
      this.informationForm.get('secondInsuredInfo').get('emailAddress').reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthMonth')
        .setValidators(null);
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthMonth')
        .reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthDay')
        .setValidators(null);
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthDay')
        .reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthYear')
        .setValidators(null);
      this.informationForm
        .get('secondInsuredInfo')
        .get('dateOfBirthYear')
        .reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('phoneNumber')
        .setValidators(null);
      this.informationForm.get('secondInsuredInfo').get('phoneNumber').reset();
      this.informationForm
        .get('secondInsuredInfo')
        .get('phoneType')
        .setValidators(null);
      this.informationForm.get('secondInsuredInfo').get('phoneType').reset();
    }
    this.informationForm.get('secondInsuredInfo').patchValue({
      dateOfBirthMonth: '',
      dateOfBirthDay: '',
      dateOfBirthYear: ''
    });
    this.emitIfHaveAllValues(this.informationForm.value);
  }

  formatLetNumber(value: number) {
    if (value < 10) {
      return '0' + value.toString();
    }
    return value;
  }

  getDays(formName: string) {
    const days = [];
    const year =
      this.informationForm.get(`${formName}.dateOfBirthYear`).value ||
      moment().year();
    const month =
      this.informationForm.get(`${formName}.dateOfBirthMonth`).value ||
      moment().month() + 1;
    const date = `${year.toString()}-${month.toString()}`;
    for (let i = 1; i <= moment(date, 'YYYY-MM').daysInMonth(); i++) {
      days.push(i);
    }

    return days;
  }

  setLastDayInMonth(formName: string) {
    const month =
      this.informationForm.get(`${formName}.dateOfBirthMonth`).value ||
      moment().month() + 1;
    const year =
      this.informationForm.get(`${formName}.dateOfBirthYear`).value ||
      moment().year();
    const lastDay = new Date(year, month, 0).getDate();

    if (
      this.informationForm.get(`${formName}.dateOfBirthDay`).value > lastDay
    ) {
      this.informationForm
        .get(`${formName}`)
        .patchValue({ dateOfBirthDay: lastDay.toString() });
    }
  }

  setValidatorsPreviusAddress(previusAddress: boolean) {
    const formControPrevAddr = ['address1', 'city', 'stateCode', 'zipCode'];
    if (previusAddress) {
      formControPrevAddr.forEach((x) => {
        this.informationForm
          .get('previousAddress')
          .get(x)
          .setValidators([Validators.required]);
        this.informationForm.get('previousAddress').get(x).reset();
      });
    } else {
      formControPrevAddr.forEach((x) => {
        this.informationForm.get('previousAddress').get(x).setValidators(null);
        this.informationForm.get('previousAddress').get(x).reset();
      });
    }
    this.informationForm.get('previousAddress').get('address2').reset();
  }
}
