<h1 mat-dialog-title>{{ data.title }}(Yes)</h1>
<div mat-dialog-content>
  <div
    gdLayoutsm="column"
    gdGap="12px"
    gdColumns.gt-sm="calc(50% - 6px) calc(50% - 6px)"
  >
    <div *ngFor="let question of data.questions">
      <p>{{ question.questionDesc }}</p>
      <mat-radio-group
        [(ngModel)]="question.response"
        aria-label="Select an option"
        *ngIf="question.isYesNoQuestion"
      >
        <mat-radio-button class="answer-question" [value]="true">
          Yes
        </mat-radio-button>
        <mat-radio-button class="answer-question" [value]="false">
          No
        </mat-radio-button>
      </mat-radio-group>

      <mat-form-field *ngIf="!question.isYesNoQuestion" appearance="outline">
        <mat-label>Type here</mat-label>
        <input
          matInput
          placeholder="Type here"
          [(ngModel)]="question.response"
        />
      </mat-form-field>
    </div>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="cancel()">Cancel</button>
  <button mat-button [mat-dialog-close]="data.questions" cdkFocusInitial>
    Ok
  </button>
</div>
