<div class="card">
  <div class="card-header">Additional Interest</div>

  <form class="card-body" [formGroup]="additionalInterestForm">
    <div
      fxFlex
      fxFill
      fxLayout="column"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="25px"
    >
    <div
    fxLayout="row"
    fxLayout.lt-md="column"
    fxLayoutAlign="start start"
    fxLayoutAlign.lt-md="space-around stretch"
    fxLayoutGap="15px"
    fxFill>
    <div fxFlex="50%" fxFlex.lt-md="100%">
      <label class="col-form-label input-label">
        Name
      </label>
      <input
      style="padding-right: 15px;"
        type="text"
        class="form-control"
        id="additionalInterestName"
        formControlName="additionalInterestName"
        [ngClass]="{ 'is-invalid': getError('additionalInterestName', true) }"
      />
      <div class="invalid-feedback">Name is required.</div>
      <small
        *ngIf="
        additionalInterestForm
            .get('additionalInterestName')
            .hasError('pattern')
        "
        class="text-danger"
      >
        This field only admit spaces, letters and numbers
      </small>
    </div>
    <div fxFlex="50%" fxFlex.lt-md="100%">
    </div>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center start"
      fxLayoutAlign.lt-md="space-around stretch"
      fxLayoutGap="15px"
      fxFill
    >
      <div fxFlex="50%" fxFlex.lt-md="100%">
        <label class="col-form-label input-label">
          Address
        </label>
        <input
          type="text"
          class="form-control"
          id="address1"
          formControlName="address1"
          [ngClass]="{ 'is-invalid': getError('address1', true) }"
        />
        <div class="invalid-feedback">Address is required.</div>
      </div>

      <div fxFlex="50%" fxFlex.lt-md="100%">
        <label class="col-form-label input-label">
          City
        </label>
        <input
          [imask]="maskCity"
          [unmask]="true"
          type="text"
          class="form-control"
          id="city"
          formControlName="city"
          [ngClass]="{ 'is-invalid': getError('city', true) }"
        />
        <div class="invalid-feedback">City is required.</div>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayout.lt-md="column"
      fxLayoutAlign="center start"
      fxLayoutAlign.lt-md="space-around stretch"
      fxLayoutGap="15px"
      fxFill
    >
      <div fxFlex="50%" fxFlex.lt-md="100%">
        <label class="col-form-label input-label">
          State
        </label>
        <input
          [imask]="maskState"
          [unmask]="true"
          [class.is-invalid]=""
          type="text"
          class="form-control"
          id="stateCode"
          formControlName="stateCode"
          [ngClass]="{ 'is-invalid': getError('stateCode', true) }"
        />
        <div class="invalid-feedback">State is required.</div>
      </div>

      <div fxFlex="50%" fxFlex.lt-md="100%">
        <label class="col-form-label input-label">
          Zip Code
        </label>
        <input
          [imask]="maskZipCode"
          [unmask]="true"
          maxlength="5"
          type="text"
          class="form-control"
          id="zipCode"
          formControlName="zipCode"
          [ngClass]="{ 'is-invalid': getError('zipCode', true) }"
        />
        <div class="invalid-feedback">Zip Code is required.</div>
      </div>
    </div>
    </div>
  </form>
</div>