<div class="layout">
  <div class="header">
    <app-header></app-header>
  </div>
  <form
    class="page-container"
    [formGroup]="paymentGroup"
    (ngSubmit)="paymentProcess()"
  >
    <div #containerPage class="container">
      <app-stepper></app-stepper>
      <div class="row">
        <div class="col-4">
          <label class="col-form-label input-label label-end-icon">
            <mat-icon style="margin-right:1px">email</mat-icon>
            Landlord Notification Email
            <mat-icon style="margin-left:1px"
              customTooltip="The following email will be notified that you have purchased your renter’s insurance policy.">
              information
            </mat-icon>
          </label>
          <input type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm" formControlName="leaseEmail" [class.is-invalid]="paymentGroup.get('leaseEmail').invalid && paymentGroup.get('leaseEmail').touched"
          >
          <span *ngIf="paymentGroup.get('leaseEmail').touched">
            <small *ngIf="paymentGroup.get('leaseEmail').hasError('pattern')" class="text-danger">
              Please provide a valid email address
            </small>
          </span>
        </div>
      </div>
      <div class="row title-label">
        <div class="col-12">
          How would you like to pay for your Renters Insurance Policy?
        </div>
      </div>
      <div class="row">
        <div class="col-md-9 custom-table">
          <table
            class="dcf-table dcf-table-responsive dcf-table-striped dcf-w-100%"
          >
            <caption style="display: none">
              Plan Payment
            </caption>
            <thead>
              <tr class="header-text">
                <th data-label="Select" scope="col">Select</th>
                <th data-label="Plan" scope="col">Plan</th>
                <th data-label="Today’s Payment" scope="col">
                  Today’s Payment
                </th>
                <th data-label="Installments" scope="col">Installments</th>
                <th data-label="Total Premium" scope="col">Total Premium</th>
                <th data-label="Total Cost" scope="col">Total Cost</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let plan of listPlan">
                <tr
                  [ngClass]="{
                    'active-plan': plan === planSelect
                  }"
                >
                  <td class="border-first" data-label="Select">
                    <div class="custom-control custom-radio">
                      <input
                        type="radio"
                        [id]="plan.paymentPlanCd"
                        [value]="plan"
                        formControlName="plan"
                      />
                      <label
                        class="custom-control-label answer-question"
                        [for]="plan.paymentPlanCd"
                      ></label>
                    </div>
                  </td>
                  <td class="border-center" data-label="Plan">
                    <strong>{{ plan.paymentPlanDesc }}</strong>
                  </td>
                  <td class="border-center" data-label="Today’s Payment">
                    {{ plan.firstPaymentAmount | currency }}
                  </td>
                  <td class="border-center" data-label="Installments">
                    {{ plan.numInstallments }}
                  </td>
                  <td class="border-center" data-label="Total Premium">
                    {{ plan.totalPremium | currency }}
                  </td>
                  <td class="border-end" data-label="Total Cost">
                    <div class="container-total-cost">
                      <div>
                        {{ plan.totalCost | currency }}
                      </div>
                      <mat-icon [customTooltip]="getTooltipData(plan)"
                        >information</mat-icon
                      >
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>

          <!-- Down Payment Details -->
          <div class="row" *ngIf="planSelect">
            <div class="col-12 title-label">Down Payment Details</div>
            <ng-container
              *ngIf="
                planSelect?.paymentType === 'DB';
                then fullPayment;
                else onePayment
              "
            ></ng-container>
          </div>

          <!-- Term and condi -->
          <div class="row mt-2" *ngIf="planSelect?.numInstallments > 0">
            <div class="col-12 term-and-con check-box">
              <label class="checkbox-container">
                I {{ rateInput?.insuredInfo?.firstName }}
                {{ rateInput?.insuredInfo?.lastName }} acknowledge that I am
                authorizing future electronic debits to the account indicated.
                There will be {{ planSelect?.numInstallments }} recurring debits
                of
                {{ planSelect?.installmentAmount | currency }}
                throughout the policy term. For inquiries, I can reach
                Stillwater Insurance Svcs Inc during normal business hours at
                the number shown at bottom right of this page.
                <input
                  type="checkbox"
                  id="TermAndCondi"
                  formControlName="termAndCondi"
                  [ngClass]="{ 'is-invalid': getError('termAndCondi') }"
                />
                <span class="checkmark"></span>
              </label>
            </div>
          </div>

          <!-- other address -->
          <div class="row diferent-billing-address-label" *ngIf="planSelect">
            <div class="title-label mb-0">Billing Information</div>
          </div>
          <!-- Billing fields -->
          <div
            fxLayout="column"
            class="billing-fields"
            fxLayoutGap="15px"
          >
            <div
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="center start"
              fxLayoutAlign.lt-md="space-around stretch"
              fxLayoutGap="15px"
              fxFill
            >
              <div fxFlex="50%" fxFlex.lt-md="100%">
                <label class="col-form-label input-label">Address*</label>
                <input
                  type="text"
                  class="form-control"
                  id="billingAddress"
                  formControlName="billingAddress"
                  [ngClass]="{ 'is-invalid': getError('billingAddress') }"
                />
                <div class="invalid-feedback">Address is required.</div>
              </div>

              <div fxFlex="50%" fxFlex.lt-md="100%">
                <label class="col-form-label input-label">City*</label>
                <input
                  [imask]="maskCity"
                  [unmask]="true"
                  type="text"
                  class="form-control"
                  id="BillingCity"
                  formControlName="billingCity"
                  [ngClass]="{ 'is-invalid': getError('billingCity') }"
                />
                <div class="invalid-feedback">City is required.</div>
              </div>
            </div>

            <div
              fxLayout="row"
              fxLayout.lt-md="column"
              fxLayoutAlign="center start"
              fxLayoutAlign.lt-md="space-around stretch"
              fxLayoutGap="15px"
              fxFill
            >
              <div fxFlex="50%" fxFlex.lt-md="100%">
                <label class="col-form-label input-label">State*</label>
                <input
                  [imask]="maskState"
                  [unmask]="true"
                  [class.is-invalid]=""
                  type="text"
                  class="form-control"
                  id="billingState"
                  formControlName="billingState"
                  [ngClass]="{ 'is-invalid': getError('billingState') }"
                />
                <div class="invalid-feedback">State is required.</div>
              </div>

              <div fxFlex="50%" fxFlex.lt-md="100%">
                <label class="col-form-label input-label">Zip Code*</label>
                <input
                  [imask]="maskZipCode"
                  [unmask]="true"
                  maxlength="5"
                  type="text"
                  class="form-control"
                  id="billingZip"
                  formControlName="billingZip"
                  [ngClass]="{ 'is-invalid': getError('billingZip') }"
                />
                <div class="invalid-feedback">Zip Code is required.</div>
              </div>
            </div>
          </div>
        </div>
        <div #detailComponent class="col-md-3 insurance-information-container">
          <div class="title-your-renter">
            Your Renter’s Insurance Information
          </div>
          <div class="details-container">
            <div class="title-details">
              Details for your selected payment option
            </div>
            <div class="title-property">Address</div>
            <div class="description-property">
              {{ addressFull }}
            </div>
            <div class="change-address" (click)="changeAddress()">
              Change Address
            </div>
            <div class="title-property">Contents Coverage Amount*:</div>
            <div class="description-property">
              {{ rateInput?.rate?.personalPropertyLimit | currency }}
            </div>
            <div class="title-property">Number of Installments:</div>
            <div class="description-property">
              {{ planSelect?.numInstallments }}
            </div>
            <div class="title-property">Payment Plan</div>
            <div class="description-property">
              {{ planSelect?.paymentPlanDesc }}
            </div>
            <div class="title-property">Today’s Payment</div>
            <div class="description-property">
              {{ planSelect?.firstPaymentAmount | currency }}
            </div>
            <div class="title-property">Scheduled Payment:</div>
            <div class="description-property">
              {{ planSelect?.installmentAmount | currency }}
            </div>
            <div class="total-premium">Total Premium:</div>
            <div class="total-premium-value">
              {{ planSelect?.totalPremium || 0 | currency }}
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-9">
          <div class="row-total-amount">
            <div>(*) Indicates input is required.</div>
            <div class="total-amount">
              Total Amount Collected Today:
              <strong>{{ planSelect?.firstPaymentAmount | currency }}</strong>
            </div>
          </div>

          <!-- btns -->
          <div class="d-flex justify-content-end container-btn">
            <button class="btn btn-primary btn-goback" (click)="goBack()">
              < Go Back
            </button>
            <button class="btn btn-primary btn-continue" type="submit">
              Continue <fa-icon [icon]="iconArrowRight"></fa-icon>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<ng-template #futurePaymentDetails>
  <div class="row" *ngIf="planSelect?.paymentType === 'DB'; else onlyOneMethod">
    <div class="col-12 title-label">Future Payment Details</div>
    <div class="col-12">
      <nav>
        <div
          class="nav nav-tabs downPayment"
          id="futureDownPayment"
          role="tablist"
          [formGroup]="paymentGroup"
        >
          <a
            class="nav-link"
            [ngClass]="{ active: getValue('FutureChkTypePayment') === 0 }"
            id="nav-creditCard"
            data-toggle="tab"
            data-bs-toggle="tab"
            data-bs-target="#futureCreditCard"
            role="tab"
            aria-controls="nav-creditCard"
            aria-selected="true"
            (click)="futureChangeTypePayment(0)"
            >Credit Card</a
          >
          <a
            class="nav-link"
            [ngClass]="{ active: getValue('FutureChkTypePayment') === 1 }"
            id="nav-eCheck"
            data-toggle="tab"
            data-bs-toggle="tab"
            data-bs-target="#futureECheck"
            role="tab"
            aria-controls="nav-eCheck"
            aria-selected="false"
            (click)="futureChangeTypePayment(1)"
            >E-Check</a
          >
        </div>
      </nav>
      <div
        class="tab-content"
        id="futurePayment-tabContent"
        class="downPayment-tabContent tab-content"
      >
        <!-- credit card -->
        <div
          class="tab-pane fade"
          [ngClass]="{
            'show active': getValue('FutureChkTypePayment') === 0
          }"
          id="futureCreditCard"
          role="tabpanel"
          aria-labelledby="nav-creditCard"
        >
          <ng-container *ngIf="planSelect; then creditCardForm"></ng-container>
        </div>
        <!-- echeck -->
        <div
          class="tab-pane fade"
          [ngClass]="{
            'show active': getValue('FutureChkTypePayment') === 1
          }"
          id="futureECheck"
          role="tabpanel"
          aria-labelledby="nav-futureECheck"
        >
          <ng-container *ngIf="planSelect; then eCheckForm"></ng-container>
        </div>
      </div>
    </div>
  </div>

  <!-- Term and condi -->
  <div class="row mt-2" [formGroup]="paymentGroup">
    <div class="col-12 term-and-con check-box">
      <label class="checkbox-container">
        I {{ rateInput?.insuredInfo?.firstName }}
        {{ rateInput?.insuredInfo?.lastName }} acknowledge that I am authorizing
        future electronic debits to the account indicated. There will be
        {{ planSelect?.numInstallments }} recurring debits of
        {{ planSelect?.installmentAmount | currency }}
        throughout the policy term. For inquiries, I can reach Stillwater
        Insurance Svcs Inc during normal business hours at the number shown at
        bottom right of this page.
        <input
          type="checkbox"
          id="TermAndCondi"
          formControlName="termAndCondi"
          [ngClass]="{ 'is-invalid': getError('termAndCondi') }"
        />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</ng-template>

<ng-template #onlyOneMethod>
  <div class="row">
    <div class="col-md-12 title-label">Future Payment Details</div>
  </div>
  <div
    class="future-payment-container"
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [formGroup]="paymentGroup"
  >
    <ng-container
      *ngIf="
        planSelect?.paymentType === 'CC';
        then creditCardForm;
        else eCheckForm
      "
    ></ng-container>
  </div>
</ng-template>

<ng-template #creditCardForm>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [formGroup]="paymentGroup"
  >
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px" fxFill>
      <div class="title-label mb-0">Credit/Debit Card Information</div>
      <span fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10px">
        <img
          src="assets/images/payment/visa-icon.svg"
          class="payment-icons"
          alt="visa"
        />
        <img
          src="assets/images/payment/mastercard-icon.svg"
          class="payment-icons"
          alt="master"
        />
        <img
          src="assets/images/payment/american-express-icon.svg"
          class="payment-icons"
          alt="american"
        />
        <img
          src="assets/images/payment/discover-icon.svg"
          class="payment-icons"
          alt="discove"
        />
      </span>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="15px"
      fxFill
    >
      <div class="nameOnCard">
        <input
          type="text"
          id="futureNameOnCard"
          formControlName="futureNameOnCard"
          placeholder="Card Holder Name*"
          class="form-control"
          [ngClass]="{ 'is-invalid': getError('futureNameOnCard') }"
          [imask]="maskFutureNameOnCard"
          [unmask]="true"
        />
        <div class="invalid-feedback">Name on Card is required.</div>
      </div>
      <div class="cardNumber">
        <div class="input-group">
          <input
            type="text"
            id="futureCardNumber"
            formControlName="futureCardNumber"
            placeholder="Card Number*"
            class="form-control"
            maxlength="16"
            minlength="15"
            [imask]="maskFutureCreditCard"
            [unmask]="true"
            [ngClass]="{ 'is-invalid': getError('futureCardNumber') }"
          />
          <div *ngIf="futureRouteCard">
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2"
                ><img [src]="futureRouteCard" height="24px" alt=""
              /></span>
            </div>
          </div>
          <div class="invalid-feedback">Card Number is required.</div>
        </div>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="15px"
      fxFill
    >
      <div class="dateCreditCard">
        <div class="input-group">
          <select
            class="form-control"
            id="futureMonthCreditCard"
            formControlName="futureMonthCreditCard"
            [ngClass]="{
              'is-invalid': getError('futureMonthCreditCard')
            }"
          >
            <option selected disabled value="">Exp Month*</option>
            <option value="01">01 - January</option>
            <option value="02">02 - February</option>
            <option value="03">03 - March</option>
            <option value="04">04 - April</option>
            <option value="05">05 - May</option>
            <option value="06">06 - June</option>
            <option value="07">07 - July</option>
            <option value="08">08 - August</option>
            <option value="09">09 - September</option>
            <option value="10">10 - October</option>
            <option value="11">11 - November</option>
            <option value="12">12 - December</option>
          </select>
          <div class="input-group-append">
            <span class="input-group-text"
              ><img
                class="filterSVG-Green"
                src="assets\images\purchase\calendar-alt-solid.svg"
                height="20px"
                alt=""
            /></span>
          </div>
          <div class="invalid-feedback">Exp Month is required.</div>
        </div>
      </div>
      <div class="yearCreditCard">
        <div class="input-group">
          <select
            class="form-control"
            id="futureYearCreditCard"
            formControlName="futureYearCreditCard"
            [ngClass]="{
              'is-invalid': getError('futureYearCreditCard')
            }"
          >
            <option disabled value="">Exp Year*</option>
            <option *ngFor="let yearV of yearsData" value="{{ yearV }}">
              {{ yearV }}
            </option>
          </select>
          <div class="input-group-append">
            <span class="input-group-text"
              ><img
                class="filterSVG-Green"
                src="assets\images\purchase\calendar-alt-solid.svg"
                height="20px"
                alt=""
            /></span>
          </div>
          <div class="invalid-feedback">Exp Year is required.</div>
        </div>
      </div>
      <div class="CVVCreditCard">
        <div class="input-group">
          <input
            type="password"
            formControlName="futureCVVCreditCard"
            id="futureCVVCreditCard"
            class="form-control"
            placeholder="CVV*"
            [maxlength]="futureMaxLength"
            [ngClass]="{
              'is-invalid': getError('futureCVVCreditCard')
            }"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"
              ><img
                class="filterSVG-Green"
                src="assets\images\purchase\question-circle-solid.svg"
                height="20px"
                alt=""
            /></span>
          </div>
          <div class="invalid-feedback">CVV is required.</div>
        </div>
      </div>
    </div>
  </div>
  <div class="processing-fee-label">
    A minimum 3.25% non-refundable processing fee will be included for all
    credit/debit card transactions.
  </div>
</ng-template>

<ng-template #eCheckForm>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [formGroup]="paymentGroup"
  >
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px" fxFill>
      <div class="custom-control custom-radio">
        <input
          type="radio"
          id="futureCheckingAccount"
          formControlName="futureECheckType"
          value="PersonalChecking"
          [ngClass]="{ 'is-invalid': getError('futureECheckType') }"
        />
        <label
          class="custom-control-label answer-question label-radio"
          for="futureCheckingAccount"
          ><strong>Checking Account</strong></label
        >
      </div>
      <div class="custom-control custom-radio">
        <input
          type="radio"
          id="futureSavingsAccount"
          formControlName="futureECheckType"
          value="PersonalSavings"
          [ngClass]="{ 'is-invalid': getError('futureECheckType') }"
        />
        <label
          class="custom-control-label answer-question label-radio"
          for="futureSavingsAccount"
          ><strong>Savings Account</strong></label
        >
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="15px"
      fxFill
    >
      <div class="routing-number">
        <input
          type="text"
          id="futureRoutingNumber"
          formControlName="futureRoutingNumber"
          placeholder="Routing Number*"
          class="form-control"
          [imask]="maskFutureRouting"
          [unmask]="true"
          [ngClass]="{
            'is-invalid': getError('futureRoutingNumber')
          }"
        />
        <div class="invalid-feedback">Routing Number is required.</div>
      </div>
      <div class="account-number">
        <input
          type="text"
          id="futureAccountNumber"
          formControlName="futureAccountNumber"
          placeholder="Account Number*"
          class="form-control"
          [imask]="maskFutureAccount"
          [unmask]="true"
          [ngClass]="{
            'is-invalid': getError('futureAccountNumber')
          }"
        />
        <div class="invalid-feedback">Account Number is required.</div>
      </div>
    </div>
  </div>
</ng-template>

<!-- Normal Payment -->
<ng-template #fullPayment>
  <div class="col-12" [formGroup]="paymentGroup">
    <nav>
      <div class="nav nav-tabs downPayment" id="downPayment" role="tablist">
        <a
          class="nav-link"
          [ngClass]="{ active: getValue('chkTypePayment') === 0 }"
          id="nav-creditCard"
          data-toggle="tab"
          data-bs-toggle="tab"
          data-bs-target="#creditCard"
          role="tab"
          aria-controls="nav-creditCard"
          (click)="changeTypePayment(0)"
          >Credit Card</a
        >
        <a
          class="nav-link"
          [ngClass]="{ active: getValue('chkTypePayment') === 1 }"
          id="nav-eCheck"
          data-toggle="tab"
          data-bs-toggle="tab"
          data-bs-target="#eCheck"
          role="tab"
          aria-controls="nav-eCheck"
          (click)="changeTypePayment(1)"
          >E-Check</a
        >
      </div>
    </nav>
    <div
      class="tab-content"
      id="downPayment-tabContent"
      class="downPayment-tabContent tab-content"
    >
      <!-- credit card -->
      <div
        class="tab-pane fade"
        [ngClass]="{
          'show active': getValue('chkTypePayment') === 0
        }"
        id="creditCard"
        role="tabpanel"
        aria-labelledby="nav-creditCard"
      >
        <ng-container
          *ngIf="planSelect; then NormalCreditcardForm"
        ></ng-container>
      </div>
      <!-- echeck -->
      <div
        class="tab-pane fade"
        id="eCheck"
        role="tabpanel"
        aria-labelledby="nav-eCheck"
        [ngClass]="{
          'show active': getValue('chkTypePayment') === 1
        }"
      >
        <ng-container *ngIf="planSelect; then NormalECheckForm"></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #onePayment>
  <div class="col-md-12">
    <div class="future-payment-container">
      <ng-container
        *ngIf="
          planSelect.paymentType === 'CC';
          then NormalCreditcardForm;
          else NormalECheckForm
        "
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template #NormalCreditcardForm>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [formGroup]="paymentGroup"
  >
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px" fxFill>
      <div class="title-label mb-0">Credit/Debit Card Information</div>
      <span fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="10px">
        <img
          src="assets/images/payment/visa-icon.svg"
          class="payment-icons"
          alt="visa"
        />
        <img
          src="assets/images/payment/mastercard-icon.svg"
          class="payment-icons"
          alt="master"
        />
        <img
          src="assets/images/payment/american-express-icon.svg"
          class="payment-icons"
          alt="american"
        />
        <img
          src="assets/images/payment/discover-icon.svg"
          class="payment-icons"
          alt="discove"
        />
      </span>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="15px"
      fxFill
    >
      <div class="nameOnCard">
        <label class="col-form-label input-label">Name on Card*</label>
        <input
          type="text"
          id="nameOnCard"
          formControlName="nameOnCard"
          class="form-control"
          [ngClass]="{ 'is-invalid': getError('nameOnCard') }"
          [imask]="maskNameOnCard"
          [unmask]="true"
          autocomplete="cc-name"
        />
        <div class="invalid-feedback">Name on Card is required.</div>
      </div>
      <div class="cardNumber">
        <label class="col-form-label input-label">Card Number*</label>
        <div class="input-group">
          <input
            type="text"
            id="cardNumber"
            formControlName="cardNumber"
            class="form-control"
            maxlength="16"
            minlength="15"
            [imask]="maskCreditCard"
            [unmask]="true"
            [ngClass]="{ 'is-invalid': getError('cardNumber') }"
            autocomplete="cc-number"
          />
          <div *ngIf="routeCard">
            <div class="input-group-append">
              <span class="input-group-text" id="basic-addon2"
                ><img [src]="routeCard" height="24px" alt=""
              /></span>
            </div>
          </div>
          <div class="invalid-feedback">Card Number is required.</div>
        </div>
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="15px"
      fxFill
    >
      <div class="dateCreditCard">
        <label class="col-form-label input-label">Exp Month*</label>
        <div class="input-group">
          <select
            class="form-control"
            id="dateCreditCard"
            formControlName="monthCreditCard"
            [ngClass]="{
              'is-invalid': getError('monthCreditCard')
            }"
            autocomplete="cc-exp-month"
          >
            <option selected disabled value="">MM</option>
            <option value="01">01 - January</option>
            <option value="02">02 - February</option>
            <option value="03">03 - March</option>
            <option value="04">04 - April</option>
            <option value="05">05 - May</option>
            <option value="06">06 - June</option>
            <option value="07">07 - July</option>
            <option value="08">08 - August</option>
            <option value="09">09 - September</option>
            <option value="10">10 - October</option>
            <option value="11">11 - November</option>
            <option value="12">12 - December</option>
          </select>
          <div class="input-group-append">
            <span class="input-group-text"
              ><img
                class="filterSVG-Green"
                src="assets\images\purchase\calendar-alt-solid.svg"
                height="20px"
                alt=""
            /></span>
          </div>
          <div class="invalid-feedback">Exp Month is required.</div>
        </div>
      </div>
      <div class="yearCreditCard">
        <label class="col-form-label input-label">Exp Year*</label>
        <div class="input-group">
          <select
            class="form-control"
            id="yearCreditCard"
            formControlName="yearCreditCard"
            [ngClass]="{
              'is-invalid': getError('yearCreditCard')
            }"
            autocomplete="cc-exp-year"
          >
            <option disabled value="">YYYY</option>
            <option *ngFor="let yearV of yearsData" value="{{ yearV }}">
              {{ yearV }}
            </option>
          </select>
          <div class="input-group-append">
            <span class="input-group-text"
              ><img
                class="filterSVG-Green"
                src="assets\images\purchase\calendar-alt-solid.svg"
                height="20px"
                alt=""
            /></span>
          </div>
          <div class="invalid-feedback">Exp Year is required.</div>
        </div>
      </div>
      <div class="CVVCreditCard">
        <label class="col-form-label input-label">CVV*</label>
        <div class="input-group">
          <input
            type="password"
            formControlName="CVVCreditCard"
            id="CVVCreditCard"
            class="form-control"
            [maxlength]="maxLength"
            [ngClass]="{
              'is-invalid': getError('CVVCreditCard')
            }"
            autocomplete="cc-csc"
          />
          <div class="input-group-append">
            <span class="input-group-text" id="basic-addon2"
              ><img
                class="filterSVG-Green"
                src="assets\images\purchase\question-circle-solid.svg"
                height="20px"
                alt=""
            /></span>
          </div>
          <div class="invalid-feedback">CVV is required.</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #NormalECheckForm>
  <div
    fxLayout="column"
    fxLayoutAlign="start stretch"
    fxLayoutGap="15px"
    [formGroup]="paymentGroup"
  >
    <div fxLayout="row" fxLayoutAlign="start stretch" fxLayoutGap="15px" fxFill>
      <div class="custom-control custom-radio">
        <input
          type="radio"
          id="CheckingAccount"
          formControlName="eCheckType"
          value="PersonalChecking"
          [ngClass]="{ 'is-invalid': getError('eCheckType') }"
        />
        <label
          class="custom-control-label answer-question label-radio"
          for="CheckingAccount"
          ><strong>Checking Account</strong></label
        >
      </div>
      <div class="custom-control custom-radio">
        <input
          type="radio"
          id="SavingsAccount"
          formControlName="eCheckType"
          value="PersonalSavings"
          [ngClass]="{ 'is-invalid': getError('eCheckType') }"
        />
        <label
          class="custom-control-label answer-question label-radio"
          for="SavingsAccount"
          ><strong>Savings Account</strong></label
        >
      </div>
    </div>
    <div
      fxLayout="row"
      fxLayoutAlign="space-between stretch"
      fxLayoutGap="15px"
      fxFill
    >
      <div class="routing-number">
        <label class="col-form-label input-label">Routing Number*</label>
        <input
          type="text"
          id="routingNumber"
          formControlName="routingNumber"
          class="form-control"
          [imask]="maskRouting"
          [unmask]="true"
          [ngClass]="{
            'is-invalid': getError('routingNumber')
          }"
        />
        <div class="invalid-feedback">Routing Number is required.</div>
      </div>
      <div class="account-number">
        <label class="col-form-label input-label">Account Number*</label>
        <input
          type="text"
          id="accountNumber"
          formControlName="accountNumber"
          class="form-control"
          [imask]="maskAccount"
          [unmask]="true"
          [ngClass]="{
            'is-invalid': getError('accountNumber')
          }"
        />
        <div class="invalid-feedback">Account Number is required.</div>
      </div>
    </div>
  </div>
</ng-template>
