import { ProcessService } from './../../shared/services/process/process.service';
import {
  Component,
  ElementRef,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEmpty, isNil, values } from 'ramda';
import { map } from 'rxjs/operators';
import { InformationForm, YourNameForm } from 'src/app/interfaces/information';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner/progress-spinner.service';
import {
  AdditionalInterest,
  ApiService,
  ConstructionType,
  InsuredAddress,
  InsuredPersonalInfo,
  RateControllerInput,
  RateView
} from 'src/services/generated/api-services';
import { faChevronCircleRight } from '@fortawesome/free-solid-svg-icons';
import { YourNameComponent } from './your-name/your-name.component';
import { YourInfoComponent } from './your-info/your-info.component';
import { AdditionalInterestComponent } from './additional-interest/additional-interest.component';
import { environment } from 'src/environments/environment';
import { utilities } from 'src/app/utilities/utilities';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { masks } from 'src/app/utilities/masks';
import { StorageService } from 'src/app/shared/services/Storage/StorageService';
const defaultInsured = {
  firstName: '',
  lastName: '',
  emailAddress: 'test@test.com',
  dateOfBirth: new Date(1970, 0, 1),
  phoneNumber: '1234567890',
  phoneType: 0,
  isPrincipal: true
};

@UntilDestroy()
@Component({
  selector: 'app-insured-information',
  templateUrl: './insured-information.component.html',
  styleUrls: ['./insured-information.component.scss']
})
export class InsuredInformationComponent implements OnDestroy, OnInit {
  @ViewChild('yourNameComponent') yourNameComponent: YourNameComponent;
  @ViewChild('yourInfoComponent') yourInfoComponent: YourInfoComponent;
  @ViewChild('additionalInterestComponent')
  additionalInterestComponent: AdditionalInterestComponent;

  isValidYourNameForm = false;
  isValidYourInfoForm = false;
  isValidAdditionalInterestForm = false;
  isValidYourNameSecondaryForm = false;

  address?: InsuredAddress;
  constructionTypes?: ConstructionType[];
  deductibles?: number[];
  personalPropertyLimits?: number[];
  personalLiabilityLimits?: number[];
  rateParams?: RateControllerInput;
  liabilityLimit?: number;
  premium?: number;
  insuredData = { informationStep: {}, rate: {} };
  iconArrowRight = faChevronCircleRight;
  addtionalInterestValues: AdditionalInterest;

  insuredInfo?: InsuredPersonalInfo;
  secondInsuredInfo?: InsuredPersonalInfo;
  isInvalidForm = true;
  aditionalInsured?: boolean;
  insuredCalled?: boolean;
  processGuid: string;
  additionalInterest?: boolean;
  quoteID: string;
  telContac = utilities.numberToPhone(environment.contactPhone);
  paperlessDocuments = true;

  maskCity = masks.maskOnlyLetters;
  maskState = masks.maskOnlyLetters;
  maskZipCode = masks.maskOnlyNumber;

  mailingAddressForm = new FormGroup({
    street: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    state: new FormControl('', Validators.required),
    zipCode: new FormControl('', [Validators.required, Validators.minLength(5)])
  });

  changedPLMsg = 'changed PL';
  changedPDMsg = 'Primary Deductible';
  liabilityLimitMsg = 'Liabillity Limit Changed to ';
  deductibleLimitMsg = 'Deductible Limit Changed to ';
  coveragesMsgTitle = 'Coverage Options';

  constructionTypeId?;
  previousAddress: any;

  constructor(
    public activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private elementRef: ElementRef,
    private progressSpinnerService: ProgressSpinnerService,
    private alertService: AlertService,
    private router: Router,
    private ngZone: NgZone,
    private storageService: StorageService,
    private fb: FormBuilder,
    private processService: ProcessService
  ) {}

  ngOnDestroy() {
    this.elementRef.nativeElement.remove();
  }

  ngOnInit() {
    setTimeout(() => {
      this.activatedRoute.paramMap
        .pipe(
          untilDestroyed(this),
          map(() => window.history.state)
        )
        .subscribe({
          next: ({
            constructionTypes,
            deductibles,
            personalPropertyLimits,
            address,
            propertyTypeId,
            premium,
            liabilityLimit,
            rateParams,
            personalLiabilityLimits,
            additionalInterest,
            constructionTypeId
          }) => {
            this.address = address;
            this.addtionalInterestValues = additionalInterest;
            this.constructionTypes = constructionTypes;
            this.deductibles = deductibles;
            this.personalPropertyLimits = personalPropertyLimits;
            this.personalLiabilityLimits = personalLiabilityLimits;
            this.insuredData.informationStep = {
              ...this.insuredData.informationStep,
              personalPropertyLimits,
              deductibles,
              constructionTypes,
              address,
              propertyTypeId,
              personalLiabilityLimits,
              constructionTypeId
            };

            this.constructionTypeId = constructionTypeId;
            this.processGuid = this.processService.getProcessGuid();

            if (premium && liabilityLimit && rateParams && this.processGuid) {
              this.premium = premium;
              this.liabilityLimit = liabilityLimit;
              this.rateParams = rateParams;

              this.insuredInfo = rateParams.insuredInfo;
              this.secondInsuredInfo = rateParams.secondInsuredInfo;

              this.previousAddress = rateParams?.previousAddress;

              const allValues = (obj) =>
                values(obj).every((d) => !isEmpty(d) && !isNil(d));

              if (rateParams.secondInsuredInfo) {
                this.aditionalInsured = allValues(rateParams.secondInsuredInfo);
              }

              if (this.rateParams?.paperless) {
                this.paperlessDocuments = false;
                this.mailingAddressForm.patchValue({
                  street: this.rateParams.mailingAddress.address1,
                  city: this.rateParams.mailingAddress.city,
                  state: this.rateParams.mailingAddress.stateCode,
                  zipCode: this.rateParams.mailingAddress.zipCode
                });
              }

              if (additionalInterest) {
                this.additionalInterest = allValues(additionalInterest);
              }
            } else {
              this.storageService.clear();
              this.rateParams = {
                insuredInfo: defaultInsured,
                address,
                rate: { propertyTypeId }
              };
            }
          },
          error: console.error
        });
    }, 100);
  }

  rate(fromInsured?: boolean, fromContinue?: boolean) {
    const allValues = (obj) =>
      values(obj).every((d) => !isEmpty(d) && !isNil(d));
    const hasAllInsuredData = allValues(this.rateParams.insuredInfo);
    const hasAllSecondInsuredData = allValues(
      this.rateParams.secondInsuredInfo
    );

    const rateParams = this.rateParams;

    if (this.quoteID) {
      rateParams.companysQuoteNumber = this.quoteID;
    }

    if (!hasAllInsuredData) {
      rateParams.insuredInfo = defaultInsured;
    }

    if (!this.aditionalInsured) {
      rateParams.secondInsuredInfo = undefined;
    }

    if (
      !this.rateParams.previousAddress?.address1 ||
      !this.rateParams.previousAddress?.city ||
      !this.rateParams.previousAddress?.stateCode ||
      !this.rateParams.previousAddress?.zipCode
    ) {
      this.rateParams.previousAddress = null;
    }

    if (
      values(this.rateParams?.rate).length > 1 &&
      (!fromInsured ||
        (fromInsured && hasAllInsuredData && !this.aditionalInsured) ||
        (this.aditionalInsured &&
          fromInsured &&
          hasAllInsuredData &&
          hasAllSecondInsuredData &&
          this.rateParams.secondInsuredInfo))
    ) {
      this.progressSpinnerService.progressStart();
      rateParams.questions = undefined;
      rateParams.processGuid = this.processGuid;

      if (this.paperlessDocuments) {
        rateParams.mailingAddress = undefined;
        rateParams.paperless = false;
      } else {
        rateParams.mailingAddress = {
          address1: this.mailingAddressForm.get('street').value,
          city: this.mailingAddressForm.get('city').value,
          stateCode: this.mailingAddressForm.get('state').value,
          zipCode: this.mailingAddressForm.get('zipCode').value
        };
        rateParams.paperless = true;
      }

      if (fromContinue) {
        this.insuredData.informationStep = {
          ...this.insuredData.informationStep,
          premium: this.premium,
          liabilityLimit: this.rateParams.rate.personalLiabilityLimit,
          rateParams: this.rateParams
        };
        this.continue();
      }

      this.apiService
        .rate(rateParams)
        .pipe(untilDestroyed(this))
        .subscribe({
          next: (rateView: RateView) => {
            if (
              this.insuredCalled &&
              hasAllInsuredData &&
              !this.aditionalInsured
            ) {
              this.isInvalidForm = false;
            } else if (
              this.aditionalInsured &&
              hasAllInsuredData &&
              hasAllSecondInsuredData &&
              this.rateParams.secondInsuredInfo
            ) {
              this.isInvalidForm = false;
            } else {
              this.isInvalidForm = true;
            }

            this.insuredData.rate = rateView;
            this.premium = rateView.fullTermAmt;
            this.insuredData.informationStep = {
              ...this.insuredData.informationStep,
              premium: this.premium,
              liabilityLimit: this.rateParams.rate.personalLiabilityLimit,
              rateParams: this.rateParams
            };

            this.quoteID = rateView.companysQuoteNumber;
            this.storageService.setItem('quote', this.quoteID);

            this.CheckIfLiabilityLimitWasChanged(rateView?.msgStatusDesc);
          },
          error: (error) => {
            this.progressSpinnerService.progressStop();
            this.isInvalidForm = true;
            this.alertService.AlertSimple('Property Info', error?.detail);
          },
          complete: () => this.progressSpinnerService.progressStop()
        });
    } else {
      this.isInvalidForm = true;
    }
  }

  CheckIfLiabilityLimitWasChanged(message: string) {
    const messages = message.split(', ');

    const plMessage = messages.find((msg) =>
      msg.toLowerCase().includes(this.changedPLMsg.toLowerCase())
    );
    const pdMessage = messages.find((msg) =>
      msg.toLowerCase().includes(this.changedPDMsg.toLowerCase())
    );

    let messageAlert = '';
    if (plMessage) {
      const limit = plMessage.replace(/[^0-9\.]+/g, '');
      if (limit) {
        const limitValue = parseFloat(limit);
        this.liabilityLimit = limitValue;
        messageAlert += `- ${this.liabilityLimitMsg} ${this.formatToCurrency(
          limitValue
        )} <br/>`;
      }
    }

    if (pdMessage) {
      const limit = pdMessage.replace(/[^0-9\.]+/g, '');
      if (limit) {
        const limitValue = parseFloat(limit);
        this.rateParams.rate.deductible = limitValue;
        messageAlert += `- ${this.deductibleLimitMsg} ${this.formatToCurrency(
          limitValue
        )} <br/>`;
      }
    }

    if (messageAlert !== '') {
      this.alertService.AlertSimpleHTML(this.coveragesMsgTitle, messageAlert);
    }
  }

  rateInfo(form: InformationForm) {
    const {
      deductible,
      personalPropertyLimit,
      personalLiabilityLimit,
      constructionTypeId
    } = this.rateParams.rate;

    this.rateParams.rate.policyStartDate = utilities.createDate(form?.policyStartDate);

    if (
      deductible != Number(form?.deductible) ||
      personalPropertyLimit != Number(form?.personalPropertyLimit) ||
      personalLiabilityLimit != Number(form?.personalLiabilityLimit) ||
      constructionTypeId?.localeCompare(form?.constructionTypeId) != 0
    ) {
      this.rateParams.rate = {
        ...this.rateParams?.rate,
        ...form,
        deductible: parseInt(form?.deductible),
        personalPropertyLimit: parseInt(form.personalPropertyLimit),
        personalLiabilityLimit: parseInt(form.personalLiabilityLimit),
        constructionTypeId: form?.constructionTypeId,
        policyStartDate: utilities.createDate(form?.policyStartDate)
      };
      this.rate();
    }
  }

  additionaInfo(form: YourNameForm) {
    this.addtionalInterestValues = form.additionalInterestInfo;
  }

  rateName(
    {
      insuredInfo,
      secondInsuredInfo,
      aditionalInsured,
      previousAddress
    }: {
      insuredInfo: InsuredPersonalInfo;
      secondInsuredInfo?: InsuredPersonalInfo;
      aditionalInsured?: boolean;
      previousAddress?: any;
    },
    aditionalInsuredForm: boolean
  ) {
    this.insuredCalled = true;

    if (aditionalInsuredForm) {
      this.rateParams = {
        ...this.rateParams,
        secondInsuredInfo: values(insuredInfo).every(
          (val) => !isEmpty(val) && !isNil(val)
        )
          ? {
              ...this.rateParams.insuredInfo,
              ...insuredInfo
            }
          : undefined
      };
      if (this.rateParams.secondInsuredInfo) {
        this.rateParams.secondInsuredInfo.isPrincipal = false;
      }
    } else {
      this.rateParams = {
        ...this.rateParams,
        insuredInfo: { ...this.rateParams?.insuredInfo, ...insuredInfo }
      };
    }

    this.rateParams.previousAddress = previousAddress;
  }

  continueValidation() {
    const valid = this.yourNameComponent.getFormValid();
    const yourInfoValid = this.yourInfoComponent.getValidForm();

    if (valid && yourInfoValid) {
      this.rate(true, true);
    }
  }

  continue() {
    let params = {
      ...this.insuredData,
      additionalInterest: this.addtionalInterestValues
    };

    this.ngZone
      .run(() => this.router.navigate(['/underwriting'], { state: params }))
      .then();
  }

  goBack() {
    this.ngZone.run(() => this.router.navigate(['/home'])).then();
  }

  changePaperless() {
    this.mailingAddressForm.reset();
  }

  getError(nameControl: string) {
    return (
      this.mailingAddressForm?.get(nameControl)?.invalid &&
      this.mailingAddressForm?.get(nameControl)?.touched
    );
  }

  formatToCurrency(amount) {
    return '$' + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  IsAdditionalInterestChecked() {
    if (!this.additionalInterest) {
      this.addtionalInterestValues = undefined;
      this.isValidAdditionalInterestForm = false;
    }
  }
}
