declare var window: any;

export class DynamicEnvironment {
  public get environment() {
    return window.config?.environment;
  }

  public get configMessage() {
    return window.config?.message;
  }

  public get SessionDuration() {
    return window.config?.environmentUrl?.SESSION_DURATION;
  }

  public get EnvironmentUrlApiUrl() {
    return window.config?.environmentUrl?.API_URL;
  }

  public get EnvironmentLSA() {
    return window.config?.environmentLSA?.API_URL;
  }

  public get EnvironmentDocuSign() {
    return window.config?.environmentDocuSign?.API_URL;
  }

  public get SubjectivityToken() {
    return window.config?.environmentUrl?.API_KEY_SUBJECTIVITY;
  }

  public get LSAToken() {
    return window.config?.environmentLSA?.API_KEY_IMS_SERVICE;
  }

  public get DocuSignToken() {
    return window.config?.environmentDocuSign?.API_KEY_DOCUSIGN;
  }

  public get DepositCURL() {
    return window.config?.environmentDC?.API_URL;
  }

  public get DepositCToken() {
    return window.config?.environmentDC?.API_KEY_DC;
  }

  public get PaymentURL() {
    return window.config?.environmentPayment?.API_URL;
  }

  public get PaymentToken() {
    return window.config?.environmentPayment?.API_KEY_PAYMENT;
  }

  public get FileName() {
    return window.config?.fileName;
  }

  public get TemplateID() {
    return window.config?.templateID;
  }

  public get Company() {
    return window.config?.company;
  }

  public get FolderName() {
    return window.config?.folderName;
  }

  public get DocumentTypeGUID() {
    return window.config?.documentTypeGUID;
  }

  public get Description() {
    return window.config?.description;
  }

  public get ConditionID() {
    return window.config?.conditionID;
  }

  public get ReturnURL() {
    return window.config?.returnUrl;
  }

  public get LineGUID() {
    return window.config?.lineGUID;
  }

  public get CompanyLocationGUID() {
    return window.config?.companyLocationGUID;
  }

  public get UnderwriterGUID() {
    return window.config?.underwriterGUID;
  }

  public get QuotingLocationGUID() {
    return window.config?.quotingLocationGUID;
  }

  public get IssuingLocationGUID() {
    return window.config?.issuingLocationGUID;
  }

  public get CostCenterID() {
    return window.config?.costCenterID;
  }

  public get OfficeID() {
    return window.config?.officeID;
  }

  public get BusinessTypeID() {
    return window.config?.businessTypeID;
  }

  public get LocationName() {
    return window.config?.locationName;
  }

  public get ISOCountryCode() {
    return window.config?.ISOCountryCode;
  }

  public get LocationType() {
    return window.config?.locationType;
  }

  public get DeliveryMethodID() {
    return window.config?.deliveryMethodID;
  }

  public get LocationTypeID() {
    return window.config?.locationTypeID;
  }

  public get QuoteStatusID() {
    return window.config?.quoteStatusID;
  }

  public get BillingTypeID() {
    return window.config?.billingTypeID;
  }

  public get PolicyTypeID() {
    return window.config?.policyTypeID;
  }

  public get PayerFee() {
    return window.config?.payerFee;
  }

  public get DocuSignDirect() {
    return window.config?.environmentDocuSignDirect?.API_URL;
  }
  public get ProtectionURL() {
    return window.config ? window.config?.environmentPC?.API_URL : '';
  }

  public get ProtectionToken() {
    return window.config ? window.config?.environmentPC?.API_KEY_PC : '';
  }

  public get RaterID() {
    return window.config?.onlineRaterID;
  }

  public get ChargeFee() {
    return window.config?.chargeFee;
  }

  public get catalyticPhone() {
    return window.config?.catalyticPhone;
  }

  public get contactPhone() {
    return window.config?.constacInfo?.PHONE;
  }

  public get contactFax() {
    return window.config?.constacInfo?.FAX;
  }
  public get contactEmail() {
    return window.config?.constacInfo?.EMAIL;
  }

  public get contactPhoneInfoQuote() {
    return window.config?.constacInfo?.PHONEINFOQUOTE;
  }

  public get TermsConditionsCC() {
    return window.config?.stillwaterDocuments?.TermsConditionsCC;
  }
  public get TermsConditionsEF() {
    return window.config?.stillwaterDocuments?.TermsConditionsEF;
  }

  public get urlLogin() {
    return window.config?.urlLogin;
  }

  public get tooltipContentsCoverage() {
    return window.config?.toolTipsInfo?.ContentsCoverage;
  }
  public get tooltipLiabilityLimit() {
    return window.config?.toolTipsInfo?.LiabilityLimit;
  }
}
