import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { nameAlphanumericPattern } from 'src/app/constants/patters';
import { YourNameForm } from 'src/app/interfaces/information';
import { masks } from 'src/app/utilities/masks';
import { AdditionalInterest } from 'src/services/generated/api-services';

@UntilDestroy()
@Component({
  selector: 'app-additional-interest',
  templateUrl: './additional-interest.component.html',
  styleUrls: ['./additional-interest.component.scss']
})
export class AdditionalInterestComponent implements OnInit, OnChanges {
  disableAddtionalInterest: boolean = true;
  maskCity = masks.maskOnlyLetters;
  maskState = masks.maskOnlyLetters;
  maskZipCode = masks.maskOnlyNumber;
  additionalInterestForm = new FormGroup({
    additionalInterestName : new FormControl('', [Validators.pattern(nameAlphanumericPattern), Validators.required]),
      address1:  new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      stateCode: new FormControl('', [Validators.required]),
      zipCode: new FormControl('', [Validators.required, Validators.minLength(5)]),
  });

  @Output() isFormValid = new EventEmitter<boolean>();
  @Output() form = new EventEmitter<YourNameForm>();
  @Input() additionalInfo: AdditionalInterest;

  
  constructor(private fb: FormBuilder,) {
    this.additionalInterestForm.statusChanges.subscribe((status) => {
      const statusReturn = status === 'VALID';
      if(statusReturn) {
          const addtionalInterest = this.getAdditionalInterest();
          this.form.emit( {
            additionalInterestInfo: addtionalInterest,
            insuredInfo: null,
            secondInsuredInfo: null
          });
        }

      this.isFormValid.emit(statusReturn);
    });
   }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    const name = changes?.additionalInfo?.currentValue?.additionalInterestName == changes?.additionalInfo?.previousValue?.additionalInterestName;
    const address = changes?.additionalInfo?.currentValue?.address1 == changes?.additionalInfo?.previousValue?.address1;
    const city = changes?.additionalInfo?.currentValue?.city == changes?.additionalInfo?.previousValue?.city;
    const state = changes?.additionalInfo?.currentValue?.stateCode == changes?.additionalInfo?.previousValue?.stateCode;
    const zipcode = changes?.additionalInfo?.currentValue?.zipCode == changes?.additionalInfo?.previousValue?.zipCode; 
   
    if (!name || !address || !city || !state || !zipcode) {
        this.additionalInterestForm.patchValue(changes?.additionalInfo.currentValue);
    }
  }

  getAdditionalInterest() : AdditionalInterest {
    let additionalInterestGroup = this.additionalInterestForm;
    const name = additionalInterestGroup.controls['additionalInterestName'].value;
    
    if(name && name.toString().trim() !== '') {
      return this.additionalInterestForm.value;
    }
  }

  getError(nameControl: string, isAdditionalInterest?: boolean) {
    if(isAdditionalInterest) {
      return (
        this.additionalInterestForm?.get(nameControl)?.invalid &&
        this.additionalInterestForm?.get(nameControl)?.touched);
    }
  }

  getValidForm() {
    this.additionalInterestForm.markAllAsTouched();
    return this.additionalInterestForm.valid;
  }

}
