import { Component, OnInit } from '@angular/core';
import { AddressService } from 'src/app/shared/services/address/address.service';
import Swal from 'sweetalert2';
import { Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  address: any;
  currentComponent: any;

  constructor(private addressService: AddressService, private router: Router) {
    router.events
      .pipe(filter((e) => e instanceof RouterEvent))
      .subscribe((e: RouterEvent) => {
        this.currentComponent = e.url;
      });
  }

  ngOnInit() {
    this.address = this.addressService.getAddress();
  }

  AddressValidation() {
    if (!this.address) {
      Swal.fire({
        text: 'Please enter address to continue',
        icon: 'warning',
        confirmButtonText: 'Continue',
        confirmButtonColor: '#046a38',
        scrollbarPadding: false
      });
    } else {
      this.router.navigate(['/purchase']);
    }
  }

  ValidationExit(NamePage: string) {
    if (this.address) {
      Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to leave and loose your progress?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#046a38',
        cancelButtonColor: '#d33',
        confirmButtonText: 'OK',
        scrollbarPadding: false
      }).then((result) => {
        if (result.value) {
          this.addressService.deleteAddress();
          this.router.navigate(['/' + NamePage]);
        }
      });
    } else {
      this.router.navigate(['/' + NamePage]);
    }
  }

  ValidationLogin() {
    if (this.address) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The changes will be lost!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#046a38',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, exit!',
        scrollbarPadding: false
      }).then((result) => {
        if (result.value) {
          this.addressService.deleteAddress();
          window.location.href = environment.urlLogin;
        }
      });
    } else {
      window.location.href = environment.urlLogin;
    }
  }

  NavigateHome(id: string) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: 'smooth' });
  }
}