<div
  class="layout"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  fxLayoutGap="20px"
  mat-dialog-content
  #containerMakePayment
>
  <div fxLayout="column" fxLayoutAlign="start start">
    <h1 class="title-make-payment">Confirm Information and Purchase Policy</h1>
    <p class="legend">
      Thank you for allowing Stillwater Insurance Group to be of service to you.
    </p>
  </div>
  <div fxLayout="row" fxLayoutAlign=" start"  fxLayoutGap="12px">
    <div
      fxLayout="column"
      fxLayoutAlign="start stretch"
      fxFlex="50"
      fxLayoutGap="7px"
      class="make-payment-section"
    >
      <app-banner-quote-generated></app-banner-quote-generated>
      <p><strong>Payment Plan: </strong>{{ planSelect?.paymentPlanDesc }}</p>
      <p>
        <strong>Deposit Amount: </strong
        >{{ planSelect?.firstPaymentAmount | currency }}
      </p>
      <p *ngIf="planSelect?.installmentAmount > 0">
        <strong>Installment Amount({{ planSelect?.numInstallments }}): </strong
        >{{ planSelect?.installmentAmount | currency }} *
      </p>
      <p *ngIf="planSelect?.installmentFee > 0">
        <strong>Installment Service Fee: </strong>
        {{ planSelect?.installmentFee | currency }}
      </p>
      <div class="includes-fees" *ngIf="planSelect?.installmentAmount > 0">
        * Includes Fees
      </div>
    </div>
    <div
      fxFlex="50"
      fxLayout="column"
      fxLayoutAlign="start stretch"
      class="your-info-container"
    >
      <h1>Your Info</h1>
      <div class="your-info-details">
        <p>
          <strong>Personal Property Coverage </strong><br />
          {{ yourInfoData?.personalPropertyLimit | currency }}
        </p>
        <p>
          <strong>Policy Start Date </strong><br />
          {{ yourInfoData?.policyStartDate | date: 'MMMM dd yyyy' }}
        </p>
        <p>
          <strong>Liability Limit </strong><br />
          {{ yourInfoData?.liabilityLimit | currency }}
        </p>
        <p>
          <strong>Deductible </strong><br />
          {{ yourInfoData?.deductible | currency }}
        </p>
      </div>
    </div>
  </div>

  <h1>Please Review</h1>
  <h2>Confirm Down Payment Details</h2>

  <div fxLayout="column" fxLayoutAlign="space-between start">
    <p><strong>Payment Method: </strong>{{ paymentMethod }}</p>
    <p><strong>Account Number: </strong> {{ accountNumber }}</p>
    <p>
      <strong>Payment Amount: </strong>
      {{ paymentInput?.billingInfo?.transferAmt | currency }}
    </p>
  </div>

  <p>
    NOTICE: To avoid getting charged more than once, please do not use your
    browser’s refresh or back buttons.
  </p>

  <p>
    An email from DocuSign will be sent directly to
    <strong>{{ paymentInput?.insuredInfo?.emailAddress }}</strong> with
    important Form Acknowledgements that need your electronic signature. Please
    sign and return as soon as possible to bind your policy.
  </p>
</div>
<div class="d-flex justify-content-end container-btn" mat-dialog-actions>
  <button class="btn btn-primary btn-goback" (click)="dialogRef.close(false)">
    Cancel
  </button>
  <button
    mat-raised-button
    color="primary"
    class="btn-continue"
    (click)="makePayment()"
  >
    Buy Now
  </button>
</div>
