import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class ProcessService {

  processGuid: string;
  constructor() { }

  createProcessGuid():string{
    this.processGuid = Guid.create().toString();
    return this.processGuid;
  }

  getProcessGuid(): string{
    return this.processGuid;
  }

  removeProcessGuid(){
    this.processGuid = null;
  }
}
