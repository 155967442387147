import { Component, OnInit, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as IMask from 'imask';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { AddressService } from 'src/app/shared/services/address/address.service';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { BondCoverageService } from 'src/app/shared/services/bondCoverage/bond-coverage.service';
import { IMSService } from 'src/app/shared/services/IMS/ims.service';
import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner/progress-spinner.service';
import { environment } from 'src/environments/environment';
import { AddressModel } from 'src/model/Address';
import { Property } from 'src/model/Property';
import {
  ConstructionType,
  GetPersonalPropertyInfoView,
  GetPropertyTypesView,
  InsuredAddress,
  PersonalPropertyService,
  PropertyType
} from 'src/services/generated/api-services';
import Swal from 'sweetalert2';
import { MoratoriumCheckRequest } from './../../../../model/IMSService/MoratoriumCheckRequest';
import { MoratoriumCheckResponse } from './../../../../model/IMSService/MoratoriumCheckResponse';
import { HomeAddressComponent } from './../home-address/home-address.component';

declare var google;
let place;

@UntilDestroy()
@Component({
  selector: 'app-home-autocomplete',
  templateUrl: './home-autocomplete.component.html',
  styleUrls: ['./home-autocomplete.component.scss']
})
export class HomeAutocompleteComponent implements OnInit {
  address: AddressModel;
  getAddressView: any;

  show: boolean;

  Unit?: string;
  selectUnit = 'Select unit';

  googleAddress: string;

  unitsOptions: any[] = [];

  autocomplete: string;
  addressLine2: string;

  Property = new Property();
  unitForAddress: any;

  // Modal
  bsModalAddress: BsModalRef;

  propertyTypes?: PropertyType[];
  homeForm = new FormGroup({
    address: new FormControl('', Validators.required),
    apt: new FormControl(''),
    propertyTypeId: new FormControl('', Validators.required),
    constructionTypeId: new FormControl({ value: '', disabled: true }, Validators.required)
  });

  maskAPT = new IMask.MaskedRegExp({
    mask: /^[#A-Za-z0-9\s]*$/
  });

  constructionTypes?: ConstructionType[];

  constructor(
    private router: Router,
    private addressService: AddressService,
    private bondCoverageService: BondCoverageService,
    private modalService: BsModalService,
    private imsService: IMSService,
    private ppService: PersonalPropertyService,
    private progressSpinnerService: ProgressSpinnerService,
    private alertService: AlertService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.ppService
      .getPropertyTypes()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: ({ propertyTypes }: GetPropertyTypesView) =>
          (this.propertyTypes = propertyTypes),
        error: (err) => console.error('error getting property types:', err)
      });
    const autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('autocomplete'),
      { types: ['geocode'] }
    );
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      place = autocomplete.getPlace();
      this.ClearAllData();
      this.GetAddress();
      if (this.validateInputs()) {
        this.getConstructionTypes();
      }
    });

    this.homeForm.get('propertyTypeId').valueChanges.pipe(untilDestroyed(this)).subscribe(res => {
      if (this.validateInputs()) {
        this.getConstructionTypes();
      }
    });

    this.homeForm.get('address').valueChanges.pipe(untilDestroyed(this)).subscribe(res => {
      const addressFieldHasValue = !!this.homeForm.get('address').value && this.homeForm.get('address').value !== ' ';
      if (!addressFieldHasValue) {
        this.constructionTypes = []
        this.homeForm.get('constructionTypeId').disable();
        this.homeForm.patchValue({
          constructionTypeId: ''
        });
      }
    });
  }

  GetAddress() {
    if (place.propertyIsEnumerable('address_components')) { //Validate if property exists and if is enumerable
      // tslint:disable-next-line: prefer-for-of
      for (const component of place.address_components) {
        switch (component.types[0]) {
          case 'street_number':
            this.address.streetNumber = component.short_name;
            break;
          case 'route':
            this.address.route  = component.short_name;
            break;
          case 'locality':
            this.address.locality = component.short_name;
            break;
          case 'administrative_area_level_1':
            this.address.administrativeAreaLevel1 = component.short_name;
            break;
          case 'country':
            this.address.country = component.short_name;
            break;
          case 'postal_code':
            this.address.postalCode = component.short_name;
            break;
          case 'administrative_area_level_2':
            this.address.county = component.short_name;
            break;
          case 'sublocality_level_1':
            if(!this.address.locality){
              this.address.locality = component.short_name;
            }
            break;
        }
      }
      if(!this.address?.streetNumber){
        const fieldAddress:string = this.homeForm.get('address').value;
        const addressArray = fieldAddress?.split(' ');
        this.address.streetNumber = addressArray[0]
      }
      this.ValidateIncompleteAddress();
    }
  }

  ValidateIncompleteAddress():boolean{
    let fields: string[] = [];
    for (const entry of Object.entries(this.address)) {
      if(!entry[1]){
        let fieldName: string;
        switch (entry[0]) {
          case 'streetNumber': fieldName = 'Street Number';
            break;
          case 'route': fieldName = 'Street Name';
            break;
          case 'administrativeAreaLevel1': fieldName = 'State Code';
            break;
          case 'postalCode': fieldName = 'Postal Code';
            break;
          case 'locality': fieldName = 'City';
            break;
        }
        fields.push('<tr><td><li>' + fieldName + '</li></td></tr>');
      }
    }
    if(fields.length > 0){
      const message = '<span>We encountered a problem and could not get the following information.</span><br><br>'+
      '<table style="text-align: left; margin-left: 10px;">'+ fields.toString().replace(',','') + '</table>';
      Swal.fire({
        title: 'Problems obtaining address information.',
        html: message,
        icon: 'warning',
      });
      this.ClearAllData();
      return false;
    }
    else{
      return true;
    }
  }

  SetUnit() {
    if (this.Unit !== '') {
      // All units
      this.unitForAddress = this.unitsOptions.find(
        (x) => x.unitNumber === this.Unit
      );
      this.addressService.setUnitForAddressLocal(this.unitForAddress);
      if (this.unitForAddress) {
        this.Property.CommunityName = this.unitForAddress.communityName;
        this.Property.CommunityNumber = this.unitForAddress.communityId;
        this.Property.PropertyName = this.unitForAddress.managementName;
      }
      this.bondCoverageService.Property$.emit(this.Property);
    }
  }

  AddressValidation() {

    this.address.unitNumber = this.addressLine2;
    this.Unit = this.addressLine2;
    this.getAddressView = {
      address1: this.address?.streetNumber + ' ' + this.address?.route,
      address2: this.addressLine2,
      city: this.address?.locality,
      stateCode: this.address?.administrativeAreaLevel1,
      zipCode: this.address?.postalCode
    };
    this.addressService.setAddress(this.getAddressView);

    this.unitForAddress = {
      unitId: 0,
      unitNumber: this.addressLine2
    };

    this.addressService.setUnitForAddressLocal(this.unitForAddress);
    if (this.getAddressView) {
      const infoRequest = {
        stateCode: this.address?.administrativeAreaLevel1,
        propertyTypeCode: this.homeForm.get('propertyTypeId')?.value
      };
      const address: InsuredAddress = {
        address1: `${this.address.streetNumber ?? 0} ${this.address.route}`,
        address2: this.homeForm.get('apt')?.value,
        city: this.address.locality,
        country: this.address.country,
        stateCode: this.address?.administrativeAreaLevel1,
        zipCode: this.address?.postalCode
      };
      if (
        address?.address1 &&
        address?.stateCode &&
        address?.zipCode &&
        address?.stateCode
      ) {
        this.progressSpinnerService.progressStart();
        this.ppService.getInfo(infoRequest).subscribe({
          next: (state: GetPersonalPropertyInfoView) =>
            this.router.navigate(['/information'], {
              state: {
                address,
                propertyTypeId: this.homeForm.get('propertyTypeId')?.value,
                constructionTypeId: this.homeForm.get('constructionTypeId').value,
                ...state
              }
            }),
          error: () => this.progressSpinnerService.progressStop(),
          complete: () => this.progressSpinnerService.progressStop()
        });
      } else {
        this.alertService.AlertSimple(
          'Rating could not be completed',
          'We are unable to verify the address.'
        );
      }
    } else {
      Swal.fire({
        title: 'Rating could not be completed',
        text: 'We are unable to verify the address.',
        icon: 'error',
        showCancelButton: false,
        confirmButtonColor: '#046a38',
        confirmButtonText: 'OK',
        scrollbarPadding: false
      });
    }
  }

  MoratoriumValidation(): Observable<MoratoriumCheckResponse> {
    let moratoriumCheck: MoratoriumCheckRequest = {
      lineGuid: environment.LineGUID,
      unitId: this.unitForAddress.unitId
    };
    return this.imsService.postMoratoriumCheck(moratoriumCheck);
  }

  ClearAllData() {
    this.address = {
      route : null,
      administrativeAreaLevel1 : null,
      postalCode : null,
      streetNumber: null,
      locality: null
    };
    this.addressService.deleteAddress();
    this.autocomplete = '';
  }

  onKeydown(event: KeyboardEvent) {
    let k = event.key;
    if (
      k != 'ArrowLeft' &&
      k != 'ArrowRight' &&
      k != 'ArrowUp' &&
      k != 'ArrowDown' &&
      k != 'Shift' &&
      k != 'Control' &&
      k != 'Alt' &&
      k != 'AltGraph' &&
      k != ' ' &&
      k != 'End' &&
      k != 'Home' &&
      k != 'Enter'
    ) {
      if (k == 'Backspace' || k == 'Delete') {
        this.ClearAllData();
      } 
      
    }
  }

  validateInputs(): boolean {
    const hasAddress = this.address && !Object.values(this.address).every(o => o === null);
    const propertyTypeHasValue = !!this.homeForm.get('propertyTypeId').value;

    return hasAddress && propertyTypeHasValue
  }

  getConstructionTypes(): void {
    const infoRequest = {
      stateCode: this.address?.administrativeAreaLevel1,
      propertyTypeCode: this.homeForm.get('propertyTypeId')?.value
    };

    this.ppService.getInfo(infoRequest).subscribe({
      next: (state: GetPersonalPropertyInfoView) => {
        this.zone.run(() => {
          this.constructionTypes = state.constructionTypes;
          this.homeForm.get('constructionTypeId').enable();
        });
      }
    });
  }

  formIsValid() {
    let hasAddress = false;
    if (this.address) {
      hasAddress = !Object.values(this.address).every(o => o === null);
    }
    const propertyHasValue = !!this.homeForm.get('propertyTypeId').value;
    const constructionHasValue = !!this.homeForm.get('constructionTypeId').value;
    const addressFieldHasValue = !!this.homeForm.get('address').value && this.homeForm.get('address').value !== ' ';

    return hasAddress && propertyHasValue && constructionHasValue && addressFieldHasValue;
  }
}
