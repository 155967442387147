<div class="modal-header">
    <h4 class="modal-title pull-left">Privacy Policy</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRefPrivatePolicy.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
</div>
<div class="modal-body">
    <b>Privacy of Non-Public Financial Information</b>
    <br>
    Deposit Choice, LLC represents licensed insurers and through its producers requests and obtains from consumers and
    customers nonpublic personal financial information, as defined below ("Nonpublic Personal Financial Information"),
    for use in connection with providing certain insurance products.
    It is understood Nonpublic Personal Financial Information includes personally identifiable financial information and
    any list, description or other grouping of consumers, and publicly available information pertaining to them, that is
    derived using any personally identifiable financial information that is not publicly available. "Personally
    identifiable financial Information" means any information a consumer provides Deposit Choice, LLC to obtain a
    financial product or service, information otherwise obtained about a consumer in connection with providing a
    financial product or service to that consumer, and any information about a consumer resulting from any transaction
    involving a financial product or service between Deposit Choice, LLC and a consumer. Personally identifiable
    financial information includes a consumer's name, address, zip code, email address, phone number, credit history,
    social security number, driver's license number, date of birth and any other information that itself identifies, or
    when tied to the above information, may identify a consumer.
    Pursuant to the Gramm-Leach-Bliley Act of 1999 (the "GLB Act") and state insurance laws and regulations promulgated
    thereunder requiring that financial institutions develop policies to protect the confidentiality of Nonpublic
    Personal Financial Information, Deposit Choice, LLC has prepared a privacy notice regarding its privacy practices,
    which may be amended from time to time in accordance with the GLB Act and the state insurance laws and regulations
    of those states in which products are sold (the "Privacy Notice"). The Privacy Notice is provided to consumers and
    states that (1) Deposit Choice, LLC does not disclose any Nonpublic Personal Financial Information to any third
    party except as set forth in the Privacy Notice; and (2) the Privacy Notice does not provide the consumer an
    opportunity to opt out of disclosure of their Nonpublic Personal Financial Information for purposes of the GLB Act.
    The Privacy Notice is sent to all insureds at the inception of their insurance policy and annually thereafter as
    long as the insurance policy remains in force. Deposit Choice, LLC will not disclose or use Nonpublic Personal
    Financial Information obtained other than in connection with the duties and obligations set forth in the insurance
    contract and in connection with servicing and processing a product that a consumer requests or authorizes in the
    ordinary course of business and except as otherwise set forth in the Privacy Notice or as otherwise provided by
    applicable federal and state laws and regulations.
    <br><br>
    <b>Trademark and Copyright </b>
    <br>
    Deposit Choice, LLC is an official Trademark of Deposit Choice, LLC. The content at this site, including copyrighted
    materials, service marks, trademarks and trade names, are owned by Deposit Choice, LLC unless specifically labeled
    to the contrary. The unauthorized use of any materials from this site is prohibited. You may print or download
    information for your own personal or non-commercial use provided that copyright, trademark or other legal notices
    are not removed. Any other modification or use of these materials on this site is a violation of the proprietary
    rights of Deposit Choice, LLC.

</div>