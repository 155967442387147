import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { equals, keys, path } from 'ramda';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WithParamsGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const optional: string[] = route.data.optionalParams;
    const required: string[] = route.data.requiredParams;
    return this.hasParams(required, optional);
  }

  hasParams(params: string[], optionals?: string[]) {
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      let state = path(['extras', 'state'])(navigation);
      if (state) {
        state = keys(state).sort();
        params = params.sort();
        optionals = optionals?.sort();
        let mixed = [];
        if (optionals) mixed = [...params, ...optionals].sort();
        if (
          equals(params, state) ||
          equals(optionals, state) ||
          equals(mixed, state)
        )
          return true;
      }
    }
    this.router.navigate(['/']);
    return false;
  }
}
