<div class="Rectangle">
  <div class="Bond" *ngIf="!isMobile">
    RENTER'S INSURANCE COVERAGE APPLICATION
  </div>
  <div id="Property">
    <div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <label class="BondTitle">Address:</label>
        <label class="BondText"
          >&nbsp;{{ getAddressView?.address1 }}&nbsp;
          {{ getAddressView?.address2 }}</label
        >
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <label class="BondTitle">City & State:</label>
        <label class="BondText"
          >&nbsp;{{ getAddressView?.city }},&nbsp;{{
            getAddressView?.stateCode
          }}</label
        >
      </div>
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <label class="BondTitle">Zip Code:</label>
        <label class="BondText">&nbsp;{{ getAddressView?.zipCode }}</label>
      </div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <label
        for="Address"
        class="ChangeAddress"
        (click)="ValidationExit('home')"
      >
        Change Address
      </label>
    </div>
  </div>
  <div id="Coverage" *ngIf="BondCoverage">
    <div class="LineBond"></div>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <label for="CA" class="CoverageTitle">Property Insurance Policy*:</label>
      <label for="CAV" class="CoverageText">{{
        this.paymentPlan?.totalInsurableReplCostAmt
          | currency: 'USD':'symbol':'.0-0'
      }}</label>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <label for="P" class="PremiumTitle">PREMIUM (what you pay): </label>
      <label for="PV" class="PremiumText">{{
        this.paymentPlan?.fullTermAmt | currency: 'USD':'symbol':'.0-0'
      }}</label>
    </div>
  </div>

  <div id="Owner" *ngIf="this.Owner.FirstName">
    <div class="LineBond"></div>

    <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="Owner">
      <label for="Bond" class="BondTitle">Policy Holder</label>
      <label for="BondValue" class="BondText"
        >{{ this.Owner.FirstName }} {{ this.Owner.LastName }}</label
      >
    </div>

    <div
      *ngIf="Owner.Address"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <label for="Bond" class="BondTitle">Address:</label>
      <label for="BondValue" class="BondText"
        >{{ this.Owner?.Address?.street }} Unit
        {{ this.unitsForAddress.unitNumber }}, {{ this.Owner?.Address?.city }}
        {{ this.Owner?.Address?.stateCode }}
        {{ this.Owner?.Address?.zipcode }}
      </label>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-between center">
      <label for="Bond" class="BondTitle">Move-in Date</label>
      <label for="BondValue" class="BondText"
        >{{ this.Owner.MoveOnDate | date }}
      </label>
    </div>
  </div>

  <div id="AdditionalOcupants" *ngIf="this.Occupants.occupants_form">
    <div class="LineBond"></div>

    <div fxLayout="column" fxLayoutAlign="space-around start">
      <label for="Bond" class="OccupantsTitle">Additional Occupants</label>
      <div
        *ngFor="let Occupant of Occupants.occupants_form"
        class="DivMaxHeight"
      >
        <label for="BondValue" class="TextOccupants">
          {{ Occupant.firstName }} {{ Occupant.lastName }}
        </label>
      </div>
    </div>
  </div>

  <div id="PaymentInformation" *ngIf="this.Payment.CardType">
    <div class="LineBond"></div>

    <div fxLayout="column" fxLayoutAlign="space-around start">
      <label for="Bond" class="BondTitle">Payment Information</label>
      <label for="BondValue" class="BondText">{{ AddressFullName }}</label>
      <label for="BondValue" class="BondText"
        >{{ this.Payment.CardType }} {{ LastCardNumber }}</label
      >
      <label for="BondValue" class="BondText"
        >{{ AddressStreet }}, {{ AddressCity }} {{ AddressState }}
        {{ AddressZipCode }}</label
      >
    </div>
  </div>

  <div style="color: black">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="PaymentPlaHeader">Payment Plan:</div>
      <div>{{ PaymentPlanAmountResponse?.paymentPlanType }}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="PaymentPlaHeader">Number of Installments:</div>
      <div>{{ PaymentPlanAmountResponse?.paymentPlanId }}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="PaymentPlaHeader">Payment Amount:</div>
      <div>{{ PaymentPlanAmountResponse?.paymentAmount | currency }}</div>
    </div>
    <div
      *ngIf="ContentsAmountResponse?.stateSurcharge"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="PaymentPlaHeader">
        State Surcharge ({{
          ContentsAmountResponse?.stateSurchargeDescription
        }}):
      </div>
      <div>{{ ContentsAmountResponse?.stateSurchargeAmount | currency }}</div>
    </div>
    <div
      *ngIf="ContentsAmountResponse?.citySurcharge"
      fxLayout="row"
      fxLayoutAlign="space-between center"
    >
      <div class="PaymentPlaHeader">
        City Surcharge ({{ ContentsAmountResponse?.citySurchargeDescription }}):
      </div>
      <div>{{ ContentsAmountResponse?.citySurchargeAmount | currency }}</div>
    </div>
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div class="PaymentPlaHeader">Total Premium:</div>
      <div>
        {{
          PaymentPlanAmountResponse?.paymentPlanId *
            PaymentPlanAmountResponse?.paymentAmount | currency
        }}
      </div>
    </div>
  </div>
</div>
