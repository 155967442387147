import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PayPolicyRequest } from 'src/model/PaymentService/PayPolicyRequest';
import { PayPolicyResponse } from 'src/model/PaymentService/PayPolicyResponse';
import { PaymentInfoRequest } from 'src/model/PaymentService/PaymentInfoRequest';
import { PaymentInfoResponse } from 'src/model/PaymentService/PaymentInfoResponse';
import { TokenRequest } from 'src/model/PaymentService/TokenRequest';
import { TokenResponse } from 'src/model/PaymentService/TokenResponse';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  API_URL: any = environment.PaymentURL;
  TOKEN: any = environment.PaymentToken;
  headersIMS = new HttpHeaders({
    'Authorization': 'Bearer ' + this.TOKEN
  });

  constructor(private http: HttpClient) { }

  async postGenerateToken(tokenRequest: TokenRequest){
    const baseURL = this.API_URL + "Payment/GenerateCreditCardToken"; 
    return await this.http.post<TokenResponse>(baseURL, tokenRequest, { headers: this.headersIMS, reportProgress: false }).toPromise();
  }

  async postPayPolicy(payPolicyRequest: PayPolicyRequest) {
    const baseURL = this.API_URL + "Payment/PayPolicy"; 
    return await this.http.post<PayPolicyResponse>(baseURL, payPolicyRequest, { headers: this.headersIMS, reportProgress: false }).toPromise();
  }

  async postPaymentToIMS(paymentToIMS : PaymentInfoRequest){
    const baseURL = this.API_URL + "Payment/PostPaymentToIMS";
    return await this.http.post<PaymentInfoResponse>(baseURL, paymentToIMS, { headers: this.headersIMS, reportProgress: false }).toPromise();
  }
}
