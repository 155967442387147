import { environment } from './../../../../environments/environment';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BondCoverage } from 'src/model/BondCoverage';
import { AddressService } from 'src/app/shared/services/address/address.service';
import { ProtectionService } from 'src/app/shared/services/protection/protection.service';
import { BondCoverageGlobal } from 'src/model/BondCoverageGlobal';
import { BondCoverageService } from 'src/app/shared/services/bondCoverage/bond-coverage.service';
import { Owner } from 'src/model/Owner';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LiabilityAmountResponse } from 'src/model/Protection/LiabilityAmountResponse';
import IMask from 'imask/esm/imask';

// Media Observer
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Observable } from 'rxjs';

// FontAwesome
import { faCaretRight, faCaretDown } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-purchase-general',
  templateUrl: './purchase-general.component.html',
  styleUrls: ['./purchase-general.component.scss']
})
export class PurchaseGeneralComponent implements OnInit {

  getAddressView: any;
  paymentPlan: any;

  coverageAmount: BondCoverage[] = [];
  bondSelected = new BondCoverage();

  bondCoverage = new BondCoverage();

  selectedOption: number;

  bondCoverageGlobal = new BondCoverageGlobal();

  owner = new Owner();

  minDate: Date;
  maxDate: Date;

  contentsAmountView: any;

  public selectedAmount : any;
  public liabilityResponsemodel : LiabilityAmountResponse;
  public liabilityAmountResponse: LiabilityAmountResponse[] = [];
  public contentsAmountResponse: any[] = [];

  @Output() rateId = new EventEmitter<number>();

  // Email
  General = new FormGroup({
    CoverageOptionControl: new FormControl('', Validators.required),
    FirstNameControl: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
    LastNameControl: new FormControl('', [Validators.required, Validators.pattern('[a-zA-Z ]*')]),
    EmailControl: new FormControl('', [Validators.required,
      Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]),
    MoveInDateControl: new FormControl('', Validators.required),
    PrincipalPhone: new FormControl('', Validators.minLength(12))
  });

  get CoverageOptionControl() {
    return this.General.get('CoverageOptionControl');
  }
  get FirstNameControl() {
    return this.General.get('FirstNameControl');
  }

  get LastNameControl() {
    return this.General.get('LastNameControl');
  }

  get EmailControl() {
    return this.General.get('EmailControl');
  }

  get MoveInDateControl() {
    return this.General.get('MoveInDateControl');
  }

  get PrincipalPhone() {
    return this.General.get('PrincipalPhone');
  }

  unitForAddress : any;

  // Media Observer
  public media$: Observable<MediaChange[]>;
  public isMobile = false;

  expand = faCaretDown;
  close = faCaretRight;
  togglerIcon = faCaretDown;
  collapseDisclaimer = true;

  firstNameMask = new IMask.MaskedRegExp({
    mask: /^[a-zA-Z\s]*$/
  });

  lastNameMask = new IMask.MaskedRegExp({
    mask: /^[a-zA-Z\s]*$/
  });


  constructor(private router: Router,
              private addressService: AddressService,
              private protectionService: ProtectionService,
              private bondCoverageService: BondCoverageService,
              public media: MediaObserver) {
    this.minDate = new Date();
    this.maxDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth() + 6, this.minDate.getDate());
    this.media$ = media.asObservable();
  }

  ngOnInit() {
    this.media$.subscribe(
      (mq) => {
        this.isMobile = ('xs' === mq[0].mqAlias);
      }
    );
    this.getAddressView = this.addressService.getAddress();
    this.unitForAddress = this.addressService.getUnitForAddressLocal();
    this.bondCoverageService.PaymentPlant$.subscribe(paymentplan => {
      this.paymentPlan = paymentplan ;
    });
    this.LoadAmounts();

    this.ValidationBondCoverage();
  }

  LoadAmounts() {
    var contentsAmountInput : any = {
      unitId : this.unitForAddress?.unitId,
      stateCode : this.getAddressView?.stateCode,
      useStillWater: true
    };
    if(this.unitForAddress){
    }
  }

  ChangeComboAmount() {
    this.selectedAmount = this.contentsAmountView.contentsAmounts.find(amount => amount.contentsId == this.CoverageOptionControl.value);
    this.protectionService.emiterContentsAmount(this.selectedAmount);
    this.rateId.emit(this.selectedAmount.rateId);
    this.bondCoverageService.ContentsAmountResponse$.emit(this.selectedAmount);
  }

  ValidationBondCoverage() {
    if (this.bondCoverage) {
      this.setbondCoverage();
    }
  }

  setbondCoverage() {
    this.bondCoverageService.BondCoverage$.emit(this.bondCoverage);
  }

  ValidationOwner() {
    if (this.General.status === 'VALID' && this.getAddressView) {
      this.setOwner();
    }
  }

  ValidationExit(NamePage: string) {
    if (this.getAddressView) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The changes will be lost!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#046a38',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, exit!',
        scrollbarPadding: false
      }).then((result) => {
        if (result.value) {
          this.addressService.deleteAddress();
          this.router.navigate(['/' + NamePage]);
        }
      });
    } else {
      this.router.navigate(['/' + NamePage]);
    }
  }

  setOwner() {
    this.owner.FirstName = this.General.controls.FirstNameControl.value;
    this.owner.LastName = this.General.controls.LastNameControl.value;
    this.owner.Email = this.General.controls.EmailControl.value;
    this.owner.MoveOnDate = this.General.controls.MoveInDateControl.value;
    this.owner.Address ={
      street : this.getAddressView.address1,
      city :  this.getAddressView.city,
      stateCode : this.getAddressView.stateCode,
      zipcode : this.getAddressView.zipCode
    }
    this.owner.PrincipalPhone = this.General.controls.PrincipalPhone.value;

    this.bondCoverageService.Owner$.emit(this.owner);
  }

  toggleDisclaimer(): void {
    this.collapseDisclaimer = !this.collapseDisclaimer;

    if (this.collapseDisclaimer) {
      this.togglerIcon = this.expand;
    } else {
      this.togglerIcon = this.close;
    }
  }
}
