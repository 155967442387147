<nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top"   fxLayout="column">
  <div class="nav-container">
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarTogglerDemo03"
      aria-controls="navbarTogglerDemo03"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <a class="navbar-brand" (click)="ValidationExit('home')">
      <img src="assets/images/header/pc-logo.svg" alt="Protection-Choice" />
    </a>

    <div
      class="collapse navbar-collapse justify-content-end"
      id="navbarTogglerDemo03"
      *ngIf="currentComponent === '/' || currentComponent === '/home'"
    >
      <ul class="nav justify-content-center">
        <li class="nav-item HeaderTitle">
          <a
            class="nav-link"
            [routerLink]="[]"
            (click)="NavigateHome('DivProtection')"
            routerLinkActive="active"
            >Home</a
          >
        </li>
        <li class="nav-item HeaderTitle">
          <a
            class="nav-link"
            [routerLink]="[]"
            (click)="NavigateHome('DivAbout')"
            routerLinkActive="active"
            >About</a
          >
        </li>
        <li class="nav-item HeaderTitle">
          <a
            class="nav-link"
            [routerLink]="[]"
            (click)="NavigateHome('DivRenters')"
            routerLinkActive="active"
            >Renters/Owners</a
          >
        </li>
        <!-- <li class="nav-item HeaderTitle">
                <a class="nav-link purchaseBond" [routerLink]="[]" (click)="ValidationLogin()">Log In <img class="user-icon" src="\assets\images\header\user-solid.svg" alt="User" height="16"></a>
            </li> -->
      </ul>
    </div>
  </div>
  <app-banner-quote-generated class="banner" 
  *ngIf="currentComponent !== '/' && currentComponent !== '/home'">
  </app-banner-quote-generated>
</nav>
