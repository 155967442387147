<div id="DivHeader" class="fixed-top">
    <div *ngIf="!isMobile; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <nav class="navbar navbar-expand-lg navbar-light bg-white fixed-top">        
            <span class="navbar-brand">
                <img src="assets/images/header/pc-logo.svg" alt="Protection-Choice">
            </span>
        </nav>
    </ng-template>
    <ng-template #elseBlock>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="15px" style="background-color: #02703C; color: white; padding: 7px;">
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <img src="assets\images\purchase\PC-logo.svg" alt="Protection-Choice" height="30px">
    
                <div>
                    <div><b style="font-size: 18px;">Property Insurance<br>Policy</b></div>
                    <div class="text-center"><b>{{ paymentPlanAmountResponse?.paymentAmount | currency }}</b> / {{ paymentPlanAmountResponse?.paymentPlanType }}</div>
                </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="space-around stretch">
                <a data-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" class="Details text-center" (click)="toggleSummary()">
                    Policy Details
                    <fa-icon [icon]="togglerIcon"></fa-icon>
                </a>
                <div class="collapse" id="collapseExample">
                    <div class="card card-body" style="padding: 10px;border: 3px solid rgb(2, 112, 60);">
                        <app-purchase-bond-coverage></app-purchase-bond-coverage>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>

<div class="layout" fxLayout="row" fxLayoutAlign="space-around stretch" fxLayoutGap="15px" fxFill style="padding: 15px;">
    <div fxFlex="60%" fxFlex.xs="100%">
        <app-purchase-general (rateId)="onRatedIdChange($event)"></app-purchase-general>
        <app-purchase-occupants></app-purchase-occupants>
        <app-payment-options [ratedId]="ratedId"></app-payment-options>
        <app-purchase-payment></app-purchase-payment>
        <app-purchase-billing></app-purchase-billing>

        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="15px" style="margin: 10px">
            <button class="btnBack" (click)="ValidationExit('home')">Back</button>
            <button class="btnNext" (click)="Purchase()">Purchase Policy</button>
        </div>

        <div class="Text" style="padding: 15px;">
            This page provides a general description of the insurance policy and does not constitute a
            policy contract. Your policy will contain a complete list of terms, conditions, limitations,
            and exclusions.
            <br>
            To enroll online, simply complete the online enrollment form and submit
            it to us through our secure link. An automatic confirmation of coverage will be emailed to
            you and the leasing office will be immediately notified of your enrollment. To purchase by
            telephone, you may call our Customer Service Center toll-free at (888) 219-9112.
        </div>
    </div>

    <div fxHide.lt-md="true" fxFlex="25%">
        <app-purchase-bond-coverage></app-purchase-bond-coverage>
    </div>
</div>

<div fxLayout="column" fxLayoutAlign="space-around center" class="movile-footer" style="background-color: rgb(2, 112, 60);;" *ngIf="isMobile">
    <button class="btnNext" (click)="Purchase()">Purchase Policy</button>
</div>

