import { InjectionToken } from '@angular/core';

export const CONTAINER_DATA = new InjectionToken<any>('CONTAINER_DATA');

/* eslint-disable max-len */
export const retrofittingTooltip = `Dwellings built prior to 1973 must meet the following requirements in order to qualify for retrofitting discount:

    a. The dwelling is properly anchor bolted to the foundation; and

    b. Cripple walls if present are braced with plywood or its equivalent; and

    c. The hot water heater is secured to the building frame

  An engineering report must be submitted for verification prior to binding.`;
export const foundationDescriptionsTooltip = `Basement - An additional floor partially or completely below ground and built with poured concrete walls.

  Cripple Wall - Short wood stud walls that enclose a crawl space under the first floor.

  Raised Foundation - Main floor of structure is raised above the plane of the surrounding earth. Foundation stemwall and footing support exterior walls with the middle portion supported on posts and piers.

  Slab - Concrete poured over a prepared surface of soil or gravel upon which the structure is built.

  Solid Perimeter - Continuous foundation and footing that support exterior building walls. Isolated piers provide interior support.

  Stilts and Pilings - Piers set into the ground with beams extending from pier to pier.`;

export const homeRemplacementTooltip =
  'The cost at the time of loss to repair or replace the dwelling using material of like kind and quality without deduction for depreciation.';
export const otherStructuresTooltip =
  'Pays to repair or rebuild other structures on your property, like a detached garage or shed. Damage must be caused by a covered loss.';
export const personalPropertyTooltip =
  'Personal belongings refers to all of your belongings and household goods.';
export const lossOfUserTooltip =
  'This covers the necessary increase in living expenses incurred by you so that your household can maintain its normal standard of living when you are unable to live in your home as a result of a covered loss. Payment will be for the shortest time required to repair or replace the damage, or if you permanently relocate, the shortest time required for your household to settle elsewhere. Time limits apply. Refer to your policy documents for complete details.';
export const lossAssessmentTooltip =
  'This provides coverage for loss assessment charged by a corporation or association of property owners or condominium associations because of a “covered event” during the policy period. Our Dwelling policy includes $10,000 coverage. Our Condominium Unit Owners policy includes $5,000 coverage.';
export const deductibleTooltip =
  'This is the amount your loss must exceed in order for your policy to begin paying for covered losses provided by this policy. Refer to your policy documents for complete details.';
