import { NgModule } from '@angular/core';

import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  exports: [
    MatStepperModule
  ]
})
export class MaterialModule { }
