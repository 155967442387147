<div class="container last-section-padding">
    <div class="row m-0">
      <div class="col">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div>
            <img src="assets/images/home/user-tie-solid.svg" height="51.2" width="44.8">
          </div>
          <div class="Title">
            Experienced
          </div>
          <div class="GreenLine"></div>
          <br>
          <div class="Text">
            Our team of professionals have decades
            of experience working with rental communities, management companies, property owners, and their
            residents.
          </div>
        </div>
      </div>
      <div class="col">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <img src="assets/images/home/balance-scale-solid.svg" height="51.2" width="44.8">
          <div class="Title">
            Stable
          </div>
          <div class="GreenLine">
  
          </div>
          <br>
          <div class="Text">
            Our insurance partners serve customers throughout the United States, and were selected based on
            their history of delivering innovative solutions.
          </div>
        </div>
      </div>
    </div>
    <div class="row m-0">
      <br><br><br><br>
    </div>
    <div class="row m-0">
      <div class="col">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div>
            <img src="assets/images/home/check-solid.svg" height="51.2" width="44.8">
          </div>
          <div class="Title">
            Committed
          </div>
          <div class="GreenLine">
  
          </div>
          <br>
          <div class="Text">
            Protection Choice, LLC is a national provider of surety bonds for the multifamily industry. Our
            singular focus is on
            serving the specific needs of renters
            and property managers.
          </div>
        </div>
      </div>
      <div class="col">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div>
            <img src="assets/images/home/puzzle-piece-solid.svg" height="51.2" width="44.8">
          </div>
          <div class="Title">
            Dedicated
          </div>
          <div class="GreenLine">
  
          </div>
          <br>
          <div class="Text">
            As a member of the BetterLeasing
            group, Protection Choice is dedicated
            to offering quality deposit alternative products supported by professional customer service.
          </div>
        </div>
      </div>
    </div>
  </div>