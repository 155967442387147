import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stepper-buttons',
  templateUrl: './stepper-buttons.component.html',
  styleUrls: ['./stepper-buttons.component.scss']
})
export class StepperButtonsComponent {
  @Input() backPath?: string;
  @Input() backParams?: any;
  @Input() nextPath?: string;
  @Input() nextParams?: any;
  @Input() disableNext?: boolean;

  @Output() beforeBack = new EventEmitter();
  @Output() onNext = new EventEmitter();
  @Input() handleNext?: boolean;

  constructor(public router: Router) {}

  back() {
    this.beforeBack.emit();
    setTimeout(
      () => this.router.navigate([this.backPath], { state: this.backParams }),
      1500
    );
  }

  next() {
    if (this.handleNext) this.onNext.emit();
    else this.router.navigate([this.nextPath], { state: this.nextParams });
  }
}
