import { ProcessService } from './shared/services/process/process.service';
import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IMaskModule } from 'angular-imask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { ApiServicesModule } from './../services/api.services.module';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomeAboutComponent } from './components/home/home-about/home-about.component';
import { HomeAddressComponent } from './components/home/home-address/home-address.component';
import { HomeAutocompleteComponent } from './components/home/home-autocomplete/home-autocomplete.component';
import { HomeFeaturesComponent } from './components/home/home-features/home-features.component';
import { HomeProtectionComponent } from './components/home/home-protection/home-protection.component';
import { HomeRentersOwnersComponent } from './components/home/home-renters-owners/home-renters-owners.component';
import { HomeComponent } from './components/home/home.component';
import { InsuredInformationComponent } from './components/insured-information/insured-information.component';
import { YourInfoComponent } from './components/insured-information/your-info/your-info.component';
import { YourNameComponent } from './components/insured-information/your-name/your-name.component';
import { AdditionalInterestComponent } from './components/insured-information/additional-interest/additional-interest.component';
import { MakePaymentComponent } from './components/make-payment/make-payment.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentOptionsComponent } from './components/purchase/payment-options/payment-options.component';
import { PurchaseBillingComponent } from './components/purchase/purchase-billing/purchase-billing.component';
import { PurchaseBondCoverageComponent } from './components/purchase/purchase-bond-coverage/purchase-bond-coverage.component';
import { PurchaseGeneralComponent } from './components/purchase/purchase-general/purchase-general.component';
import { PurchaseOccupantsComponent } from './components/purchase/purchase-occupants/purchase-occupants.component';
import { PurchasePaymentComponent } from './components/purchase/purchase-payment/purchase-payment.component';
import { PurchaseThankYouComponent } from './components/purchase/purchase-thank-you/purchase-thank-you.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { UnderwritingComponent } from './components/underwriting/underwriting.component';
import { CrippleWallsTooltipComponent } from './shared/components/cripple-walls-tooltip/cripple-walls-tooltip.component';
import { QuestionsDialogComponent } from './shared/components/questions-dialog/questions-dialog.component';
import { StepperButtonsComponent } from './shared/components/stepper-buttons/stepper-buttons.component';
import { StringTooltipComponent } from './shared/components/string-tooltip/string-tooltip.component';
import { TuckTooltipComponent } from './shared/components/tuck-tooltip/tuck-tooltip.component';
import { CustomTooltipDirective } from './shared/directives/customTooltip.directive';
import { PhoneMaskDirective } from './shared/directives/phone-mask.directive';
import { HttpErrorInterceptor } from './shared/interceptor/http-error.interceptor';
import { BannerQuoteGeneratedComponent } from './shared/components/bannerQuoteGenerated/banner-quote-generated/banner-quote-generated.component';
import { PaymentValidationComponent } from './shared/modal/payment-validation/payment-validation.component';
import { PrivacyPolicyComponent } from './shared/modal/privacy-policy/privacy-policy.component';
import { SignatureValidationComponent } from './shared/modal/signature-validation/signature-validation.component';
import { LogService } from './shared/services/log/log.service';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { NgxCurrencyModule } from 'ngx-currency';
import { SharedModule } from './shared/shared.module';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
export function init_app(appInitService: AppInitService) {
  return () => appInitService.getEnvRoute();
}

@NgModule({
  declarations: [
    AppComponent,
    CrippleWallsTooltipComponent,
    CustomTooltipDirective,
    FooterComponent,
    HeaderComponent,
    HomeAboutComponent,
    HomeAddressComponent,
    HomeAutocompleteComponent,
    HomeComponent,
    HomeFeaturesComponent,
    HomeProtectionComponent,
    HomeRentersOwnersComponent,
    InsuredInformationComponent,
    MakePaymentComponent,
    PaymentOptionsComponent,
    PaymentValidationComponent,
    PhoneMaskDirective,
    PrivacyPolicyComponent,
    PurchaseBillingComponent,
    PurchaseBondCoverageComponent,
    PurchaseComponent,
    PurchaseGeneralComponent,
    PurchaseOccupantsComponent,
    PurchasePaymentComponent,
    PurchaseThankYouComponent,
    SignatureValidationComponent,
    StringTooltipComponent,
    StepperButtonsComponent,
    TuckTooltipComponent,
    UnderwritingComponent,
    YourInfoComponent,
    YourNameComponent,
    PaymentValidationComponent,
    PrivacyPolicyComponent,
    HomeAddressComponent,
    PhoneMaskDirective,
    PaymentComponent,
    QuestionsDialogComponent,
    BannerQuoteGeneratedComponent,
    AdditionalInterestComponent,
  ],
  imports: [
    ApiServicesModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    BsDatepickerModule.forRoot(),
    FlexLayoutModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    IMaskModule,
    NgxCurrencyModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG,
      serverLogLevel: NgxLoggerLevel.ERROR
    }),
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatExpansionModule,
    ModalModule.forRoot(),
    NgxDropzoneModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    SharedModule
  ],
  providers: [
    DatePipe,
    AppInitService,
    ProcessService,
    LogService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [AppInitService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    SignatureValidationComponent,
    PaymentValidationComponent,
    PrivacyPolicyComponent
  ]
})
export class AppModule {}
