import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { NGXLogger, LoggerConfig } from 'ngx-logger';

@Injectable()
export class LogService {
  constructor(private logger: NGXLogger, private loggerConfig: LoggerConfig) {
   }

   Log(message:any, level : Level) {
    this.loggerConfig.serverLoggingUrl = environment.ProtectionURL + '/api/Log';
    this.logger.setCustomHttpHeaders(new HttpHeaders({Authorization: 'Bearer ' + environment.ProtectionToken}));
    switch(level){
      case Level.DEBUG:
        this.logger.debug(message);
        break;
      case Level.INFO:
        this.logger.info(message);
        break
      case Level.LOG:
        this.logger.log(message);
        break;
      case Level.WARN:
        this.logger.warn(message);
        break;
      case Level.ERROR:
        this.logger.error(message);
        break;
      case Level.FATAL:
        this.logger.fatal(message);
        break;
    }
  }
}


export enum Level  {
  DEBUG = 1,
  INFO = 2,
  LOG = 3,
  WARN = 4,
  ERROR = 5,
  FATAL = 6
}
