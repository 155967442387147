import { Component, OnInit } from '@angular/core';
import { Occupant } from 'src/model/Occupant';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { BondCoverageService } from 'src/app/shared/services/bondCoverage/bond-coverage.service';

@Component({
  selector: 'app-purchase-occupants',
  templateUrl: './purchase-occupants.component.html',
  styleUrls: ['./purchase-occupants.component.scss']
})
export class PurchaseOccupantsComponent implements OnInit {

  occupants: Occupant;
  dynamicForm: FormGroup;

  // convenience getters for easy access to form fields
  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.occupants_form as any; }

  OneRow = true;
  FiveRow = false;

  i: number;

  disableButtons: Boolean = false;

  constructor(private formBuilder: FormBuilder, private bondCoverageService: BondCoverageService) { }

  ngOnInit() {
    this.dynamicForm = this.formBuilder.group({
      occupants_form: new FormArray([])
    });
  }

  addOccupant() {
    if (this.t.controls.length < 5) {
      this.t.push(this.formBuilder.group({
        occupants_form: new FormArray([]),
        firstName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        lastName: ['', [Validators.required, Validators.pattern('[a-zA-Z ]*')]],
        email: ['', [Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]]
      }));

      this.OneRow = false;
    }

    if (this.t.controls.length === 5) {
      this.FiveRow = true;
    }
  }

  removeOccupant(i: number) {
    if (this.t.controls.length <= 1) {
      this.OneRow = true;
    }
    this.t.removeAt(this.t.controls.length - 1);

    if (this.t.controls.length !== 5) {
      this.FiveRow = false;
    }

    this.ValidationOcupants();

  }

  ValidationOcupants() {
    if (this.dynamicForm.controls.occupants_form.status === "VALID") {
      this.setOccupants()
    }
  }

  setOccupants() {
    this.occupants = this.dynamicForm.value;

    this.bondCoverageService.Occupants$.emit(this.occupants);
  }
}
