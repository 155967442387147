export class Surety{
    QuoteGUID: string;
    UnitId: number;
    Premium: number;
    CoverageAmount: number;
    BondSigned: boolean;
    LineGUID: string;
    PaymentPlanId: number;
    ContentsAmount: number;
    LiabilityAmount: number;
    DeductibleAmount: number;
    PaymentPlanAmount: number;
}