import { Injectable, EventEmitter } from '@angular/core';
import { BondCoverageGlobal } from 'src/model/BondCoverageGlobal'
import { BondCoverage } from 'src/model/BondCoverage';
import { Property } from 'src/model/Property';
import { Owner } from 'src/model/Owner';
import { Occupant } from 'src/model/Occupant';
import { Payment } from 'src/model/Payment';
import { BillingAddress } from 'src/model/BillingAddress';

@Injectable({
  providedIn: 'root'
})
export class BondCoverageService {

  bondCoverageGlobalService = new BondCoverageGlobal();
  bond$ = new EventEmitter<BondCoverageGlobal>();
  Property$ = new EventEmitter<Property>();
  BondCoverage$ = new EventEmitter<BondCoverage>();
  Owner$ = new EventEmitter<Owner>();
  Occupants$ = new EventEmitter<Occupant>();
  Payment$ = new EventEmitter<Payment>();
  BillingAddress$ = new EventEmitter<BillingAddress>();
  ContentsAmountResponse$ = new EventEmitter<any>();
  PaymentPlant$ = new EventEmitter<any>();
  selectedPaymentPlanId$ = new EventEmitter<string>();

  constructor() { }

}
