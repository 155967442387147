<app-header></app-header>
<div class="layout" fxLayout="column" fxLayoutAlign="space-between center">
  <div class="container">
    <img src="assets/images/payment/success.gif" alt="success" />
    <h1>
      You have successfully purchased your Renters Insurance Policy, policy
      number: {{ policyNumber }}
    </h1>
    <div
      fxLayout="column"
      fxLayoutAlign="space-between center"
      class="container links"
    >
      <strong class="title-doc-dw">Documents available for download:</strong>
      <a target="_blank" [href]="decPage"
        ><label>View Policy Declarations Page</label>
        <mat-icon svgIcon="cpp-download-icon"></mat-icon
      ></a>
      <a target="_blank" [href]="acordApp"
        ><label>Application / Underwriting Questions</label>
        <mat-icon svgIcon="cpp-download-icon"></mat-icon
      ></a>
      <a target="_blank" [href]="policyConfirm"
        ><label>Payment Confirmation</label>
        <mat-icon svgIcon="cpp-download-icon"></mat-icon
      ></a>
    </div>
    <button
      mat-raised-button
      color="primary"
      (click)="router.navigate(['/home'])"
    >
      Go back to Protection Choice
    </button>
    <div></div>
  </div>
</div>
