import { Component, OnInit } from '@angular/core';
import { AddressService } from 'src/app/shared/services/address/address.service';
import { Router, RouterEvent } from '@angular/router';
import Swal from 'sweetalert2';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PrivacyPolicyComponent } from 'src/app/shared/modal/privacy-policy/privacy-policy.component';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { utilities } from 'src/app/utilities/utilities';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  phoneNumber = environment.contactPhone;
  faxNumber = environment.contactFax;
  phone = utilities.numberToPhone(this.phoneNumber);
  fax = utilities.numberToPhone(this.faxNumber);
  faxSafe: any;

  getAddressView: any;

  //Modal
  bsModalRefPrivatePolicy: BsModalRef;
  currentComponent: string;

  constructor(
    private addressService: AddressService,
    private router: Router,
    private modalService: BsModalService,
    private sanitizer: DomSanitizer
  ) {
    this.faxSafe = this.sanitizer.bypassSecurityTrustUrl(
      'Fax:' + this.faxNumber
    );
    router.events
      .pipe(filter((e) => e instanceof RouterEvent))
      .subscribe((e: RouterEvent) => {
        this.currentComponent = e.url;
      });
  }

  ngOnInit() {
    this.getAddressView = this.addressService.getAddress();
  }

  AddressValidation() {
    if (!this.getAddressView) {
      Swal.fire({
        text: 'Please enter address to continue',
        icon: 'warning',
        confirmButtonText: 'Continue',
        confirmButtonColor: '#046a38',
        scrollbarPadding: false
      });
    } else {
      this.router.navigate(['/purchase']);
    }
  }

  ValidationExit(NamePage: string) {
    if (this.getAddressView) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'The changes will be lost!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#046a38',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, exit!',
        scrollbarPadding: false
      }).then((result) => {
        if (result.value) {
          this.addressService.deleteAddress();
          this.router.navigate(['/' + NamePage]);
        }
      });
    } else {
      this.router.navigate(['/' + NamePage]);
    }
  }

  NavigateHome(id: string) {
    let el = document.getElementById(id);
    el.scrollIntoView({ behavior: 'smooth' });
  }

  ShowPrivatePolicyModal() {
    this.bsModalRefPrivatePolicy = this.modalService.show(
      PrivacyPolicyComponent
    );
    this.bsModalRefPrivatePolicy.content.closeBtnName = 'Close';
  }
}
