<div class="title">Payment Options</div>

<form #form="ngForm">
    <div *ngIf="!isMobile; then thenBlock else elseBlock"></div>
    <ng-template #thenBlock>
        <div fxLayout="column" fxLayoutAlign="space-around stretch" fxLayoutGap="15px">
            <div div fxLayout="row" fxLayoutAlign="space-around center" fxFill>
                <div fxFlex="16%" class="RowHeader"></div>
                <div fxFlex="16%" class="RowHeader">Payment Plan</div>
                <div fxFlex="16%" class="RowHeader">First Payment</div>
                <div fxFlex="16%" class="RowHeader">Number of Installments</div>
                <div fxFlex="16%" class="RowHeader">Payment Amount</div>
                <div fxFlex="16%" class="RowHeader">Total Premium 
                    <!--<img src="/assets/images/home/info-circle-solid.svg" alt="info" tooltip="Does not include installment fees." height="18">-->
                </div>
            </div>
            <div div fxLayout="row" fxLayoutAlign="space-around center" class="RowRectangle" fxFill *ngIf="paymentPlanAmountResponse">
                <div fxFlex="16%" class="ColHeader">
                    <div class="custom-control custom-radio">
                        <input [disabled]="disableForm" type="radio" id="customRadio1" name="selectedPaymentPlanId" class="custom-control-input" value="0" 
                        [(ngModel)]="selectedPaymentPlanId" (ngModelChange)="onChange($event)" (change)="changeOpt()">
                        <label class="custom-control-label" for="customRadio1"></label>
                    </div>
                </div>
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[0]?.paymentPlanType }}</div> <!-- Payment Plan -->
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[0]?.firstPaymentAmount | currency }}</div> <!-- First Payment -->
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[0]?.paymentPlanId }}</div> <!-- Number of Installments -->
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[0]?.paymentAmount | currency }}</div> <!-- Payment Amount -->
                <div fxFlex="16%" class="ColData">{{ getPaymentPlanView.fullTermAmt | currency}}</div> <!-- Total Premium -->
            </div>
            <div  div fxLayout="row" fxLayoutAlign="space-around center" class="RowRectangle" fxFill *ngIf="paymentPlanAmountResponse && paymentPlanAmountResponse.length > 1">
                <div fxFlex="16%" class="ColHeader">
                    <div class="custom-control custom-radio">
                        <input [disabled]="disableForm" type="radio" id="customRadio2" name="selectedPaymentPlanId" class="custom-control-input" value="1" 
                        [(ngModel)]="selectedPaymentPlanId" (ngModelChange)="onChange($event)" (change)="changeOpt()">
                        <label class="custom-control-label" for="customRadio2"></label>
                    </div>
                </div>
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[1]?.paymentPlanType }}</div>
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[1]?.firstPaymentAmount | currency }}</div>
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[1]?.paymentPlanId }}</div>
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[1]?.paymentAmount | currency }}</div>
                <div fxFlex="16%" class="ColData">{{ getPaymentPlanView.fullTermAmt | currency }}</div>
            </div>
    
            <div div fxLayout="row" fxLayoutAlign="space-around center" class="RowRectangle" fxFill *ngIf="paymentPlanAmountResponse && paymentPlanAmountResponse.length > 1">
                <div fxFlex="16%" class="ColHeader">
                    <div class="custom-control custom-radio">
                        <input [disabled]="disableForm" type="radio" id="customRadio3" name="selectedPaymentPlanId" class="custom-control-input" value="2" 
                        [(ngModel)]="selectedPaymentPlanId" (ngModelChange)="onChange($event)" (change)="changeOpt()">
                        <label class="custom-control-label" for="customRadio3"></label>
                    </div>
                </div>
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[2]?.paymentPlanType }}</div>
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[2]?.firstPaymentAmount | currency }}</div>
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[2]?.paymentPlanId }}</div>
                <div fxFlex="16%" class="ColData">{{ paymentPlanAmountResponse[2]?.paymentAmount | currency }}</div>
                <div fxFlex="16%" class="ColData">{{ getPaymentPlanView.fullTermAmt | currency }}</div>
            </div>
        </div>
    </ng-template>
    <ng-template #elseBlock>
        <div class="RowRectangle p-3" *ngIf="isMobile && paymentPlanAmountResponse" fxLayout="column">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="15px" style="border-bottom: 1px solid gray;">
                <div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio1" name="selectedPaymentPlanId" class="custom-control-input" value="0" 
                        [(ngModel)]="selectedPaymentPlanId" (ngModelChange)="onChange($event)" (change)="changeOpt()">
                        <label class="custom-control-label" for="customRadio1">{{paymentPlanAmountResponse[0]?.paymentPlanType}}</label>
                    </div>
                </div>
                <div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio2" name="selectedPaymentPlanId" class="custom-control-input" value="1" 
                        [(ngModel)]="selectedPaymentPlanId" (ngModelChange)="onChange($event)" (change)="changeOpt()">
                        <label class="custom-control-label" for="customRadio2">{{paymentPlanAmountResponse[1]?.paymentPlanType}}</label>
                    </div>
                </div>
                <div>
                    <div class="custom-control custom-radio">
                        <input type="radio" id="customRadio3" name="selectedPaymentPlanId" class="custom-control-input" value="2" 
                        [(ngModel)]="selectedPaymentPlanId" (ngModelChange)="onChange($event)" (change)="changeOpt()">
                        <label class="custom-control-label" for="customRadio3">{{paymentPlanAmountResponse[2]?.paymentPlanType}}</label>
                    </div>
                </div>
            </div>
    
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>First Payment</div>
                <div>{{ paymentPlanAmountResponse[selectedPaymentPlanId]?.firstPaymentAmount | currency }}</div>
            </div>
    
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>Number of Installments</div>
                <div>{{ paymentPlanAmountResponse[selectedPaymentPlanId]?.paymentPlanId }}</div>
            </div>
    
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>Payment Amount</div>
                <div>{{ paymentPlanAmountResponse[selectedPaymentPlanId]?.paymentAmount | currency }}</div>
            </div>
    
            <div fxLayout="row" fxLayoutAlign="space-between center">
                <div>Total Premium</div>
                <div>{{ getPaymentPlanView.fullTermAmt | currency }}</div>
            </div>
        </div>
    </ng-template>
</form>