<div class="layout">
    
    <div id="DivHeader">
        <app-header></app-header>
    </div>
    
    <div id="DivProtection">
      <app-home-protection></app-home-protection>
    </div>
 
    <div id="DivAbout" class="section-separator">
        <app-home-about></app-home-about>
    </div>

    <div id="DivRenters" class="section-separator">
        <app-home-renters-owners></app-home-renters-owners>
    </div>
     
    <div id="DivAutocomplete">
        <app-home-autocomplete></app-home-autocomplete>
    </div>
</div>