<app-header></app-header>
<div
  class="layout"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
  fxLayoutGap="20px"
>
  <app-stepper></app-stepper>
  <app-your-name
    #yourNameComponent
    (form)="rateName($event, false)"
    [insuredInfo]="insuredInfo"
    (isFormValid)="isValidYourNameForm = $event"
    [isSecondInsuredInfo]="false"
    [previousAddress]="previousAddress"
  ></app-your-name>
  <app-your-info
    #yourInfoComponent
    [personalPropertyLimits]="personalPropertyLimits"
    [personalLiabilityLimits]="personalLiabilityLimits"
    [deductibles]="deductibles"
    [constructionTypes]="constructionTypes"
    [liabilityLimit]="liabilityLimit"
    [premium]="premium"
    [personalPropertyLimit]="rateParams?.rate?.personalPropertyLimit"
    [deductible]="rateParams?.rate?.deductible"
    [constructionTypeId]="constructionTypeId"
    [policyStartDate]="rateParams?.rate?.policyStartDate"
    [address]="address"
    (form)="rateInfo($event)"
    (isFormValid)="isValidYourInfoForm = $event"
    (liabilityLimitChange)="liabilityLimit = $event"
    *ngIf="address"
  ></app-your-info>

  <!-- Additional Interest -->
  <div class="secondary-insured-container card">
    <div class="card-body">
      <label class="checkbox-container">
        Would you like to include an Additional Interest?
        <input
          type="checkbox"
          [(ngModel)]="additionalInterest"
          [ngModelOptions]="{ standalone: true }"
          (change)="IsAdditionalInterestChecked()"
        />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
  <app-additional-interest
    *ngIf="additionalInterest"
    #additionalInterestComponent
    [additionalInfo]="addtionalInterestValues"
    (form)="additionaInfo($event)"
    (isFormValid)="isValidAdditionalInterestForm = $event"
  ></app-additional-interest>

  <!-- Secondary Insured-->
  <div class="secondary-insured-container card">
    <div class="card-body">
      <label class="checkbox-container">
        Would you like to include another named insured on the policy?
        <input
          type="checkbox"
          [(ngModel)]="aditionalInsured"
          [ngModelOptions]="{ standalone: true }"
        />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
  <div *ngIf="aditionalInsured" class="message-container">
    <span>
      <mat-icon aria-hidden="false" aria-label="Info">information</mat-icon>
    </span>
    <span>
      Additional Insured: To ensure that coverage is extended to another
      insured, please include the additional insured over 18 years of age below.
    </span>
  </div>
  <app-your-name
    *ngIf="aditionalInsured"
    #yourNameSecondaryComponent
    (form)="rateName($event, true)"
    [insuredInfo]="secondInsuredInfo"
    (isFormValid)="isValidYourNameSecondaryForm = $event"
    [isSecondInsuredInfo]="true"
  ></app-your-name>

  <!-- Paperless -->
  <div class="card">
    <div class="card-body">
      <label class="checkbox-container">
        Would you like to go green with Paperless Documents Enrollment?
        <input
          type="checkbox"
          [(ngModel)]="paperlessDocuments"
          [ngModelOptions]="{ standalone: true }"
          (change)="changePaperless()"
        />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>

  <div *ngIf="!paperlessDocuments" class="mailing-container">
    <div class="card">
      <div class="card-header">Mailing Address</div>
      <div class="card-body">
        <div
          fxLayout="row"
          fxLayoutAlign="space-between start"
          fxLayoutGap="20px"
          [formGroup]="mailingAddressForm"
        >
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxFlex="calc(40% - 20px)"
          >
            <label class="col-form-label input-label"> Street* </label>
            <input
              formControlName="street"
              [class.is-invalid]="getError('street')"
              type="text"
              class="form-control"
            />
            <div class="invalid-feedback">Street is required.</div>
          </div>
          <!-- City -->
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxFlex="calc(20% - 20px)"
          >
            <label class="col-form-label input-label"> City* </label>
            <input
              formControlName="city"
              [class.is-invalid]="getError('city')"
              type="text"
              class="form-control"
              [imask]="maskCity"
              [unmask]="true"
            />
            <div class="invalid-feedback">City is required.</div>
          </div>
          <!-- state -->
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxFlex="calc(20% - 20px)"
          >
            <label class="col-form-label input-label"> State* </label>
            <input
              formControlName="state"
              [class.is-invalid]="getError('state')"
              type="text"
              class="form-control"
              [imask]="maskState"
              [unmask]="true"
            />
            <div class="invalid-feedback">State is required.</div>
          </div>
          <!-- Zip Code -->
          <div
            fxLayout="column"
            fxLayoutAlign="space-between start"
            fxFlex="calc(20% - 20px)"
          >
            <label class="col-form-label input-label"> Zip Code* </label>
            <input
              formControlName="zipCode"
              [class.is-invalid]="getError('zipCode')"
              type="text"
              class="form-control"
              [imask]="maskZipCode"
              [unmask]="true"
              maxlength="5"
            />
            <div class="invalid-feedback">Zip Code is required.</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="info-container">(*) Indicates input is required.</div>

  <div class="d-flex justify-content-end container-btn">
    <button class="btn btn-primary btn-goback" (click)="goBack()">
      < Go Back
    </button>
    <button
      class="btn btn-primary btn-continue"
      (click)="continueValidation()"
      [disabled]="
        !isValidYourNameForm ||
        !isValidYourInfoForm ||
        (additionalInterest && !isValidAdditionalInterestForm) ||
        (aditionalInsured && !isValidYourNameSecondaryForm) ||
        (!paperlessDocuments && mailingAddressForm?.invalid)
      "
    >
      Continue >
    </button>
  </div>
</div>
