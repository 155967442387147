import {
  Overlay,
  OverlayPositionBuilder,
  OverlayRef
} from '@angular/cdk/overlay';
import {
  ComponentPortal,
  ComponentType,
  PortalInjector,
  TemplatePortal
} from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnInit,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { CONTAINER_DATA } from 'src/app/constants/tooltips';
import { StringTooltipComponent } from '../components/string-tooltip/string-tooltip.component';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[customTooltip]'
})
export class CustomTooltipDirective implements OnInit {
  @Input('customTooltip')
  tooltipContent!: TemplateRef<any> | ComponentType<any> | string;

  private _overlayRef!: OverlayRef;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private viewContainerRef: ViewContainerRef,
    private _injector: Injector
  ) {}

  ngOnInit(): void {
    if (this.tooltipContent) {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'center',
            originY: 'bottom',
            overlayX: 'center',
            overlayY: 'top',
            offsetX: 0,
            offsetY: 8,
            weight: 1
          },
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom',
            offsetX: 0,
            offsetY: -8,
            weight: 1
          }
        ]);

      this._overlayRef = this.overlay.create({
        positionStrategy,
        scrollStrategy: this.overlay.scrollStrategies.close(),
        panelClass: 'custom-tooltip'
      });
    } else {
      console.error('[ERROR] Directive must have something to show');
    }
  }

  createInjector(dataToPass: string): PortalInjector {
    const injectorTokens = new WeakMap();
    injectorTokens.set(CONTAINER_DATA, dataToPass);
    return new PortalInjector(this._injector, injectorTokens);
  }

  @HostListener('mouseenter')
  private _show(): void {
    if (this._overlayRef) {
      let containerPortal: TemplatePortal<any> | ComponentPortal<any>;

      if (this.tooltipContent instanceof TemplateRef) {
        containerPortal = new TemplatePortal(
          this.tooltipContent,
          this.viewContainerRef
        );
      } else if (typeof this.tooltipContent === 'string') {
        containerPortal = new ComponentPortal(
          StringTooltipComponent,
          this.viewContainerRef
        );
      } else {
        containerPortal = new ComponentPortal(
          this.tooltipContent,
          this.viewContainerRef
        );
      }

      const tooltipRef = this._overlayRef.attach(containerPortal);
      if (typeof this.tooltipContent === 'string') {
        tooltipRef.instance.info = this.tooltipContent;
        tooltipRef.changeDetectorRef.detectChanges();
      }
    }
  }

  @HostListener('mouseout')
  private _hide(): void {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
