import { Guid } from 'guid-typescript';

export class Submission{
    InsuredGuid: string
    ProducerContactGuid: string
    ProducerLocationGuid: string
    UnderwriterGuid: string
    TACSRGuid: string
    InHouseProducerGuid: string
    SubmissionDate: Date
}