import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEmpty, isNil } from 'ramda';
import { map } from 'rxjs/operators';
import { CrippleWallsTooltipComponent } from 'src/app/shared/components/cripple-walls-tooltip/cripple-walls-tooltip.component';
import { TuckTooltipComponent } from 'src/app/shared/components/tuck-tooltip/tuck-tooltip.component';
import { AlertService } from 'src/app/shared/services/alert/alert.service';
import { ProgressSpinnerService } from 'src/app/shared/services/progress-spinner/progress-spinner.service';
import { masks } from 'src/app/utilities/masks';
import { StorageService } from 'src/app/shared/services/Storage/StorageService';

import {
  AdditionalInterest,
  ApiService,
  GetQuestionsView,
  Question,
  RateControllerInput,
  RateView
} from 'src/services/generated/api-services';

@UntilDestroy()
@Component({
  selector: 'app-underwriting',
  templateUrl: './underwriting.component.html',
  styleUrls: ['./underwriting.component.scss']
})
export class UnderwritingComponent implements OnDestroy, OnInit {
  crippleWallTooltip = CrippleWallsTooltipComponent;

  ineligbileQuestionIds: string[] = [];
  isNotEligible = false;

  questionsData?: GetQuestionsView;

  tuckTooltip = TuckTooltipComponent;
  underwritingForm: FormGroup;

  informationStep?: any;
  rate?: any;
  paymentStep?: any;
  additionalInterest: AdditionalInterest;
  disableNext = false;
  generateNewQuote = false;

  get questions() {
    return this.underwritingForm.get('questions') as FormArray;
  }

  constructor(
    public activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private formBuilder: FormBuilder,
    private elementRef: ElementRef,
    private progressSpinnerService: ProgressSpinnerService,
    private router: Router,
    private alertService: AlertService,
    private storageService: StorageService
  ) {
    this.underwritingForm = this.formBuilder.group({
      questions: this.formBuilder.array([])
    });
  }

  ngOnDestroy() {
    this.elementRef.nativeElement.remove();
  }

  ngOnInit() {
    this.activatedRoute.paramMap
      .pipe(
        untilDestroyed(this),
        map(() => window.history.state)
      )
      .subscribe({
        next: ({ informationStep, rate, additionalInterest }) => {
          this.informationStep = { ...informationStep, additionalInterest };
          this.additionalInterest = additionalInterest;
          this.rate = rate;
          if (this.informationStep) {
            const {
              address: { stateCode },
              propertyTypeId
            } = this.informationStep;
            this.progressSpinnerService.progressStart();
            this.apiService
              .questions(stateCode, propertyTypeId)
              .pipe(untilDestroyed(this))
              .subscribe({
                next: (questionsView: GetQuestionsView) => {
                  this.questionsData = questionsView;
                  [
                    ...this.questionsData.lossQuestions,
                    ...this.questionsData.questions
                  ].forEach((x) =>
                    this.addQuestionForm(
                      Number(x.questionId),
                      x.questionDesc,
                      x.isYesNoQuestion,
                      x.childQuestions
                    )
                  );
                },
                error: () => this.progressSpinnerService.progressStop(),
                complete: () => this.progressSpinnerService.progressStop()
              });
          }
        },
        error: console.log
      });
  }

  addQuestionForm(
    id: number,
    questionText?: string,
    isYesNo?: boolean,
    childQuestions?: Question[]
  ) {
    const question = this.formBuilder.group({
      eligibilityQuestionId: new FormControl(id),
      answer: [isYesNo ? false : ''],
      question: new FormControl(questionText),
      childQuestions: this.formBuilder.array([]),
      icon: this.getQuestionIcon(id),
      isYesNo
    });

    if (childQuestions?.length > 0)
      childQuestions.forEach((cQuestion) => {
        const childQuestion = this.formBuilder.group({
          eligibilityQuestionId: new FormControl(cQuestion.questionId),
          answer: [cQuestion.isYesNoQuestion ? false : ''],
          question: new FormControl(cQuestion.questionDesc),
          icon: this.getQuestionIcon(cQuestion.questionId),
          isYesNo: cQuestion.isYesNoQuestion
        });
        (question.get('childQuestions') as FormArray).push(childQuestion);
      });

    this.questions.push(question);
  }

  changeChildQuestion(ev: any, parent: number, child: number) {
    const lossLength = this.questionsData.lossQuestions.length;
    const isLoss = parent < lossLength;
    const questionType = isLoss ? 'lossQuestions' : 'questions';
    parent = isLoss ? parent : parent - lossLength;
    const { isYesNoQuestion } =
      this.questionsData[questionType][parent].childQuestions[child];
    let value = ev.target?.value ?? ev.value;
    if (isYesNoQuestion && !isEmpty(value) && !isNil(value))
      value = value ? 'Yes' : 'No';
    this.questionsData[questionType][parent].childQuestions[child].response =
      value;
    this.validateQuestions();
  }

  questionChange(question: AbstractControl) {
    this.setValidatorChildQuestion(
      question?.get('eligibilityQuestionId').value,
      question.get('answer').value
    );
    this.validateQuestions(question.value);

    const lossQuestionsAux = this.questionsData.lossQuestions.find(
      (q) => q.questionId == question?.get('eligibilityQuestionId').value
    );
    if (lossQuestionsAux) {
      lossQuestionsAux?.childQuestions?.forEach((c) => {
        c.response = null;
      });
    }

    const questionsAux = this.questionsData.questions.find(
      (q) => q.questionId == question?.get('eligibilityQuestionId').value
    );
    if (questionsAux) {
      questionsAux?.childQuestions?.forEach((c) => {
        c.response = null;
      });
    }
  }

  getQuestionIcon(type: number): string {
    switch (type) {
      case 1:
        return 'pc-parking';
      case 2:
        return 'pc-historical';
      case 3:
        return 'pc-homeowners';
      case 4:
        return 'pc-modular';
      case 5:
        return 'pc-multi';
      case 6:
        return 'pc-earthquake';
      case 7:
        return 'pc-anchored';
      case 8:
        return 'pc-anchoredFoundation';
      case 9:
        return 'pc-waterHeater';
      case 3501:
      case 3561:
        return 'pc-earthquake2';
      case 1915:
        return 'pc-dog';
      case 3052:
        return 'pc-business';
      default:
        return 'pc-anchoredFoundation';
    }
  }

  setQuestionsTo(questionsType: string) {
    this.questionsData[questionsType] = this.questions.value
      .filter((q) =>
        this.questionsData[questionsType]
          .map((lq) => lq.questionId)
          .includes(q.eligibilityQuestionId)
      )
      .map((question) => {
        const lossQuestion = this.questionsData[questionsType].find(
          (x) => x.questionId === question.eligibilityQuestionId
        );
        return { ...lossQuestion, response: question.answer ? 'Yes' : 'No' };
      });
  }

  preRate() {
    this.progressSpinnerService.progressStart();
    const rateInput: RateControllerInput = this.informationStep.rateParams;

    this.setQuestionsTo('lossQuestions');
    this.setQuestionsTo('questions');

    rateInput.questions = {
      lossQuestions: this.questionsData.lossQuestions,
      questions: this.questionsData.questions
    };
    if (this.generateNewQuote) {
      rateInput.companysQuoteNumber = null;
    }

    this.apiService
      .rate(rateInput)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (rateView: RateView) => {
          this.paymentStep = {
            rateInput,
            rateView,
            informationStep: this.informationStep,
            additionalInterest: this.additionalInterest
          };
          this.paymentStep.rateInput.companysQuoteNumber =
            rateView.companysQuoteNumber;
          this.generateNewQuote = false;
          this.storageService.setItem('quote', rateView.companysQuoteNumber);
          this.router.navigate(['/Payment'], { state: this.paymentStep });
        },
        error: (error) => {
          this.progressSpinnerService.progressStop();
          const errorMesage =
            '<br> Unfortunately, answer above indicates that this risk is ineligible for coverage at this time.';
          this.alertService.AlertSimpleHTML(
            'Property Info',
            error?.detail + errorMesage
          );
          this.generateNewQuote = true;
        },
        complete: () => this.progressSpinnerService.progressStop()
      });
  }

  validateQuestions(parentQestion?: {
    eligibilityQuestionId?: number;
    answer?: boolean;
    question?: string;
    childQuestions?: any[];
    icon?: string;
    isYesNo?: boolean;
  }) {
    const questions = this.questions.value.map((q) =>
      q.eligibilityQuestionId === parentQestion?.eligibilityQuestionId
        ? parentQestion
        : q
    );

    let disableNext = !questions.every(({ answer }) => !answer);
    questions.forEach((question) => {
      if (question.answer) {
        const findFn = (q) => q.questionId === question.eligibilityQuestionId;
        let currentQuestion = this.questionsData.lossQuestions.find(findFn);
        if (!currentQuestion)
          currentQuestion = this.questionsData.questions.find(findFn);
        disableNext = currentQuestion.childQuestions.some(
          ({ response }) => isEmpty(response) || isNil(response)
        );
      }
    });
    this.disableNext = disableNext;
  }

  setValidatorChildQuestion(idQuestion: number, answer: boolean) {
    const fomsChil = this.questions.controls
      .find((x) => x.get('eligibilityQuestionId').value == idQuestion)
      ?.get('childQuestions') as FormArray;

    if (fomsChil) {
      fomsChil?.controls?.forEach((x) => {
        const answerControl = x.get('answer');
        if (answer) {
          answerControl?.setValidators(Validators.required);
        } else {
          answerControl?.setValidators(null);
        }
        answerControl?.reset();
      });
    }
  }

  getFormArrayChild(item: AbstractControl) {
    return item.get('childQuestions') as FormArray;
  }

  getMaskNumber() {
    return masks.maskNGXOnlyNumber;
  }
}
