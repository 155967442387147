
<router-outlet></router-outlet>
<app-footer></app-footer>

<div class="overlay" *ngIf="isLoading">
  <div class="center">
    <mat-progress-spinner diameter="50" mode="indeterminate" color="accent">
    </mat-progress-spinner>
  </div>
</div>
