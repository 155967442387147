<div class="subTitle">Billing</div>

<div [formGroup]="Billing">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="15px" fxFill>
        <div fxFlex="50%"  fxFlex.lt-md="100%">
            <label for="" class="LabelText">Address*</label>
            <input formControlName="AddressControl" (focusout)="Validation()"
                [class.is-invalid]="AddressControl.invalid && AddressControl.touched" type="text"
                class="form-control" id="AddressControl">
            <div *ngIf="(AddressControl.invalid && AddressControl.touched) || AddressControl.dirty">
                <small *ngIf="AddressControl.errors?.required" class="text-danger">Address is required</small>
            </div>
        </div>

        <div fxFlex="50%"  fxFlex.lt-md="100%">
            <label for="" class="LabelText">City*</label>
            <input formControlName="CityControl" (focusout)="Validation()" [imask]="maskCity" [unmask]="true"
                [class.is-invalid]="CityControl.invalid && CityControl.touched" type="text" class="form-control"
                id="CityControl">
            <div *ngIf="(CityControl.invalid && CityControl.touched) || CityControl.dirty">
                <small *ngIf="CityControl.errors?.required" class="text-danger">City is required</small>
            </div>
        </div>

        <div fxFlex="25%"  fxFlex.lt-md="100%">
            <!-- placeholder -->
        </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="center start" fxLayoutAlign.lt-md="space-around stretch" fxLayoutGap="15px" fxFill>
        <div fxFlex="50%"  fxFlex.lt-md="100%">
            <label for="" class="LabelText">State*</label>
            <input formControlName="StateControl" (focusout)="Validation()"  [imask]="maskState" [unmask]="true"
                [class.is-invalid]="StateControl.invalid && StateControl.touched" type="text" class="form-control"
                id="StateControl">
            <div *ngIf="(StateControl.invalid && StateControl.touched) || StateControl.dirty">
                <small *ngIf="StateControl.errors?.required" class="text-danger">State is required</small>
            </div>
        </div>

        <div fxFlex="50%"  fxFlex.lt-md="100%">
            <label for="" class="LabelText">Zip Code*</label>
            <input formControlName="ZipCodeControl" (focusout)="Validation()" pattern="^\d{5}$" minlenght="5" maxlength="5"
                [class.is-invalid]="ZipCodeControl.invalid && ZipCodeControl.touched" type="text"
                class="form-control" id="ZipCodeControl" (keypress)="keyPress($event)">
            <div *ngIf="(ZipCodeControl.invalid && ZipCodeControl.touched) || ZipCodeControl.dirty">
                <small *ngIf="ZipCodeControl.errors?.required" class="text-danger">Zip code is required</small>
                <small *ngIf="ZipCodeControl.hasError('pattern')" class="text-danger">The zip code must have a length of '5'</small>
            </div>
        </div>

        <div fxFlex="25%"  fxFlex.lt-md="100%">
            <!-- placeholder -->
        </div>

        <div fxFlex="25%"  fxFlex.lt-md="100%">
            <!-- placeholder -->
        </div>
    </div>
</div>

<div class="Text">
    The total amount to be charged to your card will be the policy payment
      amount plus a {{ fee || '$0.00' | currency }} processing fee. This is a processing
      fee, collected by
      <a class="txt-link" [href]="oneIncURL" target="_blank"> One Inc </a>, for
      this payment option.
</div>

<div class="DivAcknowledgement" style="margin-top: 10px;">
    <div class="Text">
        Customer Acknowledgement:
    </div>

    <div>
        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="exampleCheck1" (change)="Validation()" [(ngModel)]="aceptedTerms">
            <label class="form-check-label Text" for="exampleCheck1"> I accept the terms /</label>
            <span class="Policy" (click)="ShowPrivatePolicyModal()">
                View our Privacy Policy
            </span>
        </div>
    </div>

    <div class="Text">
        <b>Note: </b> <i>By checking the box, I understand that clicking on the "PURCHASE POLICY" button
            authenticates my signature, that I am the same person as listed in the "Insured Name" and "Insured
            Location" fields and the lawful holder of the Debit or Credit Card to which policy premiums will be
            charged. </i>
        <b>In addition, I authorize Protection Choice or its agent to make recurring charges for the premium I
            have agreed to herein until the policy is cancelled and to send all policy documents to the e-mail
            address provided.</b>
    </div>
</div>